import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from '@app/core/route';
import { RedirectCache } from '@app/core/route/redirect-cache.component';
import { useAuthGuard, useInternalMarket } from '@app/modules/auth';
import { ChatRouter } from '@app/modules/chat';
import { ChatRouterV2 } from '@app/modules/chat-v2';
import { DashboardPage } from '@app/modules/dashboard';
import { DocumentsPage } from '@app/modules/documents/documents.page';
import { NotFound } from '@app/modules/error';
import { FinanceRouter } from '@app/modules/finance/finance.router';
import { HelpCenterPage } from '@app/modules/help-center/help-center.page';
import { OrderRouter } from '@app/modules/order/order.router';
import { FormRouter } from '@app/modules/quote/form/form.router';
import { RedirectPage } from '@app/modules/redirect/redirect-page';
import { AdminSettingsPage } from '../admin-settings/admin-settings.page';
import { AdminRouter } from '../admin/admin-router';
import { AuthPage } from '../auth/auth.page';
import { ChangePasswordPage } from '../auth/partials/change-password';
import { ForgotPasswordPage } from '../auth/partials/forgot-password/forgot-password.page';
import { quoteIsInternalUserSelector } from '../auth/store/auth.selectores';
import { ComplainRouter } from '../complain/complain.router';
import { Surrogate } from '../components/surrogate/surrogate.page';
import { OrderInputRouter } from '../order-input/order-input.router';
import { FormAdminRouter } from '../quote/form-admin/form-admin.router';
import { QuoteRouter } from '../quote/quote.router';
import { AppPath } from './route-constants';

export const Routes: React.FC = () => {
  const authAuthorized = useAuthGuard();
  const internalMarket = useInternalMarket();
  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);

  if (!authAuthorized) {
    return (
      <Switch>
        <Route path={AppPath.AUTHENTICATION} component={AuthPage} />
        <Route
          path={AppPath.FORGOT_PASSWORD}
          component={ForgotPasswordPage}
          authorized={!authAuthorized}
          redirectPath={AppPath.ORDERS}
        />
        <Redirect to='/auth' />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route
        path={AppPath.ORDER_INPUT}
        component={OrderInputRouter}
        exact
        authorized={authAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        path={AppPath.ORDERS}
        component={OrderRouter}
        exact
        authorized={authAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        path={AppPath.CHAT.BASE}
        component={ChatRouter}
        authorized={authAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        path={AppPath.CHAT_V2.BASE}
        component={ChatRouterV2}
        authorized={authAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        path={AppPath.QUOTE.FORM.BASE}
        component={FormRouter}
        authorized={authAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        path={AppPath.FINANCE}
        component={FinanceRouter}
        exact
        authorized={authAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        path={AppPath.QUOTE.BASE}
        component={QuoteRouter}
        authorized={authAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        path={AppPath.CHANGE_PASSWORD}
        component={ChangePasswordPage}
        authorized={authAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        path={AppPath.ADMIN_SETTINGS}
        component={AdminSettingsPage}
        authorized={authAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        path={AppPath.ADMIN.BASE}
        component={AdminRouter}
        authorized={authAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        path={AppPath.HELP_CENTER}
        component={HelpCenterPage}
        authorized={authAuthorized && (internalMarket || quoteIsInternalUser)}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        path={AppPath.DASHBOARD}
        component={DashboardPage}
        authorized={authAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        path={AppPath.COMPLAIN.BASE}
        component={ComplainRouter}
        authorized={authAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        path={AppPath.CATALOGS}
        component={DocumentsPage}
        authorized={authAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        path={AppPath.DOCUMENTS}
        component={DocumentsPage}
        authorized={authAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />
      <Route
        path={AppPath.QUOTE.FORM_ADMIN}
        component={FormAdminRouter}
        authorized={authAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />

      <Route
        path={AppPath.SURROGATE}
        component={Surrogate}
        exact
        authorized={authAuthorized}
        redirectPath={AppPath.AUTHENTICATION}
      />

      <Route path={AppPath.CACHE_ROUTE} component={RedirectCache} />

      <Route path={AppPath.REDIRECT} component={RedirectPage} />

      <Route path={AppPath.ERROR} component={NotFound} />

      <Route component={NotFound} />
    </Switch>
  );
};
