import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { NewsModalResponse } from '@app/models/news-modal.model';

const endpoint = {
  MODAL: '/modal',
  USER: '/user',
  ANSWER: '/answer',
  ADMIN: '/admin',
};

export const NewsModalDatasource = {
  getNews: (): Promise<NewsModalResponse> => {
    const user = Container.get(AuthStorageService).getUser();
    return Container.get(HttpRequestBuilder)
      .get(endpoint.MODAL + endpoint.USER)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params({ identifier: user.uid })
      .execute();
  },
  setViewedNews: (functionality: string): Promise<NewsModalResponse> => {
    const user = Container.get(AuthStorageService).getUser();
    return Container.get(HttpRequestBuilder)
      .post(endpoint.MODAL + endpoint.USER + endpoint.ANSWER)
      .headers({
        'Content-Type': 'application/json',
        'Sso-Access': user.ssoAccess,
      })
      .data({ identifier: user.uid, functionality: functionality })
      .execute();
  },
};
