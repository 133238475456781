import { IAdminState } from '../admin.interfaces';
import { LIMIT_ITEMS_PER_PAGE } from '../utils/utils';

export const initialState: IAdminState = {
  userSearch: {
    filterOption: '',
    textQuery: '',
    selectedOptions: [],
    filteredUsers: [],
    pagination: {
      skip: 0,
      limit: LIMIT_ITEMS_PER_PAGE,
      count: 0,
    },
    activeId: '',
  },
  allUsers: [],
  bpSearch: {
    filterOption: '',
    textQuery: '',
    selectedOptions: [],
    filteredBPs: [],
    pagination: {
      skip: 0,
      limit: LIMIT_ITEMS_PER_PAGE,
      count: 0,
    },
    activeId: '',
  },
  allBPs: [],
  showForm: false,
  user: {
    _id: '',
    oktaUid: '',
    name: '',
    email: '',
    language: '',
    applications: '',
    lastLogin: '',
    created: {
      at: '',
      by: {
        name: '',
        email: '',
      },
    },
    profile: {
      _id: '',
      profile: '',
      tabs: [],
    },
    bps: [],
    isAdmin: false,
  },
  bpFilter: {
    filterOption: '',
    textQuery: '',
    selectedOptions: [],
    selectedCorpGroupOptions: [],
    filteredBPs: [],
  },
  isEditing: false,
  bpOptions: [],
  bpBPOptionsFormSelect: [],
  bpCorpGroupOptionsFormSelect: [],
  corpGroupSelect: [],
  salesOrgSelect: [],
  tabChips: [],
  roles: [],
  eppRoleConfig: null,
  pedRoleConfig: null,
  rtcRoleConfig: null,
  gqRoleConfig: null,
  adminRoleConfig: null,
  sellerRoleConfig: null,
  scmRoleConfig: null,
  squadRoleConfig: null,
  surrogateConfig: null,
  lackOfChargeConfig: null,
  schedulingCompleteConfig: null,
  loadingUsers: false,
  loadingBPs: false,
  loadingSubmit: false,
  loadingUser: false,
  loadingResetPassword: false,
  loadingProfile: false,
  loadingAllUsers: false,
  loadingAllBps: false,
  showConfirmModal: false,
  showDeleteModal: false,
};
