import { createAsyncThunk } from '@reduxjs/toolkit';
import Container from 'typedi';
import { LoginDataSource } from '@app/data/http/login.datasource';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { SLICE_NAME } from '../../auth.constants';
import { IAuthState, LogoutParams } from '../../auth.interfaces';

const authStorageService: AuthStorageService = Container.get(AuthStorageService);

export const logout = createAsyncThunk(`${SLICE_NAME}/logout`, async (logoutParams: LogoutParams, thunkAPI) => {
  const state: any = thunkAPI.getState();

  const { userInfo, ssoAccess, azureToken }: IAuthState = state.auth;

  return await LoginDataSource.logout(userInfo.oktaUid, azureToken)
    .then(() => {
      authStorageService.logout();

      if (ssoAccess) {
        window.location.href = `${logoutParams.ssoLogoutUrl}${logoutParams.ssoTenantId}${logoutParams.ssoLogoutAuthPath}${logoutParams.gsbAuthUrl}`;
      }
    })
    .catch(err => {
      thunkAPI.dispatch(setToast({ show: true, text: err.response.data.message, variant: ToastVariant.DANGER }));
    });
});

export const addLogoutReducers = builder => {
  builder.addCase(logout.pending, state => {
    state.loading = true;
  });

  builder.addCase(logout.rejected, state => {
    state.loading = false;
  });
};
