/* eslint-disable complexity */

import React from 'react';
import { SelectField } from '@atomic/atm.select';
import { TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Form, Validators } from '@atomic/obj.form';
import { FormContext } from '../../form.context';
import { FormFlowProps } from '../form-flow-fields.container';

export const RangeField = (props: FormFlowProps) => {
  const { formResult, strings } = React.useContext(FormContext);
  const [min, setMin] = React.useState(null);
  const [max, setMax] = React.useState(null);
  const [unit, setUnit] = React.useState(
    props.field?.unit?.allowed?.length > 1 ? props.field?.unit?.allowed[0]?.symbol : props.field?.unit?.symbol,
  );

  const stringsNewForm = strings.newForm.messages;

  React.useEffect(() => {
    console.log('unit', unit);
    if (min && max && unit) {
      getValues();
    }
  }, [min, max, unit]);

  const getValues = () => {
    props.handleValue(`${min} - ${max}`, props.field.key, false, props.stepKey, unit);
  };

  return (
    <Table>
      <TR>
        <TD>
          <Form.Field
            name={`${props.field.key}-min`}
            onValueChange={value => setMin(value)}
            initialValue={
              formResult !== null && formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value?.min
                ? formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value?.min
                : null
            }
            hideErrorCaption
            validators={[
              Validators.MaxValue(max, min, stringsNewForm.alertMax),
              Validators.HasAtLeastOne([min, max], stringsNewForm.mandatoryAlert),
              Validators.MinMoreThanMax([min, max], stringsNewForm.alertMinMoreMax),
            ]}
          >
            <TextField placeholder='Min.' type='text' />
          </Form.Field>
        </TD>
        <TD>-</TD>
        <TD>
          <Form.Field
            name={`${props.field.key}-max`}
            initialValue={
              formResult !== null && formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value?.max
                ? formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value?.max
                : null
            }
            onValueChange={value => setMax(value)}
            validators={[
              Validators.MaxValue(max, max, stringsNewForm.alertMax),
              Validators.HasAtLeastOne([min, max], stringsNewForm.mandatoryAlert),
              Validators.MinMoreThanMax([min, max], stringsNewForm.alertMinMoreMax),
            ]}
          >
            <TextField placeholder='Máx.' type='text'></TextField>
          </Form.Field>
        </TD>
        {props.field?.unit?.allowed?.length > 1 ? (
          <TD>
            <Form.Field
              name={props.field.key}
              onValueChange={value => setUnit(value)}
              validators={[Validators.Required(stringsNewForm.mandatoryAlert)]}
            >
              <SelectField id={`${props.field.key}-unit`}>
                {props.field?.unit.allowed.map((el: { symbol: string; description: string }) => (
                  <option value={el.symbol} key={el.symbol}>
                    {el.description}
                  </option>
                ))}
              </SelectField>
            </Form.Field>
          </TD>
        ) : (
          <TD>
            <Body>{props.field?.unit?.symbol}</Body>
          </TD>
        )}
      </TR>
    </Table>
  );
};
