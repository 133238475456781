import React from 'react';
import { useHistory } from 'react-router';
import { LogAnalytics } from '@app/core/analytics';
import { AppPath } from '@app/modules/app/route-constants';
import { Button } from '@atomic/atm.button';
import { Body, H2 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { FormStrings } from '../form.string';

interface ModalConfirmationProps {
  conversationId: string;
  setShowModalErroNlu: (value: boolean) => void;
  opened: boolean;
  language: string;
}

export const ModalErrorNluForm = (props: ModalConfirmationProps) => {
  const history = useHistory();
  const strings = FormStrings[props.language].newForm.errorNluModal;

  const redirectSubmit = () => {
    LogAnalytics.click({
      tipo: 'ContinuarCotacaoFormParaChat',
    });
    history.push(`${AppPath.CHAT.BASE}/recovery/${props.conversationId}`);
  };

  return (
    <Modal
      preventOverlayClick
      opened={props.opened}
      small
      customHeight='100'
      onClose={() => props.setShowModalErroNlu(false)}
    >
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <H2 color={Color.Alert}>{strings.title}</H2>

            <Body>{strings.intro}</Body>
            <VSeparator />
            <VSeparator />
            <Hbox>
              <Hbox.Item grow>
                <Button expanded kind='secondary' onClick={() => redirectSubmit()}>
                  {strings.submitButton}
                </Button>
              </Hbox.Item>
              <Hbox.Separator small />
            </Hbox>
            <VSeparator />
            <VSeparator />
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};
