import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';

export const editAttributes = async (params?: any) => {
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: params.language };

  if (params.data.rows) {
    params.data.rows.forEach(row => {
      row.forEach(line => {
        delete line.colSpan;
        delete line.rowSpan;
      });
    });
  }

  const setPayload = data => {
    if (data.suggestionStatus) {
      return {
        derogate: {
          suggestion: { status: { value: data.suggestionStatus } },
        },
      };
    } else if (data.rows) {
      return {
        status: data.status,
        derogate: data.reason && {
          suggestion: { value: data.suggestion },
          reason: data.reason,
        },
        value: {
          rows: data.rows,
        },
      };
    } else {
      return {
        status: data.status,
        derogate: data.reason && {
          reason: data.reason,
          suggestion: { value: data.suggestion },
        },
      };
    }
  };

  const data = setPayload(params.data);

  const form = new FormData();
  form.append('data', JSON.stringify(data));
  if (params.file) {
    params.file.forEach(file => {
      form.append('file', file);
    });
  }

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.data.analysisId}${
    endpoint.ANALYSIS_PLANT
  }/${params.data.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.data.attributeKey}`;

  const { data: dataResponse } = await axios.patch(url, form, { headers: defaultHeaders });

  return dataResponse;
};

export const useEditAttributes = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(editAttributes, {
    onSuccess,
    onError,
    onMutate: async data => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
      const previousData = queryClient.getQueriesData(['getTechnicalAnalysis']).pop();

      queryClient.setQueriesData(
        ['getTechnicalAnalysis', { id: data.data.analysisId, language: data.data.language }],
        (oldQueryData: any) => {
          const selectedPlant = oldQueryData.plants.filter(plant => plant.key === data.data.plantKey);

          const { attributes } = selectedPlant[0];

          if (attributes) {
            if (data.data.status === 'derogate') {
              const derogate = {
                reason: data.data.reason,
                images: data?.data?.images ? data.data.images : [],
                suggestion: {
                  value: data.data.suggestion,
                  status: {
                    value: 'no-analysed',
                  },
                },
              };

              if (data.data.rowsUpdated.length > 0) {
                attributes[data.data.ATTRIBUTE_INDEX].value.rows = data.data.rowsUpdated;
              }

              attributes[data.data.ATTRIBUTE_INDEX].status = data.data.status;
              attributes[data.data.ATTRIBUTE_INDEX] = { ...attributes[data.data.ATTRIBUTE_INDEX], derogate };
            } else if (data.data.status === 'accepted' || data.data.status === 'non-analysed') {
              if (data.data.rowsUpdated.length > 0) {
                attributes[data.data.ATTRIBUTE_INDEX].value.rows.map((line, index) => {
                  const colsteste = data.data.rowsUpdated[index].concat(line);
                  attributes[data.data.ATTRIBUTE_INDEX].value.rows[index] = colsteste;
                });
              }
              attributes[data.data.ATTRIBUTE_INDEX].status = data.data.status;
              attributes[data.data.ATTRIBUTE_INDEX].derogate = null;
            }
            if (data.data.suggestionStatus) {
              attributes[data.data.ATTRIBUTE_INDEX].derogate.suggestion.status.value = data.data.suggestionStatus;
            }
          }

          return {
            ...oldQueryData,
          };
        },
      );
      return {
        previousData,
      };
    },
    onSettled: (error, _, context) => {
      if (!context?.acceptance && !error) {
        queryClient.invalidateQueries(['getTechnicalAnalysis']);
      }
    },
  });
};
