import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { UserInfo } from '@app/models/login.model';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName } from '@app/providers';
import { FlashMessageType } from '@atomic/mol.flash-message';
import { FormStrings } from '../form.string';

interface ModalAttributeContextState {
  userInfo: UserInfo;
  strings: any;
  show: (type: FlashMessageType, content: string, alignRight?: boolean, small?: boolean) => void;
}

export interface ClientList {
  bp: string;
  corporateGroup: string;
  formatList: string;
  name: string;
  type: string;
}

export const ModalAttributeContext = React.createContext<ModalAttributeContextState>(null);

export const ModalAttributeProvider: React.FC = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const strings = FormStrings[userInfo.language];

  const value = {
    userInfo,
    strings,
    show,
  };

  const memoizedValue = React.useMemo(() => value, [value]);

  return <ModalAttributeContext.Provider value={memoizedValue}>{props.children}</ModalAttributeContext.Provider>;
};
