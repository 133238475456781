import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { BaggHttpRequestBuilder, endpointRcs } from '@app/data/http';
import { CrmExpeditionResponse, GetCrmParams, mapData } from '@app/data/http/crm.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getExpedition(params?: GetCrmParams): Promise<CrmExpeditionResponse> {
  const user = Container.get(AuthStorageService).getUser();

  const defaultHeaders = { ...Container.get(BaggHttpRequestBuilder).config.headers, language: user.language };

  const url = `${Container.get(BaggHttpRequestBuilder).config.baseUrl}${endpointRcs.RCS}/${params.id}${
    endpointRcs.EXPEDITION
  }`;

  const { data, status } = await axios.get(url, {
    headers: defaultHeaders,
  });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useGetExpedition(params: GetCrmParams, onError?) {
  return useQuery(['getExpedition', params], () => getExpedition(params), {
    onError,
    enabled: params.enable,
    select: data => {
      return mapData(data.data);
    },
    refetchOnWindowFocus: false,
  });
}
