import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
//import { HttpRequestBuilder } from '@app/core/http';
import { IsaHttpRequestBuilder } from '@app/data/http';
//import { ConfigurationsReponse } from '@app/data/http/quote-params.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';
//import { handleUnauthorized } from '@app/utils/http-utils';
import { Field } from '../components/form-flow-fields.container';
import { mapAdditionalAttributesList, mapFormFlow, mapSteelFlow } from '../utils/form.dto';
//import { FormStrings } from '../form.string';

interface CallbackParams {
  payload: {
    field: Field;
    corporateGroup?: string;
  };
  url: string;
}

export const FormFlowDatasource = {
  async getFormFlow(params): Promise<any> {
    const user = Container.get(AuthStorageService).getUser();

    const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: user.language };

    const url = `https://cxp-quote-api-cxp-qa.apps2.rosa-gerdau.bxnu.p1.openshiftapps.com/v3/api/forms/steps/${params.flow}`;

    try {
      const { data, status } = await axios.get(url, { headers: defaultHeaders });
      if (status === 200) {
        return mapFormFlow(data.data, params.flow);
      } else {
        return null;
      }
    } catch (error) {
      //handleUnauthorized(error);
      return null;
    }
  },
  async callAttributeCallback(params: CallbackParams): Promise<any> {
    const user = Container.get(AuthStorageService).getUser();

    const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: user.language };
    try {
      if (params.payload.field.key === 'STEEL_NAME') {
        const payload = { corporateGroup: params.payload.corporateGroup };

        const url = params.url;

        const { data, status } = await axios.post(url, payload, { headers: defaultHeaders });

        if (status === 200) {
          return mapSteelFlow(data.data);
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      //handleUnauthorized(error);
      return null;
    }
  },
  async getAdditionalAttributesList(params): Promise<any> {
    const user = Container.get(AuthStorageService).getUser();

    const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: user.language };

    const url = `https://cxp-quote-api-cxp-qa.apps2.rosa-gerdau.bxnu.p1.openshiftapps.com/v3/api/forms/steps/${params.flow}`;

    try {
      const { data, status } = await axios.get(url, { headers: defaultHeaders });
      if (status === 200) {
        return mapFormFlow(data.data, params.flow);
      } else {
        return null;
      }
    } catch (error) {
      //handleUnauthorized(error);
      return null;
    }
  },
};

export function useFormFlowInitial(params, onSuccess?, onError?) {
  return useQuery(['getFormFlow', params], () => FormFlowDatasource.getFormFlow(params), {
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useFormFlow(onSuccess?, onError?) {
  return useMutation(FormFlowDatasource.getFormFlow, {
    onSuccess,
    onError,
    retry: false,
  });
}

export function useGetAdditionalAttributesList(params, onSuccess?, onError?) {
  return useQuery(['getFormFlow', params], () => FormFlowDatasource.getAdditionalAttributesList(params), {
    onSuccess,
    onError,
    select: data => {
      return mapAdditionalAttributesList(data);
    },
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export function useCallBackAttribute(onSuccess?, onError?) {
  return useMutation(FormFlowDatasource.callAttributeCallback, {
    onSuccess,
    onError,
    retry: false,
  });
}
