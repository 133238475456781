import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { FullAnalysisResponse } from '@app/data/http/quote.dto';

export const deleteComments = async (params?: any) => {
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: params.language };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_PLANT
  }/${params.plantKey}${endpoint.ANALYSIS_ATTRIBUTE}/${params.attributeKey}${endpoint.ANALYSIS_COMMENTS}/${
    params.commentKey
  }`;

  return await axios.delete(url, { headers: defaultHeaders });
};

export const useDeleteComment = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteComments, {
    onSuccess,
    onError,
    onMutate: async data => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
      const previousData = queryClient.getQueriesData(['getTechnicalAnalysis']);
      queryClient.setQueriesData(['getTechnicalAnalysis'], (oldQueryData: FullAnalysisResponse) => {
        const selectedPlant = oldQueryData.plants.filter(plant => plant.key === data.plantKey);

        const { comments } = selectedPlant[0].attributes[data.ATTRIBUTE_INDEX];
        const commentIndex = comments.findIndex(comment => comment.key === data.commentKey);
        comments.splice(commentIndex, 1);

        return {
          ...oldQueryData,
        };
      });
      return {
        previousData,
      };
    },
  });
};
