import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpointCrm, IsaHttpRequestBuilder } from '@app/data/http';
import { CrmOpportunityDetailsResponse, GetCrmParams, mapOpportunityDetailsData } from '@app/data/http/crm.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getOpportunityDetails(params?: GetCrmParams): Promise<CrmOpportunityDetailsResponse> {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: user.language };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpointCrm.CRM}/${params.id}${
    endpointCrm.OPPORTUNITY_DETAILS
  }`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useGetOpportunityDetails(params: GetCrmParams, onSuccess?, onError?) {
  return useQuery(['getOpportunityDetails', params], () => getOpportunityDetails(params), {
    onSuccess,
    onError,
    enabled: params.enable,
    select: data => {
      return mapOpportunityDetailsData(data.data);
    },
    refetchOnWindowFocus: false,
  });
}
