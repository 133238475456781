import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../announcement-regular-modal.constants';
import { IAnnouncementRegularModalState } from '../announcement-regular-modal.interfaces';
import { initialState } from './announcement-regular-modal.initial';
import { reducers } from './announcement-regular-modal.reducers';
import { addThunkReducers } from './announcement-regular.thunks';

export const announcementRegularModalSlice = createSlice<
  IAnnouncementRegularModalState,
  SliceCaseReducers<IAnnouncementRegularModalState>
>({
  name: SLICE_NAME,
  initialState,
  reducers,
  extraReducers: addThunkReducers,
});

export const { reset, setRegularAnnouncements, setRegularOpened } = announcementRegularModalSlice.actions;

export const regularAnnouncementReducer = announcementRegularModalSlice.reducer;
