import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { H4, InputLabel } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { FormStrings } from '../../form.string';
import { FormResultEditOptions } from './form-result-edit-options';
import { FormResultAttributeWrapper, FormResultOptionsWrapper } from './form-result.component.style';

interface MacroataqueItems {
  element: string;
  value: any;
}
interface MacroataqueTableProps {
  attrKey: string;
  stepKey: string;
  attribute: {
    display: boolean;
    key: string;
    label?: string;
    mandatory?: boolean;
    permissions?: {
      visible: boolean;
      editable: boolean;
    };
    type: string;
    creation: string;
    value: MacroataqueItems[];
  };
}

export const MacroataqueTable: React.FC<MacroataqueTableProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const strings = FormStrings[userInfo.language];

  return props.attribute?.permissions.visible ? (
    <FormResultAttributeWrapper
      data-testid={`analysisResume${props.attrKey}`}
      modified={props.attribute?.creation === 'NLU'}
    >
      <Hbox.Item>
        <FormResultOptionsWrapper>
          <InputLabel>
            {props.attribute?.label}{' '}
            {props.attribute?.creation === 'NLU' && (
              <span>{` ${QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}`}</span>
            )}
          </InputLabel>
          <FormResultEditOptions
            stepKey={props.stepKey}
            editable={props.attribute.permissions.editable}
            attrKey={props.attrKey}
            type={props.attribute.type}
          />
        </FormResultOptionsWrapper>
      </Hbox.Item>

      <Table>
        <TR>
          <TD />
          <TD>
            <H4>{strings.modalMacroataque.valueColumn}</H4>
          </TD>
        </TR>
        {props.attribute.value.map(item => (
          <TR key={item.element}>
            <TD>
              <H4>{item.element}</H4>
            </TD>
            <TD>{item.value}</TD>
          </TR>
        ))}
      </Table>
      <VSeparator />
    </FormResultAttributeWrapper>
  ) : null;
};
