import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { LoginDataSource } from '@app/data/http/login.datasource';
import { handleUnauthorized } from '@app/utils/http-utils';
import { authErrorMessageMap } from '../../auth-error.mapper';
import { AuthEvents } from '../../auth.analytics';
import { SLICE_NAME } from '../../auth.constants';

interface ValidateTokenParams {
  authorizationToken: string;
  ssoAccess: boolean;
}

export const validateToken = createAsyncThunk(
  `${SLICE_NAME}/validateToken`,
  async (params: ValidateTokenParams, thunkAPI) => {
    return await LoginDataSource.validateToken(params.ssoAccess, params.authorizationToken)
      .then(resp => {
        return resp;
      })
      .catch(err => {
        LogAnalytics.error({
          tipo: AuthEvents.ValidateTokenError,
          message: authErrorMessageMap[parseInt(err.response.status)],
          errorMessage: err.response.message,
          errorDetails: JSON.stringify(err.response.data),
        });
        handleUnauthorized(err);
        return thunkAPI.rejectWithValue({ status: err.response.status });
      });
  },
);

export const addValidateTokenReducers = builder => {
  builder.addCase(validateToken.pending, state => {
    state.loadingValidateToken = true;
  });
  builder.addCase(validateToken.fulfilled, (state, action) => {
    state.isActive = action.payload.isActive;
    state.loadingValidateToken = false;
    state.loadingProfile = false;
  });
  builder.addCase(validateToken.rejected, state => {
    state.loading = false;
    state.loadingValidateToken = false;
    state.loadingProfile = false;
    state.isActive = false;
  });
};
