import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { LogAnalytics } from '@app/core/analytics';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { RootState } from '@app/core/redux/store';
import { NewsModalDatasource } from '@app/data/http/news-modal-datasource';
import { Language } from '@app/models';
import { NewsModalResponse } from '@app/models/news-modal.model';
import { MarketType } from '@app/providers';
import { newsModalOpenedSelector } from '@app/providers/navigation/navigation.selectors';
import { setOpenNewsModal, setOpenNewsModalHistory } from '@app/providers/navigation/navigation.store';
import { dateText } from '@app/utils/date-parser';
import { Button } from '@atomic/atm.button';
import { Image } from '@atomic/atm.image';
import { Body, H3 } from '@atomic/atm.typography';
import { FlexRow } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { Grid, VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { Modal } from '@atomic/obj.modal';
import { Strings, IAppStrings } from '../../app/app.string';
import { ErrorPlaceholder } from '../error.placeholder';
import { NoDataPlaceholder } from '../no-data.placeholder';
import { NewsModalEvents } from './news-modal.analytics';
import { NewsModalShimmer } from './news-modal.component.shimmer';
import {
  NewsListWrapper,
  NewsModalBody,
  NewsModalCloseWrapper,
  NewsModalFooter,
  NewsModalHeader,
  NewsModalHeaderIconWrapper,
  NewsModalImage,
  NewsModalText,
  NewsModalTitleWrapper,
  NewsModalWrapper,
} from './news-modal.style';

export const NewsModal: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { currentMarket } = useSelector((state: RootState) => state.customerPersist);
  const newsModalOpened = useSelector(newsModalOpenedSelector);

  const strings: IAppStrings = Strings[userInfo?.language];

  const [newsList, setNewsList] = React.useState([]);

  const { openBPsModal, openNewsModalHistory } = useSelector((state: RootState) => state.navigation);
  const dispatch = useDispatch();

  const { loading, performRequest, error } = useLazyRequest<string, NewsModalResponse>(
    NewsModalDatasource.getNews,
    res => handleSuccess(res),
    err => LogAnalytics.error({ tipo: NewsModalEvents.NewsModalError, message: err?.message }),
  );

  useEffect(() => {
    if (currentMarket === MarketType.Internal) {
      performRequest();
    } else {
      dispatch(setOpenNewsModal(false));
    }
  }, [currentMarket, openBPsModal, openNewsModalHistory]);

  const handleSuccess = res => {
    if (res && res.modals && res.modals.length) {
      const modals = [];

      res.modals.forEach(r => {
        if ((!r.visualized && r.releasetDate.indexOf('2017') < 0) || openNewsModalHistory) {
          modals.push(r);
        }
      });

      if (modals.length) {
        setNewsList(modals.reverse());
        dispatch(setOpenNewsModal(true));
      }
    }
  };

  const handleClose = () => {
    if (!openNewsModalHistory) {
      NewsModalDatasource.setViewedNews(getFunctionalityListString());
    }

    dispatch(setOpenNewsModal(false));
    dispatch(setOpenNewsModalHistory(false));
  };

  const getFunctionalityListString = () => {
    let functionalities = '';
    const lastFunctionality = newsList[newsList.length - 1];

    newsList.forEach(n => {
      if (n !== lastFunctionality) {
        functionalities += n.functionality + ',';
      } else {
        functionalities += n.functionality;
      }
    });

    return functionalities;
  };

  const handleImages = (imageUrl: string) => {
    if (!imageUrl) {
      return null;
    }

    const imageSplit = imageUrl.split(',');

    return imageSplit.map((image, i) => {
      if (image) {
        return (
          <div key={`${image}-${i}`}>
            <VSeparator />
            <NewsModalImage>
              <img width='100%' src={`assets/img/news-modal/${image}`} />
            </NewsModalImage>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const handleButtonText = () => {
    if (openNewsModalHistory) {
      return strings?.newsModal.close;
    } else {
      return strings?.newsModal.confirm;
    }
  };

  if (userInfo?.language !== Language.por) {
    return null;
  }

  return (
    <Modal medium opened={newsModalOpened}>
      <NewsModalHeader>
        <NewsModalHeaderIconWrapper>
          <Image.NewsModalHeaderBullHorn />
        </NewsModalHeaderIconWrapper>
        <NewsModalTitleWrapper>{strings?.newsModal.title}</NewsModalTitleWrapper>
        <NewsModalCloseWrapper>
          <FlexRow onClick={() => handleClose()} style={{ cursor: 'pointer' }}>
            <Image.NewsModalHeaderCloseButton />
          </FlexRow>
        </NewsModalCloseWrapper>
      </NewsModalHeader>
      <Grid fluid style={{ overflowX: 'hidden' }}>
        <NewsModalWrapper>
          <NewsModalBody>
            <LoadingState loading={loading} data={newsList.length > 0} error={!!error}>
              <LoadingState.Shimmer>
                <NewsModalShimmer />
              </LoadingState.Shimmer>

              <LoadingState.NoData>
                <NoDataPlaceholder message={!newsList.length ? strings?.newsModal.noData : ''} />
              </LoadingState.NoData>

              <LoadingState.Error>
                <ErrorPlaceholder onRetryClick={() => performRequest()} />
              </LoadingState.Error>
              <NewsListWrapper id='news-modal-body'>
                {newsList.map((news, index) => (
                  <div key={index}>
                    <VSeparator large />
                    <Body>{dateText({ unformattedDate: news.releasetDate, language: userInfo?.language })}</Body>
                    <VSeparator />
                    <H3 color={Color.Primary}>{news.title}</H3>
                    <VSeparator />
                    <NewsModalText dangerouslySetInnerHTML={{ __html: news.description }}></NewsModalText>
                    {handleImages(news.imageUrl)}
                    <VSeparator large />
                    <VSeparator large />
                  </div>
                ))}
              </NewsListWrapper>
            </LoadingState>
            <NewsModalFooter>
              <FlexRow hAlign='center'>
                <Button testid={`${TEST_ID_CLOSE_MODAL}-news`} onClick={() => handleClose()}>
                  {handleButtonText()}
                </Button>
              </FlexRow>
            </NewsModalFooter>
          </NewsModalBody>
        </NewsModalWrapper>
      </Grid>
    </Modal>
  );
};
