/* eslint-disable complexity */
import React from 'react';
import { useSelector } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { Stages, TechnicalAnalisysStatusList } from '@app/models/quote.model';
import { quoteIsInternalUserSelector } from '@app/modules/auth/store/auth.selectores';
import { SimpleToolTip } from '@app/modules/components/SimpleToolTip';
import { quoteBadges } from '@app/modules/quote/quote.contants';
import { TechnicalAnalysisStrings } from '@app/modules/quote/technical-analysis/technical-analysis.string';
import {
  TechnicalAnalysisContentStyled,
  TechnicalAnalysisStatusBoxStyled,
} from '@app/modules/quote/technical-analysis/technical-analysis.style';
import { Badge } from '@atomic/atm.badge';
import { Button } from '@atomic/atm.button';
import { Cell } from '@atomic/atm.cell';
import { CustomizedTooltip } from '@atomic/atm.customized-tooltip';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Hbox, Separator } from '@atomic/obj.box';
import { useEventListener } from '@atomic/obj.hooks/use-event-listener.hook';
import { LoadingState } from '@atomic/obj.loading-state';
import { QuoteFilterLabelStrings } from '../../quote.string';
import { TechnicalAnalysisContext } from '../technical-analysis.context';
import { checkPendingCharacteristcAnalysis } from '../utils/checkPendingCharacteristcAnalisys';

interface TechnicalAnalysisStatusListProps {
  onStatusClick: (stage: Stages) => void;
  onClientSubmit: (key: string, action: string) => void;
  data: TechnicalAnalisysStatusList;
  totalSimilarMaterial: number;
}

export const TechnicalAnalysisStatusList: React.FC<TechnicalAnalysisStatusListProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const quoteIsInternalUser = useSelector(quoteIsInternalUserSelector);
  const {
    setToolTipRequestAnalysis,
    setToolTipStatusButton,
    toolTipStatusButton,
    setToolTipPendingStatusButton,
    toolTipPendingButton,
    statusList,
    loadingStatusList,
  } = React.useContext(TechnicalAnalysisContext);

  const menuRef = React.useRef(null);
  const [showList, setShowList] = React.useState(false);
  const strings = TechnicalAnalysisStrings[userInfo.language].status;
  const labelGerdauResume = QuoteFilterLabelStrings[userInfo.language].resumoGerdau;
  const [tooltipVisible, setTooltipVisible] = React.useState<boolean>(
    props.data.externalStatus?.key === 'PROCESSING' ? false : true,
  );

  const disableStatusOptionsList = ['NO_CUSTOMER_RESPONSE', 'FINALIZED_WITH_DEROGATION', 'FINALIZED', 'CANCELED'];

  const handleMouseDown = (event: MouseEvent) => {
    if (showList && menuRef && !menuRef.current.contains(event.target)) {
      setShowList(false);
    }
  };
  useEventListener('mousedown', handleMouseDown);

  const closeTooTipSendToGerdau = () => {
    LogAnalytics.submit({
      tipo: 'FecharTooltipEnviarParaGerdau',
    });
    setTooltipVisible(false);
  };

  const submitSendToGerdau = (key: string) => {
    LogAnalytics.submit({
      tipo: 'EnviarParaGerdau',
    });
    props.onClientSubmit(key, 'sendToGerdau');
  };

  const [requestedPlant, setRequestedPlant] = React.useState<boolean>(false);

  React.useEffect(() => {
    props.data?.plants.map(plant => {
      if (plant.review?.isRequested) {
        setRequestedPlant(true);
      }
    });
  });

  const handleOnOptions = () => {
    if (
      (props.data.internalStatus.key === 'INTERNAL_QUERY' ||
        props.data.internalStatus.key === 'INTERNAL_QUERY_SELLER') &&
      !requestedPlant
    ) {
      const openToolTipRequestAnalysis = window.localStorage.getItem('openToolTipRequestAnalysis');
      setToolTipStatusButton(false);
      if (openToolTipRequestAnalysis !== 'no') {
        setToolTipRequestAnalysis(true);
      }
    }
    if (disableStatusOptionsList.includes(props.data.internalStatus.key)) {
      setShowList(false);
    } else {
      setShowList(true);
    }
  };

  const OPEN_REQUEST_ANALYSIS_TOOLTIP = 'openToolTipRequestAnalysis';

  const handleCloseToolTipStatus = () => {
    setToolTipStatusButton(false);
    window.localStorage.setItem(OPEN_REQUEST_ANALYSIS_TOOLTIP, 'no');
  };

  const verifyDerogateList = () => {
    let count = 0;
    props.data.plants.map(plant => {
      if (
        plant.label !== `${labelGerdauResume} (C)` &&
        plant.label !== `${labelGerdauResume} (P)` &&
        plant.label !== `${labelGerdauResume} (M)`
      ) {
        plant.attributes.map(attr => {
          if (
            checkPendingCharacteristcAnalysis(
              attr.status,
              attr.derogate?.permissions?.isEditable,
              attr.derogate?.suggestionStatus,
            )
          ) {
            count++;
          }
        });
      }
    });

    return count;
  };

  const handleShowToolTip = () => {
    setToolTipPendingStatusButton(true);
  };

  const checkPermissionFinalizedSeller = (status: string) => {
    if (
      status === 'FINALIZED' &&
      userInfo.profile.profile === 'seller' &&
      (props.data.internalStatus.key === 'VALIDATION_SELLER' ||
        props.data.internalStatus.key === 'INTERNAL_QUERY_SELLER') &&
      props.totalSimilarMaterial <= 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  //const hasBranding = props.data.branding === null ? (selectedBrand === null ? false : true) : true;

  return (
    <>
      {(!quoteIsInternalUser &&
        statusList?.length > 0 &&
        props.data.externalStatus?.key !== 'IN_VALIDATION' &&
        !props.data.cancelRequestReason) ||
      props.data.externalStatus?.key === 'PROCESSING' ? (
        <>
          <CustomizedTooltip
            title={TechnicalAnalysisStrings[userInfo.language].tooltipSendToGerdau.title}
            description={TechnicalAnalysisStrings[userInfo.language].tooltipSendToGerdau.description}
            visible={tooltipVisible}
            onClick={() => closeTooTipSendToGerdau()}
            onClose={() => closeTooTipSendToGerdau()}
            customHeight={40}
            alignRight={true}
            hasOffset
          >
            <Button
              data-testid={props.data.externalStatus.key}
              kind={verifyDerogateList() > 0 ? 'neutral' : 'callToAction'}
              onClick={verifyDerogateList() > 0 ? handleShowToolTip : () => submitSendToGerdau('UNDER_ANALYSIS')}
            >
              {verifyDerogateList() > 0 ? strings.pendingButton : strings.sendToGerdau}
            </Button>
          </CustomizedTooltip>
          <SimpleToolTip
            visible={toolTipPendingButton}
            description={TechnicalAnalysisStrings[userInfo.language].toolTipPendingButton.description}
            align='right'
            alignArrow='flex-end'
            customVerticalAlign={10}
            onClick={() => setToolTipPendingStatusButton(false)}
            onClose={() => setToolTipPendingStatusButton(false)}
          />
        </>
      ) : (
        quoteIsInternalUser && (
          <>
            <Button
              data-testid={props.data.internalStatus.key}
              kind={
                verifyDerogateList() > 0 && props.data.internalStatus.key === 'WAITING_CLIENT_REVIEW'
                  ? 'neutral'
                  : 'callToAction'
              }
              onClick={handleOnOptions}
            >
              {verifyDerogateList() > 0 && props.data.internalStatus.key === 'WAITING_CLIENT_REVIEW'
                ? strings.pendingButton
                : props.data?.internalStatus?.label}

              {!disableStatusOptionsList.includes(props.data.internalStatus.key) && statusList?.length > 0 && (
                <>
                  <Hbox.Separator />
                  <FaIcon.ChevronDown size='1x' />
                </>
              )}
            </Button>
            <SimpleToolTip
              visible={toolTipStatusButton}
              description={TechnicalAnalysisStrings[userInfo.language].toolTipStatusButton.description}
              btnLabel={TechnicalAnalysisStrings[userInfo.language].toolTipStatusButton.buttonText}
              align='right'
              alignArrow='flex-end'
              customVerticalAlign={10}
              steps={TechnicalAnalysisStrings[userInfo.language].toolTipStatusButton.steps}
              onClick={handleCloseToolTipStatus}
              onClose={handleCloseToolTipStatus}
            />
            <SimpleToolTip
              visible={toolTipPendingButton}
              description={
                props.data.branding === null
                  ? TechnicalAnalysisStrings[userInfo.language].branding.tooltip
                  : TechnicalAnalysisStrings[userInfo.language].toolTipPendingButton.description
              }
              align='right'
              alignArrow='flex-end'
              customVerticalAlign={10}
              onClick={() => setToolTipPendingStatusButton(false)}
              onClose={() => setToolTipPendingStatusButton(false)}
            />
            {showList && statusList?.length > 0 ? (
              <TechnicalAnalysisStatusBoxStyled show={showList} ref={menuRef}>
                <LoadingState loading={loadingStatusList} enableActivityIndicator={true}>
                  {statusList.length > 0 && (
                    <>
                      {statusList.map(
                        option =>
                          checkPermissionFinalizedSeller(option.key) && (
                            <Cell hover key={option.key} hideBorder onClick={() => props.onStatusClick(option)}>
                              <TechnicalAnalysisContentStyled>
                                <Badge data-testid={option.key} color={quoteBadges[option.key]?.color}>
                                  {option.label}
                                </Badge>
                              </TechnicalAnalysisContentStyled>
                            </Cell>
                          ),
                      )}
                    </>
                  )}
                  <Separator small />
                </LoadingState>
              </TechnicalAnalysisStatusBoxStyled>
            ) : null}
          </>
        )
      )}
    </>
  );
};
