import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { AuthStorageService } from '../storage/auth.storage';
import { ShippingRequestParams } from './shipping-request.dto';

const endpoint = {
  SHIPPING_REQUEST: '/shipping',
};

export const ShippingRequestDataSource = {
  submitShippingRequest: (params: ShippingRequestParams): Promise<void> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .post(`${endpoint.SHIPPING_REQUEST}`)
      .headers({
        'Content-Type': 'application/json',
        Accept: '*/*',
        'Sso-Access': user.ssoAccess,
      })
      .data(params)
      .execute();
  },
};
