import React from 'react';
import { Element } from 'react-scroll';
import { IsaLoading } from '@app/modules/components/isa-loading-component';
import { UploadNormLoading } from '@app/modules/components/uploadNormLoading';
import { Button } from '@atomic/atm.button';
import { H2 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Form } from '@atomic/obj.form';
import { LoadingState } from '@atomic/obj.loading-state';
import { FormCellShimmer } from './components/form-cell.shimmer';
import { FormSection } from './components/formSection';
import { FormResult } from './components/result/form-result.component';
import {
  DetailsWrapper,
  FormResultWrapper,
  FormScrollWrapper,
  FormWrapper,
  MainQuestion,
  QuestionWrapper,
  SummaryWrapper,
} from './components/result/form-result.component.style';
import { StepsHeader } from './components/steps-header.component';
import { FormContext, FormProvider } from './form.context';
import { ModalAttributeContainer } from './modal/modal-attribute.container';
import { ModalConfirmationForm } from './modal/modal-confirmation-form.component';
import { ModalErrorNluForm } from './modal/modal-error-nlu-form.component';
import { getInitials } from './utils/getInitials';
import { resetChat } from './utils/resetChat';

export const FormContainer = () => {
  const {
    formResult,
    setFormResult,
    disableSubmit,
    conversationId,
    cfId,
    userInfo,
    strings,
    showModalConfirmation,
    showModalErroNlu,
    setShowModalErroNlu,
    initialized,
    show,
    createAnalysis,
    loadingCreateAnalysis,
    loadingPostFile,
    loadingFlowClient,
    dataFlowClient,
    dataFlowSteel,
    dataFlowProduct,
    dataFlowGoal,
    dataFlowAdditional,
    loadingFlowSteel,
    loadingFlowProduct,
    loadingFlowGoal,
    loadingFormFlowAdditional,
    // flow,
  } = React.useContext(FormContext);

  const handleSubmit = async formFields => {
    if (Object.keys(formFields.error).length <= 0) {
      if (formResult?.CLIENT_CODE?.value) {
        if (formResult?.CHECK_FILE?.value?.key === 'YES') {
          if (formResult?.SPECIFIC_SPECIFICATION_NAME && formResult?.SPECIFIC_SPECIFICATION_NAME.file.length > 0) {
            const conditionalFields = formResult?.flow[0].fields.filter(
              field => field?.conditional?.key === 'CHECK_FILE' || field?.conditional?.key === 'CHEMICAL_COMPOSITION',
            );
            conditionalFields.forEach(field => {
              field.display = false;
            });

            createAnalysis();
          } else {
            show('alert', strings.newForm.messages.attached);
          }
        } else {
          const conditionalFields = formResult.flow[0].fields.filter(field => field?.conditional?.key === 'CHECK_FILE');
          conditionalFields.forEach(field => {
            field.display = false;
          });
          createAnalysis();
        }
      } else {
        show('alert', strings.newForm.messages.alertClient);
      }
    }
  };

  document.addEventListener('keydown', function(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  });

  return (
    <LoadingState loading={loadingCreateAnalysis || loadingPostFile} enableActivityIndicator={false}>
      {loadingCreateAnalysis ? (
        <LoadingState.CustomLoading>
          <IsaLoading message={loadingCreateAnalysis ? strings.loading.initialize : strings.loading.finish} />
        </LoadingState.CustomLoading>
      ) : (
        <LoadingState.CustomLoading>
          <UploadNormLoading message={strings.loading.analyzing} />
        </LoadingState.CustomLoading>
      )}

      <Form id='formNewQuote' onSubmit={handleSubmit}>
        <FormWrapper style={{ display: 'flex', height: 'calc(100vh - 60px)' }}>
          <StepsHeader />
          <FormScrollWrapper id='scrollContainer'>
            <Element name='intro' style={{ height: 'auto', marginBottom: '40px' }}>
              <QuestionWrapper>
                <MainQuestion>
                  {strings.newForm.introdution} <b>{getInitials(userInfo.name)}</b>
                </MainQuestion>
              </QuestionWrapper>
            </Element>
            <LoadingState loading={loadingFlowClient} data={dataFlowClient || false}>
              <LoadingState.Shimmer>
                <FormCellShimmer />
                <Separator />
              </LoadingState.Shimmer>
              <FormSection step={dataFlowClient && formResult?.CLIENT} stepIndex={0} />
            </LoadingState>

            <LoadingState loading={loadingFlowSteel} data={dataFlowSteel || false}>
              <LoadingState.Shimmer>
                <FormCellShimmer />
                <Separator />
              </LoadingState.Shimmer>
              <FormSection step={dataFlowSteel && formResult?.STEEL} stepIndex={1} />
            </LoadingState>

            <LoadingState loading={loadingFlowProduct} data={dataFlowProduct || false}>
              <LoadingState.Shimmer>
                <FormCellShimmer />
                <Separator />
              </LoadingState.Shimmer>
              <FormSection step={dataFlowProduct && formResult?.PRODUCT} stepIndex={2} />
            </LoadingState>

            <LoadingState loading={loadingFlowGoal} data={dataFlowGoal || false}>
              <LoadingState.Shimmer>
                <FormCellShimmer />
                <Separator />
              </LoadingState.Shimmer>
              <FormSection step={dataFlowGoal && formResult?.GOAL} stepIndex={3} />
            </LoadingState>

            <LoadingState loading={loadingFormFlowAdditional} data={dataFlowAdditional || false}>
              <LoadingState.Shimmer>
                <FormCellShimmer />
                <Separator />
              </LoadingState.Shimmer>
              <FormSection step={dataFlowAdditional && formResult?.ADDITIONAL} stepIndex={3} />
            </LoadingState>

            {formResult && Object.entries(formResult).length === 1 && (
              <Element>
                <DetailsWrapper bg={false}>
                  <SummaryWrapper bg={false}>
                    <H2 bold={700}>
                      Próxima etapa: <span>Preencha os campos necessários na etapa 1 para continuar</span>
                    </H2>
                  </SummaryWrapper>
                </DetailsWrapper>
              </Element>
            )}

            <Hbox.Item grow>
              <Button expanded kind='callToAction' type='submit' disabled={loadingPostFile || disableSubmit}>
                {strings.newForm.submitButton}
              </Button>
            </Hbox.Item>
            <Hbox.Separator small />
            <Separator />
          </FormScrollWrapper>

          <FormResultWrapper>
            <Hbox.Item noGrow>
              <FormResult initialized={initialized} resetChat={resetChat} />
            </Hbox.Item>
          </FormResultWrapper>
        </FormWrapper>
      </Form>

      <ModalAttributeContainer />

      <ModalConfirmationForm
        language={userInfo.language}
        setFormResult={setFormResult}
        opened={showModalConfirmation}
        cfId={cfId}
      />

      <ModalErrorNluForm
        language={userInfo.language}
        opened={showModalErroNlu}
        setShowModalErroNlu={setShowModalErroNlu}
        conversationId={conversationId}
      />
    </LoadingState>
  );
};

export const FormPage = () => {
  return (
    <FormProvider>
      <FormContainer />
    </FormProvider>
  );
};
