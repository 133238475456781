import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLazyRequest } from '@app/core/hook/lazy-request.hook';
import { RootState } from '@app/core/redux/store';
import { DocumentDataSource } from '@app/data/http/document.datasource';
import { DownloadZipParams } from '@app/data/http/download-zip-params.dto';
import { UserData } from '@app/providers';
import { ActivityIndicator } from '@atomic/atm.activity-indicator';
import { Cell } from '@atomic/atm.cell';
import { FaIcon } from '@atomic/atm.fa-icon';
import { BodySecondary, ButtonText, H4 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { IconSize } from '@atomic/obj.constants';
import { OrdersStrings } from './orders.string';

interface OrderDocumentsCellProps {
  boarding: any;
  registrationDate: string;
  user?: UserData;
}

export const OrderDocumentsCell: React.FC<OrderDocumentsCellProps> = props => {
  const { userInfo, azureToken } = useSelector((state: RootState) => state.auth);
  const { clientBpsSelected } = useSelector((state: RootState) => state.customerPersist);
  const customerIds = clientBpsSelected.map(client => client.customerId).join(',');

  const handleDownloadSuccess = (response: File) => {
    const blob = new Blob([response], { type: 'application/zip' });
    const link = document.createElement<'a'>('a');
    link.download = 'documents.zip';
    link.href = window.URL.createObjectURL(blob);
    link.click();
  };

  const { performRequest, loading } = useLazyRequest<DownloadZipParams, File>(
    DocumentDataSource.getZipFile,
    handleDownloadSuccess,
  );

  const handleDoanload = () => {
    performRequest({
      boardingNumber: props.boarding,
      customerIds: customerIds,
      authorizationToken: azureToken,
    });
  };

  return (
    <Cell>
      <Hbox>
        <Hbox.Item hAlign='flex-end'>
          {loading ? (
            <ActivityIndicator type='spinner' size={IconSize.Medium} />
          ) : (
            <ButtonText kind='link' onClick={() => handleDoanload()}>
              <FaIcon.FileDownload />
            </ButtonText>
          )}
        </Hbox.Item>
      </Hbox>

      <H4>
        {OrdersStrings[userInfo?.language]?.documents.boarding} {props.boarding}
      </H4>

      <BodySecondary>
        {OrdersStrings[userInfo?.language]?.documents.regDate} {props.registrationDate}
      </BodySecondary>
    </Cell>
  );
};
