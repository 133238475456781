import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { TextField } from '@atomic/atm.text-field';
import { H1, H3 } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { FormContext } from '../form.context';
import { FormStrings } from '../form.string';

interface MacroataqueRowProps {
  label: string;
  value?: number;
  name: string;
  strings: any;
  language: string;
}

const MacroataqueRow: React.FC<MacroataqueRowProps> = props => {
  return (
    <TR>
      <TD>
        <H3>{props.label}</H3>
      </TD>
      <TD>
        <Form.Field
          name={props.name}
          validators={[Validators.Required(props.strings.mandatory)]}
          initialValue={props?.value ? props.value : ''}
          hideErrorCaption
        >
          <TextField />
        </Form.Field>
      </TD>
    </TR>
  );
};

interface ModalMacroAtaqueProps {
  data?: ElementProps[];
  open: boolean;
  submitModals: (stepKey: string, key: string, values: any) => void;
}

interface ElementProps {
  element: string;
  value: number;
}

export const ModalMacroataque: React.FC<ModalMacroAtaqueProps> = props => {
  const { setModalToOpen } = React.useContext(FormContext);
  const [reset, setReset] = React.useState(0);
  const [opened, setOpened] = React.useState(props.open);
  const [initialElements, setInitialElements] = React.useState([{ element: 'S' }, { element: 'R' }, { element: 'C' }]);

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = FormStrings[userInfo.language].modalMacroataque;

  React.useEffect(() => {
    if (props?.data) {
      setInitialElements(props.data);
    }
  }, [props.data]);

  const convertDataToNumber = (val: string | number) => {
    if (val) {
      return val.toString().replace(',', '.');
    }
    return val;
  };

  const handleSubmit = (data: FormData<any>) => {
    if (Object.keys(data.error).length > 0) {
      return;
    }

    const newValues = Object.entries(data.data).map((item: [string, string | number]) => {
      return {
        element: item[0],
        value: convertDataToNumber(item[1]),
      };
    });

    props.submitModals('ADITIONAL', 'MACRO_ETCH', {
      key: 'MACRO_ETCH',
      label: 'Macroataque',
      question: 'Macroataque',
      type: 'table',
      permissions: { visible: true, editable: true },
      display: true,
      value: newValues,
    });

    setModalToOpen(null);
  };

  const handleReset = () => {
    setReset(reset + 1);
  };

  const handleClose = () => {
    setOpened(false);
    setModalToOpen(null);
  };

  return (
    <Modal preventOverlayClick opened={opened} small onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Grid fluid>
          <Row mb>
            <Col xs={12}>
              <H1>{strings.title}</H1>
            </Col>
          </Row>
          <Row mb key={reset}>
            <Col xs={12}>
              <Table>
                {initialElements.map((item: ElementProps) => (
                  <MacroataqueRow
                    key={item.element}
                    strings={strings}
                    label={item.element}
                    name={item.element}
                    value={item?.value}
                    language={userInfo.language}
                  />
                ))}
              </Table>
            </Col>
          </Row>
          <Row mb>
            <Col xs={6}>
              <Button kind='secondary' expanded onClick={handleReset}>
                {strings.btnReset}
              </Button>
            </Col>
            <Col xs={6}>
              <Button kind='primary' expanded type='submit'>
                {strings.btnSave}
              </Button>
            </Col>
          </Row>
        </Grid>
      </Form>
    </Modal>
  );
};
