export const AppPath = {
  CACHE_ROUTE: '/',
  AUTHENTICATION: '/auth',
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  ORDERS: '/order',
  ORDER_INPUT: '/order-input',
  SHIPPING_REQUEST: '/shipping-request',
  ORDER_UPDATE: '/order-update',
  FINANCE: '/finance',
  TRUCK_TRACKING: '/truck-tracking',
  QUOTE: {
    BASE: '/quote',
    PENDINGS: '/quote/my-analysis',
    DRAFTS: '/quote/drafts',
    ANALYSIS: '/quote/analysis',
    ANALYSIS_DETAIL: '/quote/analysis/:id',
    CRM: '/quote/crm',
    CRM_DETAIL: '/quote/crm/:cfId',
    FORM: {
      BASE: '/form',
      DETAIL: '/form/:action/:analysisId?',
      NEW: 'form/new',
    },
    FORM_ADMIN: '/form-admin',
    CLIENTS: '',
  },
  CHAT: {
    BASE: '/chat',
    DETAIL: '/chat/:action/:analysisId?',
    NEW: '/chat/new',
  },
  CHAT_V2: {
    BASE: '/chat-v2',
    DETAIL: '/chat-v2/:action/:analysisId?',
    NEW: '/chat-v2/new',
  },
  NEW_ADMIN: {
    BASE: '/new-admin',
    DETAIL: '/new-admin/group',
  },
  ADMIN: {
    BASE: '/admin',
    EDIT: '/admin/user',
  },
  ADMIN_SETTINGS: '/settings',
  HELP_CENTER: '/help-center',
  DOCUMENTS: '/documents',
  CATALOGS: '/catalogs',
  REDIRECT: '/redirect/:page/:detail?',
  ERROR: '/error',
  DASHBOARD: '/dashboard',
  COMPLAIN: {
    BASE: '/complain',
    REPORT: '/complain/report',
    CRM: '/complain/crm',
    CRM_DETAIL: '/complain/crm/:cfId',
  },
  SURROGATE: '/surrogate',
};
