import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { useDownloadAsset } from '@app/modules/quote/technical-analysis/hooks/useDownloadAsset';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { InputLabel } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { FormStrings } from '../../form.string';
import { FormResultEditOptions } from './form-result-edit-options';
import { FormResultAttributeWrapper, FormResultOptionsWrapper } from './form-result.component.style';

interface FileNormProps {
  attrKey: string;
  stepKey: string;
  attribute: {
    display: boolean;
    key: string;
    label?: string;
    mandatory?: boolean;
    permissions?: {
      visible: boolean;
      editable: boolean;
    };
    type: string;
    creation: string;
    value: string;
    file?: File[];
  };
}

export const FileNorm: React.FC<FileNormProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = FormStrings[userInfo.language].fileNorm;

  const { mutate: handleDownload } = useDownloadAsset();

  return props.attribute?.permissions?.visible && props.attribute?.file ? (
    <FormResultAttributeWrapper
      data-testid={`analysisResume${props.attrKey}`}
      modified={props.attribute?.creation === 'NLU'}
    >
      <Hbox.Item>
        <FormResultOptionsWrapper>
          <InputLabel>
            {strings.title}{' '}
            {props.attribute?.creation === 'NLU' && (
              <span>{` ${QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}`}</span>
            )}
          </InputLabel>
          <FormResultEditOptions
            stepKey={props.stepKey}
            editable={props.attribute.permissions.editable}
            attrKey={props.attrKey}
            type={props.attribute.type}
          />
        </FormResultOptionsWrapper>
      </Hbox.Item>

      <Hbox>
        <Hbox.Item hAlign='flex-start'>
          <Button
            kind='link'
            onClick={() =>
              handleDownload({
                filename: props.attribute?.file[0]?.name,
                url: props.attribute.value,
                language: userInfo.language,
                type: 'pdf',
              })
            }
            size='small'
          >
            <Hbox>
              <Hbox.Item noGrow vAlign='center'>
                <FaIcon.PDF small />
              </Hbox.Item>
              <Hbox.Separator />
              <Hbox.Item>{props.attribute?.file[0]?.name}</Hbox.Item>
            </Hbox>
          </Button>
        </Hbox.Item>
      </Hbox>
    </FormResultAttributeWrapper>
  ) : null;
};
