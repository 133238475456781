import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { Material } from '@app/models';
import { AuthStorageService } from '../storage/auth.storage';
import { mapMaterials, MaterialsDTO } from './material.dto';

export interface GetMaterialParams {
  customerIds: string;
  customerMaterialCode?: string;
  salesDocumentItemText?: string;
}

const endpoint = {
  ORDERINPUT: 'mi/implantation-request',
  MATERIAL: '/material',
};

export const MaterialDatasource = {
  getMaterials: (params: GetMaterialParams) => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.ORDERINPUT + endpoint.MATERIAL)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: MaterialsDTO): Promise<Material[]> => mapMaterials(res),
      })
      .execute();
  },
};
