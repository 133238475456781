import * as React from 'react';
import { GetAssetsParams } from '@app/data/http/quote-assets-params.dto';
import { GetAnalysisParams } from '@app/data/http/quote-params.dto';
import { usePartialTechnicalAnalysis, useFullTechnicalAnalysis, useContextTechnicalAnalysis } from '@app/domain';
import { AvailableStatus, FullAnalysisData, IsaExternalRoles, Stages, UserListAllData } from '@app/models/quote.model';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName } from '@app/providers';
import { useGetStatus } from './hooks/useGetStatus';
import { useUsersByProfilesAllData } from './hooks/useUsersByProfiles';

interface GetUsersParams {
  language: string;
  profile: string;
  bp: string;
}
interface TechnicalAnalysisContextState {
  analysis: FullAnalysisData;
  analysisId: number;
  contextLoading: boolean;
  attributeLoading: boolean;
  fullLoading: boolean;
  contextError: Error;
  attributeError: Error;
  fullError: Error;
  users: UserListAllData;
  toolTipRequestAnalysis: boolean;
  toolTipStatusButton: boolean;
  toolTipPendingButton: boolean;
  avaliableStatus: AvailableStatus;
  loadingEditPlant: boolean;
  selectedBrand: string;
  blockLoading: boolean;
  loadingListUsers: boolean;
  statusList: Stages[];
  loadingStatusList: boolean;

  setUsers: (analysis: UserListAllData) => void;
  getUsers: (params: GetUsersParams) => void;
  setAnalysis: (analysis: any | FullAnalysisData) => void;
  setAnalysisId: (id: number) => void;
  getPartialAnalysis: (params: GetAnalysisParams) => void;
  getFullAnalysis: (params: GetAnalysisParams) => void;
  getAnalysis: (params: GetAnalysisParams) => void;
  updateAssetNumber: (amount: number) => void;
  setToolTipRequestAnalysis: (status: any) => void;
  setToolTipStatusButton: (status: any) => void;
  setToolTipPendingStatusButton: (status: any) => void;
  setAvaliableStatus: (status: AvailableStatus) => void;
  setLoadingEditPlant: (status: boolean) => void;
  setSelectedBrand: (brand: string) => void;
  setBlockLoading: (status: boolean) => void;
  getStatusList: (params: GetAssetsParams) => void;
}

export const TechnicalAnalysisContext = React.createContext<TechnicalAnalysisContextState>(null);

export const TechnicalAnalysisProvider: React.FC = props => {
  const [analysisId, setAnalysisId] = React.useState<number>(null);
  const [analysis, setAnalysis] = React.useState<FullAnalysisData>(null);
  const [users, setUsers] = React.useState<UserListAllData>(null);
  const [avaliableStatus, setAvaliableStatus] = React.useState<AvailableStatus>(null);
  const { getAnalysis, contextLoading, contextError } = useContextTechnicalAnalysis(setAnalysis);
  const { getFullAnalysis, fullLoading, fullError } = useFullTechnicalAnalysis(setAnalysis);
  const { getPartialAnalysis, attributeLoading, attributeError } = usePartialTechnicalAnalysis(setAnalysis);

  const [toolTipStatusButton, setToolTipStatusButton] = React.useState<boolean>(false);
  const [toolTipPendingButton, setToolTipPendingStatusButton] = React.useState<boolean>(false);
  const [toolTipRequestAnalysis, setToolTipRequestAnalysis] = React.useState<boolean>(false);
  const [loadingEditPlant, setLoadingEditPlant] = React.useState<boolean>(false);
  const [selectedBrand, setSelectedBrand] = React.useState<string>(null);
  const [blockLoading, setBlockLoading] = React.useState<boolean>(false);
  const [statusList, setStatusList] = React.useState(null);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const updateAssetNumber = React.useCallback(
    (amount: number) => {
      const updatedAnalysis = analysis;
      updatedAnalysis.assets.length = amount;
      setAnalysis(updatedAnalysis);
    },
    [analysis, setAnalysis],
  );

  const mapsList = (userList, profile) => {
    return {
      [profile.toLowerCase()]: userList.filter(u => u.profile === IsaExternalRoles[profile]).map(u => u),
    };
  };

  const { mutate: getUsers, isLoading: loadingListUsers } = useUsersByProfilesAllData(
    handleGetUsersSuccess,
    handleGetUsersError,
  );

  function handleGetUsersError(data) {
    show('alert', data.description);
  }

  function handleGetUsersSuccess(data) {
    setUsers(prevValue => ({ ...prevValue, ...mapsList(data.data, data.profile) }));
  }

  const { mutate: getStatusList, isLoading: loadingStatusList } = useGetStatus(
    handleGetStatusSuccess,
    handleGetStatusError,
  );

  function handleGetStatusSuccess(data) {
    setStatusList(
      data.stages.filter(status => {
        return status.key !== 'AVAILABLE_PRICE';
      }),
    );
  }
  function handleGetStatusError(data) {
    show('alert', data.message ? data.message : data.description);
  }

  const useCasesValues = {
    analysisId,
    setAnalysisId,
    analysis,
    setAnalysis,
    contextLoading,
    attributeLoading,
    attributeError,
    getAnalysis,
    getPartialAnalysis,
    contextError,
    getFullAnalysis,
    fullLoading,
    fullError,
    users,
    setUsers,
    getUsers,
    loadingListUsers,
    toolTipRequestAnalysis,
    setToolTipRequestAnalysis,
    toolTipStatusButton,
    setToolTipStatusButton,
    toolTipPendingButton,
    setToolTipPendingStatusButton,
    avaliableStatus,
    setAvaliableStatus,
    // getStatus,
    // loadingStatus,
    // errorStatus,
    loadingEditPlant,
    setLoadingEditPlant,
    selectedBrand,
    setSelectedBrand,
    blockLoading,
    setBlockLoading,
    statusList,
    loadingStatusList,
    getStatusList,
  };

  const value: TechnicalAnalysisContextState = {
    ...useCasesValues,
    updateAssetNumber,
  };

  const memoizedValue = React.useMemo(() => value, [value]);

  return <TechnicalAnalysisContext.Provider value={memoizedValue}>{props.children}</TechnicalAnalysisContext.Provider>;
};
