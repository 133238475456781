import { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { SelectOption } from '@app/modules/order-input/order-input.interfaces';
import { updateArray } from '@app/utils/array-helper';
import {
  IDeleteItem,
  IOrderTabOpenState,
  IRemovedAlertPayload,
  ISetTableRowsPayload,
  IUpdateObservationPayload,
  IUpdateRequestedShippingQuantity,
} from '../shipping-request.interfaces';
import { getFilteredPlantGroup, mapShippingRequestTableRows } from '../shipping-request.utils';

export const reducers = {
  setShowShippingRequest: (state: IOrderTabOpenState, { payload }) => {
    state.showShippingRequest = payload;
  },
  setOpenShippingFeedback: (state: IOrderTabOpenState, { payload }) => {
    state.openShippingFeedback = payload;
  },
  setTableRows: (state: IOrderTabOpenState, { payload }: PayloadAction<ISetTableRowsPayload>) => {
    const { selectedRows, isGKN } = payload;
    state.tableRows = mapShippingRequestTableRows(selectedRows, isGKN);
  },
  updateObservations: (state: IOrderTabOpenState, { payload }: PayloadAction<IUpdateObservationPayload>) => {
    const { salesOrder, heat, value } = payload;

    if (state.tableRows) {
      const index = state.tableRows.findIndex(row => row.salesOrder === salesOrder && row.heat === heat);
      state.tableRows[index].observations = value;
    }
  },
  updateRequestedShippingQuantity: (
    state: IOrderTabOpenState,
    { payload }: PayloadAction<IUpdateRequestedShippingQuantity>,
  ) => {
    const { salesOrder, heat, value } = payload;

    if (state.tableRows) {
      const index = state.tableRows.findIndex(row => row.salesOrder === salesOrder && row.heat === heat);
      if (!isNaN(value as any) && !isNaN(parseFloat(value))) {
        state.tableRows[index].requestedShippingQuantity = parseFloat(value);
      }
    }
  },
  setOpenReceiverModalIndex: (state: IOrderTabOpenState, { payload }: PayloadAction<number>) => {
    state.openReceiverModalIndex = payload;
  },
  setOpenAlertQtyModal: (state: IOrderTabOpenState, { payload }: PayloadAction<boolean>) => {
    state.openAlertQtyModal = payload;
  },
  updateReceiver: (state: IOrderTabOpenState, { payload }: PayloadAction<string>) => {
    const newReceiver = state.receiverOptions.find(opt => opt.value === payload);
    const newReceiverSelected = { tableRowIndex: state.openReceiverModalIndex, name: newReceiver.name };
    const updatedSelectedNewReceivers = updateArray(
      newReceiverSelected.tableRowIndex,
      'tableRowIndex',
      state.selectedNewReceivers,
      newReceiverSelected,
    );

    state.selectedNewReceivers = updatedSelectedNewReceivers;
    state.tableRows[state.openReceiverModalIndex].newReceiver = payload;
    state.openReceiverModalIndex = null;
  },
  setReceiverOptions: (state: IOrderTabOpenState, { payload }: PayloadAction<SelectOption[]>) => {
    state.receiverOptions = payload;
  },
  deleteItem: (state: IOrderTabOpenState, { payload }: PayloadAction<IDeleteItem>) => {
    const { salesOrder, heat } = payload;
    const tableRows = state.tableRows;

    const filteredTableRows = tableRows.filter(row => row.salesOrder !== salesOrder || row.heat !== heat);

    const index = state.tableRows.findIndex(row => row.salesOrder === salesOrder && row.heat === heat);
    const plantName = state.tableRows[index].plantName.toLocaleLowerCase();
    const materialItem = state.tableRows[index].material;
    const plantGroup = state[plantName];
    const filteredMaterialGroup = getFilteredPlantGroup(plantGroup, materialItem, salesOrder);
    state[plantName] = filteredMaterialGroup;
    state.tableRows = filteredTableRows;
  },
  removeAlert: (state: IOrderTabOpenState, { payload }: PayloadAction<IRemovedAlertPayload>) => {
    const { salesOrder, heat, value } = payload;

    if (state.tableRows) {
      const index = state.tableRows.findIndex(row => row.salesOrder === salesOrder && row.heat === heat);
      const plantName = state.tableRows[index].plantName.toLocaleLowerCase();
      const materialItem = state.tableRows[index].material;

      state.tableRows[index].removedAlert = value;

      const materialGroup = state[plantName]?.orderedUserGroup?.find(group => group.material === materialItem);

      if (materialGroup) {
        const currentOV = materialGroup.ovs.find(ov => ov.salesOrder === salesOrder);
        currentOV.removedAlert = value;
      }
    }
  },
  setOpenShippingRequestSuggestionModalMaterial: (state: IOrderTabOpenState, { payload }) => {
    state.openShippingRequestSuggestionModalMaterial = payload;
    state.suggestionOptions = [];
  },
  setMogPlant: (state: IOrderTabOpenState, { payload }) => {
    state.mog = payload;
  },
  setPinPlant: (state: IOrderTabOpenState, { payload }) => {
    state.pin = payload;
  },
  setChaPlant: (state: IOrderTabOpenState, { payload }) => {
    state.cha = payload;
  },
  setSuggestions: (state: IOrderTabOpenState, { payload }) => {
    state.suggestionOptions = payload;
  },
};
