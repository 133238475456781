import Container from 'typedi';
import { HttpInterceptor, HttpRequest } from '@app/core/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export class AzureTokenInterceptor implements HttpInterceptor {
  async before(request: HttpRequest): Promise<HttpRequest> {
    const user = Container.get(AuthStorageService).getUser();
    const azureToken = window.localStorage.getItem('azureToken');

    if (azureToken) {
      request.headers['Access-Token'] = user?.accessToken;
      request.headers.Authorization = `Bearer ${azureToken}`;
    }

    return request;
  }
}
