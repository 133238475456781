import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { SurrogateDataSource } from '@app/data/http/surrogate.datasource';
import { IAuthState } from '@app/modules/auth/auth.interfaces';
import { SurrogateEvents } from '../../surrogate.analytics';
import { SLICE_NAME } from '../../surrogate.constants';
import { ISurrogateState } from '../../surrogate.interfaces';
import { ISurrogateStrings, Strings } from '../../surrogate.strings';

export const deleteSurrogate = createAsyncThunk(`${SLICE_NAME}/deleteSurrogate`, async (_id: string, thunkAPI) => {
  const authState: any = thunkAPI.getState();
  const { userInfo }: IAuthState = authState.auth;
  const strings: ISurrogateStrings = Strings[userInfo.language];

  return await SurrogateDataSource.deleteSurrogate(_id)
    .then(() => {
      LogAnalytics.click({ tipo: SurrogateEvents.DeleteSurrogateSuccess });
      return { message: strings.success };
    })
    .catch(err => {
      LogAnalytics.error({
        tipo: SurrogateEvents.DeleteSurrogateError,
        errorMessage: err.response.message,
        errorDetails: JSON.stringify(err.response.data),
      });
      return thunkAPI.rejectWithValue({ status: err.response.status });
    });
});

export const addDeleteSurrogateReducer = builder => {
  builder.addCase(deleteSurrogate.pending, state => {
    state.loading = true;
  });
  builder.addCase(deleteSurrogate.fulfilled, (state: ISurrogateState, action) => {
    state.loading = false;
    state.successMessage = action.payload.message;
    state.error = false;
    state.showRemoveSurrogate = false;
  });
  builder.addCase(deleteSurrogate.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
  });
};
