import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { BaggHttpRequestBuilder } from '@app/data/http';

const endpoint = {
  SALESFORCE_ACCOUNTS: '/salesforce-accounts',
  FORM_COMPLAIN: '/form-complaints',
  FORM_COMPLAIN_FILES: '/form-attachment',
};

export const postComplain = async params => {
  const defaultHeaders = { ...Container.get(BaggHttpRequestBuilder).config.headers, language: params.language };

  const url = `${Container.get(BaggHttpRequestBuilder).config.baseUrl}${endpoint.FORM_COMPLAIN}`;

  try {
    const { data, status } = await axios.post(url, params, { headers: defaultHeaders });
    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    //handleUnauthorized(error);
    return null;
  }
};

export const usePostComplain = (onSuccess, onError) => {
  return useMutation(postComplain, {
    onSuccess,
    onError,
  });
};
