import { IAnnouncementRegularModalState } from '../announcement-regular-modal.interfaces';
import { initialState } from './announcement-regular-modal.initial';

export const reducers = {
  reset: () => {
    return initialState;
  },
  setRegularAnnouncements: (state: IAnnouncementRegularModalState, { payload }) => {
    state.announcements = payload;
  },
  setRegularOpened: (state: IAnnouncementRegularModalState, { payload }) => {
    state.opened = payload;
  },
};
