/* eslint-disable complexity */

import React from 'react';
import { TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { Form, Validators } from '@atomic/obj.form';
import { FormContext } from '../../form.context';
import { FormFlowProps } from '../form-flow-fields.container';
import { FormTextFieldWrapper, FormUnitWrapper } from '../result/form-result.component.style';

export const TextDefaultField = (props: FormFlowProps) => {
  const { formResult, strings } = React.useContext(FormContext);

  const stringsNewForm = strings.newForm.messages;

  return (
    <Form.Field
      name={props.field.key}
      validators={props?.field?.mandatory ? [Validators.Required(stringsNewForm.mandatoryAlert)] : []}
      initialValue={
        formResult !== null && formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value
          ? formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value
          : null
      }
    >
      <FormTextFieldWrapper>
        <TextField
          id={props.field.key}
          type={props.field.type}
          noArrows={props.field.type === 'number'}
          onBlur={value => props.handleValue(value.target.value, props.field.key, false, props.stepKey)}
        ></TextField>

        {formResult[props.stepKey]?.questions?.fields[props.field?.key]?.unit && (
          <FormUnitWrapper>
            <Body>{formResult[props.stepKey]?.questions?.fields[props.field?.key]?.unit.allowed[0].symbol}</Body>
          </FormUnitWrapper>
        )}
      </FormTextFieldWrapper>
    </Form.Field>
  );
};
