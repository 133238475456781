import React from 'react';
import { MechanicalPropertiesData } from '@app/modules/chat/modal-mechanical.component';
import { Attribute } from './form-result-attribute.component';
import { FormResultAttributeWrapper } from './form-result.component.style';

// tslint:disable-next-line:no-empty-interface
interface MechanicalTableProps extends MechanicalPropertiesData {
  attrKey: string;
  stepKey: string;
  attribute: any;
}

export const MechanicalTable: React.FC<MechanicalTableProps> = props => {
  const attr = { ...props.attribute };
  if (typeof props?.attribute?.value === 'number' || typeof props?.attribute?.value === 'string') {
    attr.type = 'text';
  } else {
    attr.type = 'range';
    attr.value = `${props?.attribute?.value?.min} - ${props?.attribute?.value?.max}`;
  }

  return (
    <FormResultAttributeWrapper>
      <Attribute stepKey={props.stepKey} attrKey={props.attrKey} attribute={attr} />
    </FormResultAttributeWrapper>
  );
};
