import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { BaggHttpRequestBuilder } from '@app/data/http';
import { ComplainClientResponse, mapComplainCases } from '@app/data/http/complain.dto';

const endpoint = {
  SALESFORCE_ACCOUNTS: '/salesforce-accounts',
  FORM_COMPLAIN: '/form-complaints',
  FORM_COMPLAIN_FILES: '/form-attachment',
  SALESFORCE_CASES: '/salesforce/cases',
};

interface ParamsProps {
  dataIni: string;
  dataFim: string;
}

export async function getComplainRegisters(params: ParamsProps): Promise<ComplainClientResponse> {
  const defaultHeaders = { ...Container.get(BaggHttpRequestBuilder).config.headers };

  const url = `${Container.get(BaggHttpRequestBuilder).config.baseUrl}${endpoint.SALESFORCE_CASES}?dataInicio=${
    params.dataIni
  }&dataFim=${params.dataFim}`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useComplainDownload(params?: ParamsProps, onSuccess?, onError?) {
  return useQuery(['getComplainRegisters', params], () => getComplainRegisters(params), {
    onSuccess,
    onError,
    select: data => {
      return mapComplainCases(data);
    },
    enabled: false,
    refetchOnWindowFocus: false,
  });
}
