import { IShippingRequestRowParams } from '@app/data/http/shipping-request.dto';
import { InStockOrderItem } from '@app/models/sales-order-item.model';
import { formatQtyField } from '@app/utils/string-utils';
import {
  MATERIAL_FIELD_STRING,
  tableColumnsDefinitions,
  tableColumnsDefinitionsGKN,
} from './shipping-request.constants';
import {
  IMaterialGroupInStockOrderItem,
  IMaterialGroupShippingRequestRow,
  IPlant,
  IShippingRequestColumns,
  IShippingRequestRow,
} from './shipping-request.interfaces';

export const filterSelectedRow = (selectedRow: InStockOrderItem[], plantName: string) => {
  return selectedRow.filter(row => row.plant === plantName);
};

export const getStockQuantityTotal = (rows: IShippingRequestRow[]): number => {
  return rows.reduce((sum, row) => sum + row.stockQuantity, 0);
};

export const mapShippingRequestTableRows = (
  selectedRows: InStockOrderItem[],
  isGKN: boolean,
): IShippingRequestRow[] => {
  return selectedRows?.map((row: InStockOrderItem, index: number) => {
    return {
      index,
      customerId: completeZeroReceiver(row.soldToParty),
      material: row.material,
      materialDescription: row.materialName,
      plantCode: row.plant,
      plantName: row.plantName,
      salesOrder: row.salesDocument,
      salesOrderItem: row.salesDocumentItem,
      purchaseOrder: row.customerPurchaseOrderNumber.toString(),
      customerProductCode: row.customerMaterialCode.toString(),
      stockQuantity: isGKN
        ? subtractShipmentQuantityAndPickingQuantity(row.stockQuantity, row.deliveryQuantity, row.pickingQuantity)
        : row.stockQuantity,
      shipmentQuantity: row.deliveryQuantity,
      batch: row.batch,
      receiver: completeZeroReceiver(row.soldToParty),
      newReceiver: null,
      observations: '',
      requestedShippingQuantity: 0,
      heat: row.heat,
      lastHeat: row.lastHeat,
      breakHeat: row.breakHeat,
      hasQuantityLastHeat: row.hasQuantityLastHeat,
      qtyStockLastHeat: row.qtyStockLastHeat,
      customerAgreedDate: row.customerAgreedDate,
    };
  });
};

export const subtractShipmentQuantityAndPickingQuantity = (
  stockQuantity: number,
  deliveryQuantity: number,
  pickingQuantity: number,
): number => {
  const total = stockQuantity - deliveryQuantity - pickingQuantity;
  return total;
};

export const completeZeroReceiver = (receiver: string) => {
  if (receiver.length >= 10) {
    return receiver;
  }

  return completeZeroReceiver('0' + receiver);
};

export const mapTableRowsToParams = (tableRows: IShippingRequestRow[]): IShippingRequestRowParams[] => {
  return tableRows.map((row: IShippingRequestRow) => ({
    customerId: row.customerId,
    receiver: row.newReceiver || row.receiver,
    material: row.material,
    materialDescription: row.materialDescription,
    plantCode: row.plantCode,
    plantName: row.plantName,
    salesOrder: row.salesOrder,
    salesOrderItem: row.salesOrderItem,
    purchaseOrder: row.purchaseOrder,
    customerProductCode: row.customerProductCode,
    stockQuantity: formatQtyField(row.stockQuantity),
    batch: row.batch,
    observations: row.observations,
    shipmentQuantity: formatQtyField(row.shipmentQuantity),
    heat: row.heat,
    lastHeat: row.lastHeat,
    breakHeat: row.breakHeat,
    hasQuantityLastHeat: row.hasQuantityLastHeat,
    confirmedMaterialDate: row.customerAgreedDate ? row.customerAgreedDate : '',
    lastHeatQuantity: row.qtyStockLastHeat ? row.qtyStockLastHeat.toString().replace('.', ',') : null,
    requestedShippingQuantity: !row.requestedShippingQuantity
      ? formatQtyField(row.stockQuantity)
      : formatQtyField(row.requestedShippingQuantity),
  }));
};

export const getTableColumnsDefinitions = (isGKN: boolean) => {
  let filteredColumns: IShippingRequestColumns[];
  if (isGKN) {
    filteredColumns = tableColumnsDefinitionsGKN;
  } else {
    filteredColumns = tableColumnsDefinitions;
  }
  return filteredColumns;
};

export const getOldestSuggestions = (selectedRows: InStockOrderItem[]) => {
  const mappedSuggestionOptions = mapShippingRequestTableRows(selectedRows, false);

  return mappedSuggestionOptions.sort(
    (a, b) =>
      new Date(handleDate(a.customerAgreedDate)).getTime() - new Date(handleDate(b.customerAgreedDate)).getTime(),
  );
};

const handleDate = (dateString: string): Date => {
  const [day, month, year] = dateString.split('/');
  return new Date(`${year}/${month}/${day}`);
};

const hasAlready = (filteredSelectedGroups: any[], material: string) => {
  let hasArrayAlready = false;

  if (filteredSelectedGroups.length > 0) {
    filteredSelectedGroups.forEach(materialItem => {
      if (materialItem.material === material) {
        hasArrayAlready = true;
      }
    });
  }

  return hasArrayAlready;
};

export const getFilteredSelectedGroups = (
  groupedSalesOrder: any[],
  selectedRows: IShippingRequestRow[],
): IMaterialGroupInStockOrderItem[] => {
  const filteredSelectedGroups: IMaterialGroupInStockOrderItem[] = [];

  selectedRows.forEach(item => {
    if (groupedSalesOrder.hasOwnProperty(item.material)) {
      const groupedSalesOrderMaterial: IMaterialGroupInStockOrderItem = {
        material: '',
        ovs: [],
      };

      const groupedSalesOrderByMaterial = groupedSalesOrder[item.material];

      if (
        groupedSalesOrderByMaterial &&
        groupedSalesOrderByMaterial.length > 1 &&
        !hasAlready(filteredSelectedGroups, groupedSalesOrderByMaterial[0].material)
      ) {
        groupedSalesOrderByMaterial.forEach(groupedMat => {
          groupedSalesOrderMaterial.ovs.push(groupedMat);
        });

        groupedSalesOrderMaterial.material = item.material;
        filteredSelectedGroups.push(groupedSalesOrderMaterial);
      }
    }
  });

  return filteredSelectedGroups;
};

export const groupByField = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const orderGroupByOldest = (selectedGroups: IMaterialGroupInStockOrderItem[]): IMaterialGroupInStockOrderItem[] => {
  const orderedSelectedGroups = [];

  //AGRUPAR POR MATERIAL E ORDENAR POR DATA E POR VALOR SUGERIDO DECRESCENTES
  selectedGroups.forEach(group => {
    const groupedSalesOrderMaterial: IMaterialGroupInStockOrderItem = {
      material: group.material,
      ovs: group?.ovs
        ?.sort(
          (a, b) =>
            new Date(handleDate(a.customerAgreedDate)).getTime() - new Date(handleDate(b.customerAgreedDate)).getTime(),
        )
        .sort(
          (a, b) =>
            getSuggestionValue(a.stockQuantity, a.deliveryQuantity) -
            getSuggestionValue(b.stockQuantity, b.deliveryQuantity),
        ),
    };

    orderedSelectedGroups.push(groupedSalesOrderMaterial);
  });

  return orderedSelectedGroups;
};

const orderUserSelectedRowsByOldest = (groupedUserSelectedRows: any): IMaterialGroupShippingRequestRow[] => {
  const orderUserSelectedRows: IMaterialGroupShippingRequestRow[] = [];

  for (const key of Object.entries(groupedUserSelectedRows)) {
    const groupArray = groupedUserSelectedRows[key[0]];
    const groupUserSelectedRows: IMaterialGroupShippingRequestRow = {
      material: groupArray[0].material,
      hasAlert: false,
      ovs: groupArray.sort(
        (a, b) =>
          new Date(handleDate(a.customerAgreedDate)).getTime() - new Date(handleDate(b.customerAgreedDate)).getTime(),
      ),
    };

    orderUserSelectedRows.push(groupUserSelectedRows);
  }

  return orderUserSelectedRows;
};

export const handleOldSalesOrderAlert = (
  plant: string,
  allData: InStockOrderItem[],
  selectedRows: IShippingRequestRow[],
): IPlant => {
  // OBTER TODAS AS OVS DA PLANTA SELECIONADA
  const plantGroupedSalesOrder: InStockOrderItem[] = allData.filter(
    ov => ov.plant === plant && ov.deliveryQuantity + ov.pickingQuantity < ov.stockQuantity,
  );

  // AGRUPAR TODAS OVS EM ESTOQUE POR MATERIAL
  const plantMaterialGroupedSalesOrder = groupByField(plantGroupedSalesOrder, MATERIAL_FIELD_STRING);

  // OBTER GRUPOS SELECIONADOS
  const filteredSelectedGroups: IMaterialGroupInStockOrderItem[] = getFilteredSelectedGroups(
    plantMaterialGroupedSalesOrder,
    selectedRows,
  );

  // ORDENAR CADA GRUPO PELA OV MAIS ANTIGA
  const orderedGroupByOldest: IMaterialGroupInStockOrderItem[] = orderGroupByOldest(filteredSelectedGroups);

  // OBTER TODAS AS OVS SELECIONADAS PELO USUARIO DA PLANTA SELECIONADA [plantCode]
  const plantGroupedUserSalesOrder = selectedRows.filter(ov => ov.plantCode === plant);

  // AGRUPAR OVS SELECIONADAS PELO USUARIO POR MATERIAL
  const plantGroupedUserSelectedRows = groupByField(plantGroupedUserSalesOrder, MATERIAL_FIELD_STRING);

  // ORDENAR CADA GRUPO DAS OVS SELECIONADAS PELO USUÁRIO PELA OV MAIS ANTIGA
  const orderedUserSelectedGroupedRowsByOldest = orderUserSelectedRowsByOldest(plantGroupedUserSelectedRows);

  const orderedGroupByOldestAux: IMaterialGroupInStockOrderItem[] = [];
  const orderedUserSelectedGroupedRowsByOldestAux: IMaterialGroupShippingRequestRow[] = [];

  // eslint-disable-next-line @typescript-eslint/prefer-for-of
  for (let index = 0; index < orderedUserSelectedGroupedRowsByOldest.length; index++) {
    // PARA CADA GRUPO QUE FOI SELECIONADO VERIFICAR SE POSSUI ALERTA PARA O GRUPO
    let hasOldSalesOrderForThisGroup = false;
    const newOrderedGroup = orderedGroupByOldest.find(
      oGroup => oGroup.material === orderedUserSelectedGroupedRowsByOldest[index].material,
    );
    const newOrderedUserSelectedGroup = orderedUserSelectedGroupedRowsByOldest[index];
    const shippingRequestRows = orderedUserSelectedGroupedRowsByOldest[index]?.ovs;
    const orderStockItens = newOrderedGroup?.ovs;

    if (shippingRequestRows && orderStockItens) {
      if (shippingRequestRows.length === 1 && !hasAnyOVWithSameOldestDateOV(shippingRequestRows, orderStockItens)) {
        hasOldSalesOrderForThisGroup = true;
      } else if (shippingRequestRows.length === orderStockItens.length) {
        hasOldSalesOrderForThisGroup = false;
      } else if (hasAnyOVWithSameOldestDateOV(shippingRequestRows, orderStockItens)) {
        // VERIFICAR SE ALGUMA DAS OVS SELECIONADAS É A MAIS ANTIGA OU ESTÁ NO MESMO AGRUPAMENTO POR DATA QUE A MAIS ANTIGA
        hasOldSalesOrderForThisGroup = false;
      } else {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        // VERIFICANDO DE FORMA ORDENADA SE EXISTE DIVERGÊNCIA NA ORDENAÇÃO DOS GRUPOS, CASO EXISTA SIGNIFICA QUE EXISTEM OVS ANTIGAS SENDO SUGERIDAS
        for (let j = 0; j < shippingRequestRows.length; j++) {
          const shippingRequestRowOV = shippingRequestRows[j].salesOrder;
          const orderStockItemOV = orderStockItens[j].salesDocument;

          if (orderStockItemOV !== shippingRequestRowOV) {
            hasOldSalesOrderForThisGroup = true;
          }
        }
      }
    }

    if (newOrderedUserSelectedGroup) {
      newOrderedUserSelectedGroup.hasAlert = hasOldSalesOrderForThisGroup;
    }

    orderedGroupByOldestAux.push(newOrderedGroup);
    orderedUserSelectedGroupedRowsByOldestAux.push(newOrderedUserSelectedGroup);
  }

  return {
    plantCode: plant,
    orderedGroup: orderedGroupByOldestAux,
    orderedUserGroup: orderedUserSelectedGroupedRowsByOldestAux,
  };
};

const hasAnyOVWithSameOldestDateOV = (selectedOVs: IShippingRequestRow[], suggestedOVs: InStockOrderItem[]) => {
  let hasSameOVLikeOldestDateOV = false;
  const orderedByOldestSuggestedOV = suggestedOVs.sort(
    (a, b) =>
      new Date(handleDate(a.customerAgreedDate)).getTime() - new Date(handleDate(b.customerAgreedDate)).getTime(),
  )[0].customerAgreedDate;

  const oldestSuggestedOVDate = orderedByOldestSuggestedOV;

  selectedOVs.forEach(selectedOV => {
    if (selectedOV.customerAgreedDate === oldestSuggestedOVDate) {
      hasSameOVLikeOldestDateOV = true;
    }
  });

  return hasSameOVLikeOldestDateOV;
};

const getMaterialSequence = (oldTableRows: IShippingRequestRow[]) => {
  const sequence = [];

  oldTableRows.forEach(row => {
    const hasMaterialAlready = sequence.find(item => item === row.material);

    if (!hasMaterialAlready) {
      sequence.push(row.material);
    }
  });

  return sequence;
};

export const handleNewTableRows = (
  oldTableRows: IShippingRequestRow[],
  material: string,
  selectedSuggestions: IShippingRequestRow[],
) => {
  const materialSequence = getMaterialSequence(oldTableRows);
  const removedMaterialArray: IShippingRequestRow[] = [];
  const newTableRows: IShippingRequestRow[] = [];
  const newTableRowsAux: IShippingRequestRow[] = [];

  oldTableRows.forEach(row => {
    if (row.material !== material) {
      removedMaterialArray.push(row);
    }
  });

  removedMaterialArray.forEach(item => {
    newTableRows.push(item);
  });

  selectedSuggestions.forEach(suggestion => {
    newTableRows.push(suggestion);
  });

  let indexCount = 0;

  materialSequence.forEach(materialSeq => {
    const rowsByMaterial = newTableRows.filter(newRow => newRow.material === materialSeq);

    rowsByMaterial.forEach(row => {
      const rowAux = {
        index: indexCount,
        customerId: row.customerId,
        material: row.material,
        materialDescription: row.materialDescription,
        plantCode: row.plantCode,
        plantName: row.plantName,
        salesOrder: row.salesOrder,
        salesOrderItem: row.salesOrderItem,
        purchaseOrder: row.purchaseOrder,
        customerProductCode: row.customerProductCode,
        stockQuantity: row.stockQuantity,
        shipmentQuantity: row.shipmentQuantity,
        batch: row.batch,
        receiver: row.receiver,
        newReceiver: row.newReceiver,
        requestedShippingQuantity: row.requestedShippingQuantity,
        observations: row.observations,
        heat: row.heat,
        lastHeat: row.lastHeat,
        breakHeat: row.breakHeat,
        hasQuantityLastHeat: row.hasQuantityLastHeat,
        qtyStockLastHeat: row.qtyStockLastHeat,
        removedAlert: row.removedAlert,
        customerAgreedDate: row.customerAgreedDate,
      };

      newTableRowsAux.push(rowAux);
      indexCount += 1;
    });
  });

  return newTableRowsAux;
};

export const getUpdatedPlant = (
  material: string,
  plantData: IPlant,
  updatedTableRow: IShippingRequestRow[],
): IPlant => {
  const updatedOrderedUserGroup: IMaterialGroupShippingRequestRow[] = [];
  const rowsToAdd = updatedTableRow.filter(row => row.material === material && row.plantCode === plantData.plantCode);

  plantData.orderedUserGroup.forEach(group => {
    if (group.material === material) {
      updatedOrderedUserGroup.push({
        material: material,
        hasAlert: false,
        ovs: rowsToAdd,
      });
    } else {
      updatedOrderedUserGroup.push(group);
    }
  });

  const updatedPlant = {
    plantCode: plantData.plantCode,
    orderedGroup: plantData.orderedGroup,
    orderedUserGroup: updatedOrderedUserGroup,
  };

  return updatedPlant;
};

export const getValidSuggestions = (ovs: InStockOrderItem[]) => {
  const validSuggestions: InStockOrderItem[] = [];

  ovs.forEach(ov => {
    if (ov && ov.deliveryQuantity + ov.pickingQuantity < ov.stockQuantity) {
      validSuggestions.push(ov);
    }
  });

  return validSuggestions;
};

export const getSuggestionValue = (stockQty: number, shippingQty: number) => {
  const result = stockQty - shippingQty;

  return Math.abs(result);
};

export const getFilteredPlantGroup = (currentPlantGroup, material, salesOrder) => {
  const newPlantGroup = {
    plantCode: currentPlantGroup.plantCode,
    orderedGroup: currentPlantGroup.orderedGroup,
    orderedUserGroup: [],
  };

  currentPlantGroup.orderedUserGroup.forEach(group => {
    const newGroup = { material: group.material, hasAlert: group.hasAlert, ovs: [] };

    if (group.material !== material) {
      newGroup.ovs = group.ovs;
      newPlantGroup.orderedUserGroup.push(newGroup);
    } else {
      const newOVs = [];

      group.ovs.forEach(ov => {
        if (ov.salesOrder !== salesOrder) {
          newOVs.push(ov);
        }
      });

      newGroup.ovs = newOVs;
      newPlantGroup.orderedUserGroup.push(newGroup);
    }
  });

  return newPlantGroup;
};
