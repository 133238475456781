import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';

interface ParamsEdit {
  id: string;
  file?: any;
  title: string;
  expirationDate: string;
  documentType?: string;
}

export const editDocument = async (params?: ParamsEdit) => {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: user.language };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.DOCUMENTS}/${params.id}`;

  const form = new FormData();

  form.append('title', params.title);
  form.append('documentType', params.documentType);
  if (params.expirationDate) {
    form.append('expirationDate', params.expirationDate);
  }
  if (params?.file !== undefined) {
    form.append('file', params.file);
  }

  const { data: dataResponse } = await axios.patch(url, form, { headers: defaultHeaders });

  return dataResponse;
};

export const useEditDocument = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(editDocument, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(['getDocumentList']);
    },
  });
};
