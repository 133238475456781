import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { ConfigurationsReponse } from '@app/data/http/quote-params.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { handleUnauthorized } from '@app/utils/http-utils';

export async function getChatCreateV2(): Promise<ConfigurationsReponse> {
  const user = Container.get(AuthStorageService).getUser();

  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: user.language };

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.CONFIGURATIONS}`;

  try {
    const { data, status } = await axios.get(url, { headers: defaultHeaders });
    if (status === 200) {
      return data;
    } else {
      return null;
    }
  } catch (error) {
    handleUnauthorized(error);
    return null;
  }
}

export function useChatCreate(onSuccess?, onError?) {
  return useQuery(['getChatCreateV2'], () => getChatCreateV2(), {
    onSuccess,
    onError,
    enabled: true,
    refetchOnWindowFocus: false,
  });
}
