import * as React from 'react';
import {
  TechnicalAnalysisUserListStyled,
  TechnicalAnalysisUserListWrappperStyled,
  UserListStyled,
  UserListWrapperStyled,
} from '@app/modules/quote/technical-analysis/status/technical-analysis-status.style';
import { Cell } from '@atomic/atm.cell';
import { H4 } from '@atomic/atm.typography';
import { LoadingState } from '@atomic/obj.loading-state';
import { ClientList } from '../../form.context';

interface ChatClientListProps {
  users: ClientList[];
  show: boolean;
  loading: boolean;
  onSelect: (user: ClientList) => void;
  onClose: () => void;
}

export const AutoCompleteList: React.FC<ChatClientListProps> = props => {
  return (
    <TechnicalAnalysisUserListWrappperStyled>
      <TechnicalAnalysisUserListStyled show={props.show}>
        <LoadingState loading={props.loading} data={props.users?.length > 0}>
          <LoadingState.Shimmer>{/* <TechnicalAnalysisUserShimmer /> */}</LoadingState.Shimmer>

          <UserListWrapperStyled>
            <UserListStyled>
              {props.users?.map(user => (
                <Cell hover key={`${user.bp}`} onClick={() => props.onSelect(user)}>
                  <H4>{user.formatList}</H4>
                </Cell>
              ))}
            </UserListStyled>
          </UserListWrapperStyled>
        </LoadingState>
      </TechnicalAnalysisUserListStyled>
    </TechnicalAnalysisUserListWrappperStyled>
  );
};
