/* eslint-disable complexity */

import React from 'react';
import { Button } from '@atomic/atm.button';
import { Form } from '@atomic/obj.form';
import { FormContext } from '../../form.context';
import { FormFlowProps } from '../form-flow-fields.container';

export const TableField = (props: FormFlowProps) => {
  const { openModals } = React.useContext(FormContext);

  return (
    <Form.Field name={props.field.key}>
      <Button onClick={() => openModals(props.field.key)}>
        {(props.field.key === 'ADDITIONAL_DIAMETER' || props.field.key === 'ADDITIONAL') && !props?.field?.value
          ? props.field.label
          : `Editar ${props.field.label}`}
      </Button>
    </Form.Field>
  );
};
