import { AnyAction, configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import { adminReducer } from '@app/modules/admin/store/admin.store';
import { authPersistReducer } from '@app/modules/auth/store/auth.store';
import { announcementReducer } from '@app/modules/components/announcement-modal/store/announcement-modal.store';
import { regularAnnouncementReducer } from '@app/modules/components/announcement-regular-modal/store/announcement-regular-modal.store';
import { customerPersistReducer } from '@app/modules/components/customer-search/store/customer-persist.store';
import { customerSearchReducer } from '@app/modules/components/customer-search/store/customer-search.store';
import { emailSendingFailureReducer } from '@app/modules/components/email-sending-failure-modal/store/email-sending-failure-modal.store';
import { notificationReducer } from '@app/modules/components/notification-center/store/notification.store';
import { npsReducer } from '@app/modules/components/nps-modal/store/nps-modal.store';
import { preventiveStopPersistReducer } from '@app/modules/components/preventive-stop-modal/store/preventive-stop-modal.store';
import { scheduleReducer } from '@app/modules/components/schedule-modal/store/schedule.store';
import { advancedFiltersReducer } from '@app/modules/components/side-bar-filters/store/sidebar-filters.store';
import { surrogateReducer } from '@app/modules/components/surrogate/store/surrogate.store';
import { toastReducer } from '@app/modules/components/toast/store/toast.store';
import { dashboardReducer } from '@app/modules/dashboard/dashboard.store';
import { orderInputReducer } from '@app/modules/order-input/store/order-input.store';
import { orderPriorizationReducer } from '@app/modules/order-priorization/store/order-priorization.store';
import { orderUpdateReducer } from '@app/modules/order-update/store/order-update.store';
import { orderTabHarborReducer } from '@app/modules/order/order-tabs/ME/maritime/tab-harbor/order-tab-maritime-harbor.store';
import { orderTabOpenMaritimeReducer } from '@app/modules/order/order-tabs/ME/maritime/tab-open/order-tab-maritime-open.store';
import { orderTabShippedMaritimeReducer } from '@app/modules/order/order-tabs/ME/maritime/tab-shipped/order-tab-maritime-shipped.store';
import { orderTabFrontierReducer } from '@app/modules/order/order-tabs/ME/road/tab-frontier/order-tab-frontier.store';
import { orderTabOpenInternationalReducer } from '@app/modules/order/order-tabs/ME/road/tab-open-international/order-tab-open-international.store';
import { orderTabShippedRoadReducer } from '@app/modules/order/order-tabs/ME/road/tab-shipped/order-tab-shipped.store';
import { orderTabBilledReducer } from '@app/modules/order/order-tabs/MI/tab-billed/store/order-tab-billed.store';
import { lackOfChargeReducer } from '@app/modules/order/order-tabs/MI/tab-open/partials/lack-of-charge-modal/store/lack-of-charge-modal.store';
import { schedulingCompleteReducer } from '@app/modules/order/order-tabs/MI/tab-open/partials/scheduling-complete/store/scheduling-complete-modal.store';
import { orderTabOpenReducer } from '@app/modules/order/order-tabs/MI/tab-open/store/order-tab-open.store';
import { orderTabStockReducer } from '@app/modules/order/order-tabs/MI/tab-stock/store/order-tab-stock.store';
import { orderReducer } from '@app/modules/order/order.store';
import { shippingRequestReducer } from '@app/modules/shipping-request/store/shipping-request.store';
import { truckTrackingReducer } from '@app/modules/truck-tracking/store/truck-tracking.store';
import { csatReducer } from '@app/providers/csat/csat.store';
import { navigationPersistReducer } from '@app/providers/navigation/navigation.store';
import { userPersistReducer } from '@app/providers/user-redux.store';
import { gridLayoutReducer } from './grid-layout/grid-layout.store';

const store = configureStore({
  reducer: {
    auth: authPersistReducer,
    nps: npsReducer,
    csat: csatReducer,
    schedule: scheduleReducer,
    gridLayout: gridLayoutReducer,
    user: userPersistReducer,
    customerPersist: customerPersistReducer,
    customerSearch: customerSearchReducer,
    dashboard: dashboardReducer,
    order: orderReducer,
    orderTabOpen: orderTabOpenReducer,
    orderTabStock: orderTabStockReducer,
    orderTabBilled: orderTabBilledReducer,
    orderTabOpenInternational: orderTabOpenInternationalReducer,
    orderTabFrontier: orderTabFrontierReducer,
    orderTabShippedRoad: orderTabShippedRoadReducer,
    orderTabOpenMaritime: orderTabOpenMaritimeReducer,
    orderTabHarbor: orderTabHarborReducer,
    orderTabShippedMaritime: orderTabShippedMaritimeReducer,
    orderUpdate: orderUpdateReducer,
    orderPriorization: orderPriorizationReducer,
    shippingRequest: shippingRequestReducer,
    truckTracking: truckTrackingReducer,
    navigation: navigationPersistReducer,
    admin: adminReducer,
    toast: toastReducer,
    preventiveStopPersist: preventiveStopPersistReducer,
    announcement: announcementReducer,
    advancedFilters: advancedFiltersReducer,
    orderInput: orderInputReducer,
    surrogate: surrogateReducer,
    emailSendingFailure: emailSendingFailureReducer,
    lackOfCharge: lackOfChargeReducer,
    schedulingComplete: schedulingCompleteReducer,
    notification: notificationReducer,
    regularAnnouncement: regularAnnouncementReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }).concat(thunkMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);

export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;

export default store;
