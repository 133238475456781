import React from 'react';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Body } from '@atomic/atm.typography';
import { FormContext } from '../form.context';
import { Step } from './form-flow-fields.container';
import { calcMandatory } from './formSummary';
import { IconStep, StepHeader, StepsHeaderWrapper } from './result/form-result.component.style';

export const StepsHeader = () => {
  const { formResult, scrollTo } = React.useContext(FormContext);

  const flow = formResult;

  return (
    <StepsHeaderWrapper>
      {formResult &&
        Object.entries(flow).map((step: [string, { key: string; questions: Step }]) => (
          <StepHeader
            key={step[1].questions.key}
            percent={calcMandatory(step[1].questions?.fields && Object.values(step[1].questions.fields))}
            onClick={() => scrollTo(step[1].questions.key, null, false)}
          >
            <IconStep>
              {calcMandatory(step[1].questions?.fields && Object.values(step[1].questions.fields)) === 100 ? (
                <FaIcon.Check />
              ) : (
                <FaIcon.Pen />
              )}
            </IconStep>
            <Body>
              {step[1].questions.label}{' '}
              {step[1].questions.key !== 'ADITIONAL' &&
                `${calcMandatory(step[1].questions?.fields && Object.values(step[1].questions.fields))}%`}
            </Body>
          </StepHeader>
        ))}
      {formResult && Object.keys(formResult).length === 1 && (
        <StepHeader key='NONE' percent={-1}>
          <Body>Preencha os campos necessários na etapa 1 para continuar</Body>
        </StepHeader>
      )}
    </StepsHeaderWrapper>
  );
};
