import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { GetAnalysisParams } from '@app/data/http/quote-params.dto';
import { FullAnalysisResponse } from '@app/data/http/quote.dto';

export async function getSendToBagg(params?: GetAnalysisParams): Promise<FullAnalysisResponse> {
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: params.language };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.id}${
    endpoint.BAGG_NOTIFICATION
  }`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useSendToBagg(params: GetAnalysisParams, onSuccess?, onError?) {
  return useQuery(['getSendToBagg', params], () => getSendToBagg(params), {
    enabled: false,
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
