import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { AnnouncementModalDatasource } from '@app/data/http/announcement-modal-datasource';
import { AnnouncementModalEvents } from '../announcement-regular-modal.analytics';
import { SLICE_NAME } from '../announcement-regular-modal.constants';

export const getRegularAnnouncement = createAsyncThunk(
  `${SLICE_NAME}/getRegularAnnouncement`,
  async (announcementKey: string, thunkAPI) => {
    return AnnouncementModalDatasource.getAnnouncements({ key: announcementKey })
      .then(resp => resp)
      .catch(err => {
        LogAnalytics.error({
          tipo: AnnouncementModalEvents.GetAnnouncementError,
          message: err.response.status,
        });
        return thunkAPI.rejectWithValue({ status: err.response.status });
      });
  },
);

export const addGetRegularAnnouncementReducers = builder => {
  builder.addCase(getRegularAnnouncement.pending, state => {
    state.loading = true;
  });
  builder.addCase(getRegularAnnouncement.fulfilled, (state, action) => {
    state.announcements = action.payload?.announcements;
    state.loading = false;
  });
  builder.addCase(getRegularAnnouncement.rejected, state => {
    state.loading = false;
  });
};
