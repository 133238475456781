import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { EditPlant } from '@app/data/http/quote-plant-params.dto';
import { FullAnalysisResponse } from '@app/data/http/quote.dto';

export const editPlants = async (params?: EditPlant) => {
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: params.language };

  const data = {
    review: params.review,
    selection: params.selection,
    validation: params.validation,
  };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_PLANT
  }/${params.plantKey}`;

  const { data: dataResponse } = await axios.patch(url, data, { headers: defaultHeaders });

  return dataResponse;
};

export const useEditPlants = (onSuccess?) => {
  const queryClient = useQueryClient();
  return useMutation(editPlants, {
    onSuccess,
    onMutate: async data => {
      await queryClient.cancelQueries(['getTechnicalAnalysis']);
      const previousData = queryClient.getQueriesData(['getTechnicalAnalysis']);
      queryClient.setQueriesData(['getTechnicalAnalysis'], (oldQueryData: FullAnalysisResponse) => {
        const selectedPlant = oldQueryData.plants.filter(plant => plant.key === data.plantKey);
        if (data?.selection) {
          const { selection } = selectedPlant[0];
          selection.isSelected = data.selection.isSelected;
        } else if (data?.review) {
          const { review } = selectedPlant[0];
          if (data.review.isRequestable) {
            review.isRequested = data.review.isRequestable;
          } else if (data.review.isReviewed) {
            review.isReviewed = data.review.isReviewed;
          }
        } else if (data?.validation) {
          const { validation } = selectedPlant[0];
          validation.isValidated = data.validation.isValidated;
        }

        return {
          ...oldQueryData,
        };
      });
      return {
        previousData,
      };
    },
    onError: (_error, _data, context) => {
      queryClient.setQueriesData(['getTechnicalAnalysis'], context.previousData);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['getTechnicalAnalysis']);
    },
  });
};
