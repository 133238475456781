import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { GetLoginResponse, RefreshTokenResponse } from '@app/models';
import { AuthStorageService } from '../storage/auth.storage';
import { AuthHttpRequestBuilder } from './auth-http-request.builder';
import { LoginDataParam, LoginProfileResponse, RefreshTokenParam } from './auth.params.dto';

const endpoint = {
  LOGIN: '/login',
  LOGOUT: '/revoke-session',
  PROFILE: '/profile',
  FORGOT_PASSWORD: '/forgot-password',
  VALIDATE: '/validate',
  REFRESH_TOKEN: '/refresh-token',
  CHANGE_PASSWORD: '/change-password',
};

export interface LogoutResponse {
  eGerdauResponse: {
    invalidated: boolean;
  };
}

export interface ForgotPasswordResponse {
  userName: string;
  factorType: string;
}

export interface ForgotPasswordParameters {
  username: string;
  factorType: string;
  authorizationToken: string;
}

export interface ChangePasswordResponse {
  eGerdauResponse: {
    passwordChanged: boolean;
  };
}

export interface ChangePasswordParameters {
  oldPassword: string;
  newPassword: string;
  uid: string;
  authorizationToken: string;
  ssoAccess: boolean;
}

export const LoginDataSource = {
  login: (params: LoginDataParam): Promise<GetLoginResponse> => {
    const azureToken = window.localStorage.getItem('azureToken');
    return Container.get(AuthHttpRequestBuilder)
      .post(endpoint.LOGIN)
      .data(params)
      .headers({
        'Access-Token': params.access_token,
        Authorization: `Bearer ${azureToken}`,
        'Sso-Access': params.access_token ? true : false,
        redirect_uri: params.redirectUrl,
      })
      .execute();
  },

  profile: (ssoAcces: boolean): Promise<LoginProfileResponse> => {
    return Container.get(HttpRequestBuilder)
      .post(endpoint.PROFILE)
      .data('')
      .headers({
        Accept: '*/*',
        'Content-Type': 'application/json',
        'Sso-Access': ssoAcces,
      })
      .execute();
  },

  logout: (oktaUid: string, authorizationToken: string): Promise<LogoutResponse> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(AuthHttpRequestBuilder)
      .delete(`${endpoint.LOGOUT}/${oktaUid}`)
      .headers({
        Authorization: 'Bearer ' + authorizationToken,
        'Sso-Access': user.ssoAccess,
      })
      .data('')
      .execute();
  },

  forgotPassword: (params: ForgotPasswordParameters): Promise<ForgotPasswordResponse> => {
    return Container.get(AuthHttpRequestBuilder)
      .post(endpoint.FORGOT_PASSWORD)
      .headers({
        Authorization: 'Bearer ' + params.authorizationToken,
        'Content-Type': 'application/json',
      })
      .data(params)
      .execute();
  },

  changePassword: (params: ChangePasswordParameters): Promise<ForgotPasswordResponse> => {
    return Container.get(AuthHttpRequestBuilder)
      .post(endpoint.CHANGE_PASSWORD)
      .headers({
        Authorization: 'Bearer ' + params.authorizationToken,
        'Sso-Access': params.ssoAccess,
      })
      .data({
        oldPassword: params.oldPassword,
        newPassword: params.newPassword,
        uid: params.uid,
      })
      .execute();
  },

  validateToken: (ssoAccess: boolean, authorizationToken: string): Promise<ForgotPasswordResponse> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(AuthHttpRequestBuilder)
      .post(endpoint.VALIDATE)
      .headers({
        'Access-Token': user.accessToken,
        Authorization: 'Bearer ' + authorizationToken,
        'Content-Type': 'application/json',
        'Sso-Access': ssoAccess,
      })
      .data({ token: user.accessToken })
      .execute();
  },

  refreshToken: (refreshTokenParam: RefreshTokenParam): Promise<RefreshTokenResponse> => {
    return Container.get(AuthHttpRequestBuilder)
      .post(endpoint.REFRESH_TOKEN)
      .headers({
        Authorization: 'Bearer ' + refreshTokenParam.token,
        'Content-Type': 'application/json',
        'Sso-Access': true,
      })
      .data({ refreshToken: refreshTokenParam.refreshToken })
      .execute();
  },
};
