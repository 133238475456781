// eslint-disable-next-line prettier/prettier
import type { RootState } from '@app/core/redux/store';
import { InStockOrderItem } from '@app/models';
import { PlantNames } from '../shipping-request.constants';
import { IShippingRequestRow } from '../shipping-request.interfaces';
import { getOldestSuggestions, getStockQuantityTotal, getSuggestionValue, getValidSuggestions } from '../shipping-request.utils';

export const selectedRowsByPlantSelector = (state, plantCode: string): IShippingRequestRow[] => {
  return state.shippingRequest?.tableRows?.filter(row => row.plantCode === plantCode);
};

export const totalStockQuantitySelector = (state, plantCode: string): number => {
  const filteredRows = state.shippingRequest?.tableRows?.filter(row => row.plantCode === plantCode);
  const total = getStockQuantityTotal(filteredRows);
  return total;
};

export const totalRequestedShippingQuantitySelector = (state: RootState, plantCode: string): number => {
  const filteredRows = state.shippingRequest?.tableRows?.filter(row => row.plantCode === plantCode);
  const total = filteredRows.reduce((sum, row) => {

    if (row.requestedShippingQuantity) {
      return sum + parseFloat(row.requestedShippingQuantity.toString());
    }
    return sum + parseFloat(row.stockQuantity.toString());
  }, 0);


  return total;
};

export const plantsDistinctSelector = (state): string[] => {
  const plantCodesSelected: string[] = state.shippingRequest?.tableRows?.map(row => row.plantCode);
  return [...new Set(plantCodesSelected)];
};

export const stockQunatityTotalRowsSelector = (state): number => {
  const total = state.shippingRequest?.tableRows?.reduce(
    (accumulator: number, row: IShippingRequestRow) => accumulator + row.stockQuantity,
    0,
  );
  return total;
};

export const selectStockQuantitySelector = (state, heat, salesOrder): number => {
  if (state.shippingRequest?.tableRows) {
    const index = state.shippingRequest?.tableRows?.findIndex(row => row.salesOrder === salesOrder && row.heat === heat);
    return state.shippingRequest?.tableRows[index]?.stockQuantity;
  }
  return null;
};

export const selectQtyWishedSelector = (state, heat, salesOrder): number => {
  if (state.shippingRequest?.tableRows) {
    const index = state.shippingRequest?.tableRows?.findIndex(row => row.salesOrder === salesOrder && row.heat === heat);
    return state.shippingRequest?.tableRows[index]?.requestedShippingQuantity;
  }
  return null;
};

export const suggestionByPlantAndMaterialSelector = (state, plantCode: string, material: string): IShippingRequestRow[] => {
  let suggestions = [];
  let ovs: InStockOrderItem[] = [];
  let ovsAux: InStockOrderItem[] = [];

  switch (plantCode) {
    case PlantNames.MOGI:
      if (state.shippingRequest?.mog && state.shippingRequest?.mog?.orderedGroup && state.shippingRequest?.mog?.orderedGroup.length > 0) {
        ovsAux = state.shippingRequest?.mog?.orderedGroup?.find(orderedGroup => orderedGroup?.material === material)?.ovs;
      }
      break;
    case PlantNames.PINDA:
      if (state.shippingRequest?.pin && state.shippingRequest?.pin?.orderedGroup && state.shippingRequest?.pin?.orderedGroup.length > 0) {
        ovsAux = state.shippingRequest?.pin?.orderedGroup?.find(orderedGroup => orderedGroup?.material === material)?.ovs;
      }
      break;
    case PlantNames.CHARQ:
      if (state.shippingRequest?.cha && state.shippingRequest?.cha?.orderedGroup && state.shippingRequest?.cha?.orderedGroup.length > 0) {
        ovsAux = state.shippingRequest?.cha?.orderedGroup?.find(orderedGroup => orderedGroup?.material === material)?.ovs;
      }
      break;
    default:
      break;
  }

  ovs = getValidSuggestions(ovsAux);
  
  if (ovs) {
    suggestions = getOldestSuggestions(ovs);  
  }
  
  return suggestions;
};

export const getRequestedTotalValue = (state, plant: string, material: string): number => {
  let total = 0;
  let selectedRows: IShippingRequestRow[] = [];

  switch (plant) {
    case PlantNames.MOGI:
      if (state.shippingRequest?.mog && state.shippingRequest?.mog?.orderedUserGroup && state.shippingRequest?.mog?.orderedUserGroup.length > 0) {
        selectedRows = state.shippingRequest?.mog?.orderedUserGroup?.find(orderedUserGroup => orderedUserGroup.material === material)?.ovs;
      }
      break;
    case PlantNames.PINDA:
      if (state.shippingRequest?.pin && state.shippingRequest?.pin?.orderedUserGroup && state.shippingRequest?.pin?.orderedUserGroup.length > 0) {
        selectedRows = state.shippingRequest?.pin?.orderedUserGroup?.find(orderedUserGroup => orderedUserGroup.material === material)?.ovs;
      }
      break;
    case PlantNames.CHARQ:
      if (state.shippingRequest?.cha && state.shippingRequest?.cha?.orderedUserGroup && state.shippingRequest?.cha?.orderedUserGroup.length > 0) {
        selectedRows = state.shippingRequest?.cha?.orderedUserGroup?.find(orderedUserGroup => orderedUserGroup.material === material)?.ovs;
      }
      break;
    default:
      break;
  }

  selectedRows?.forEach(row => {
      total += parseFloat(row.stockQuantity.toString());
  });

  return total;
};

export const getSuggestiosTotalValue = (state): number => {
  let total = 0;

  state.shippingRequest?.suggestionOptions?.forEach(option => {
    if (option.selected) {
      const suggestedValue = getSuggestionValue(option.item.stockQuantity, option.item.shipmentQuantity);
      total += parseFloat(suggestedValue.toString());
    }
  });

  return total;
};

export const isDisabledByOldSalesOrder = (state, isGkn): boolean => {
  let isDisabled = false;
  let hasMogAlert = false;
  let hasPinAlert = false;
  let hasChaAlert = false;

  if (state.shippingRequest.mog) {
    state.shippingRequest.mog.orderedUserGroup.forEach(group => {
      if (group.hasAlert) {
        hasMogAlert = true;
      }
    });
  }

  if (state.shippingRequest.pin) {
    state.shippingRequest.pin.orderedUserGroup.forEach(group => {
      if (group.hasAlert) {
        hasPinAlert = true;
      }
    });
  }
  
  if (state.shippingRequest.cha) {
    state.shippingRequest.cha.orderedUserGroup.forEach(group => {
      if (group.hasAlert) {
        hasChaAlert = true;
      }
    });
  }
  
  if (!isGkn && hasMogAlert || !isGkn && hasPinAlert || !isGkn && hasChaAlert) {
    isDisabled = true;
  }

  return isDisabled;
};
