import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { RootState } from '@app/core/redux/store';
import { setOpenMaintenanceModal } from '@app/providers/navigation/navigation.store';
import { Button } from '@atomic/atm.button';
import { Image } from '@atomic/atm.image';
import { FlexRow } from '@atomic/obj.box';
import { Modal } from '@atomic/obj.modal';
import { Strings, IAppStrings } from '../../app/app.string';
import {
  MaintenanceModalBody,
  MaintenanceModalCloseWrapper,
  MaintenanceModalFooter,
  MaintenanceModalHeader,
  MaintenanceModalImage,
  MaintenanceModalTitleWrapper,
  MaintenanceModalWrapper,
} from './maintenance-modal.style';

export const MaintenanceModal: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings: IAppStrings = Strings[userInfo?.language];
  const { openMaintenanceModal } = useSelector((state: RootState) => state.navigation);
  const dispatch = useDispatch();

  const handleImage = () => {
    let imageName = '';

    if (userInfo.language === 'in') {
      imageName = 'manutencao-eng.png';
    } else if (userInfo.language === 'es') {
      imageName = 'manutencao-spa.png';
    } else {
      imageName = 'manutencao.png';
    }

    return (
      <MaintenanceModalImage>
        <img width='600px' src={`assets/img/maintenance-modal/${imageName}`} />
      </MaintenanceModalImage>
    );
  };

  useEffect(() => {
    const startDate = new Date('2024-09-09T11:45:00').getTime();
    const endDate = new Date('2024-09-09T12:00:00').getTime();
    const currentDate = Date.now();

    if (startDate <= currentDate && endDate >= currentDate) {
      dispatch(setOpenMaintenanceModal(true));
    }
  }, []);

  return (
    <Modal small opened={openMaintenanceModal}>
      <MaintenanceModalHeader>
        <MaintenanceModalTitleWrapper>{strings?.maintenanceModal.title}</MaintenanceModalTitleWrapper>
        <MaintenanceModalCloseWrapper>
          <FlexRow onClick={() => dispatch(setOpenMaintenanceModal(false))} style={{ cursor: 'pointer' }}>
            <Image.NewsModalHeaderCloseButton />
          </FlexRow>
        </MaintenanceModalCloseWrapper>
      </MaintenanceModalHeader>

      <MaintenanceModalWrapper>
        <MaintenanceModalBody>
          <MaintenanceModalImage>{handleImage()}</MaintenanceModalImage>
          <MaintenanceModalFooter>
            <FlexRow hAlign='center'>
              <Button
                testid={`${TEST_ID_CLOSE_MODAL}-maintenance`}
                onClick={() => dispatch(setOpenMaintenanceModal(false))}
              >
                {strings?.newsModal.close}
              </Button>
            </FlexRow>
          </MaintenanceModalFooter>
        </MaintenanceModalBody>
      </MaintenanceModalWrapper>
    </Modal>
  );
};
