import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { GetAssetsParams } from '@app/data/http/quote-assets-params.dto';
import { mapAvailableStatus } from '@app/data/http/quote-status.dto';
import { AvailableStatus } from '@app/models/quote.model';
import { handleUnauthorized } from '@app/utils/http-utils';

export async function getStatus(params?: GetAssetsParams): Promise<AvailableStatus> {
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: params.language };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_STATUS
  }`;

  try {
    const { data, status } = await axios.get(url, { headers: defaultHeaders });

    if (status === 200) {
      return mapAvailableStatus(data);
    } else {
      return null;
    }
  } catch (error) {
    handleUnauthorized(error);
    return null;
  }
}

export const useGetStatus = (onSuccess?, onError?) => {
  return useMutation(getStatus, {
    onSuccess,
    onError,
    retry: false,
  });
};
