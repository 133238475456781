import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export const postDocument = async (params?: any) => {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: user.language };

  const form = new FormData();

  form.append('title', params.title);
  form.append('documentType', params.documentType);
  if (params.expirationDate) {
    form.append('expirationDate', params.expirationDate);
  }
  form.append('file', params.file);

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.DOCUMENTS}`;

  const { data: dataResponse } = await axios.post(url, form, { headers: defaultHeaders });

  return dataResponse;
};

export const usePostDocument = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(postDocument, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(['getDocumentList']);
    },
  });
};
