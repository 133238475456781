import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { IAdminBPList, IAdminTab, IAdminUserList, IRole } from '@app/models/admin.model';
import { IUser } from '@app/modules/admin/admin.interfaces';
import { AuthStorageService } from '../storage/auth.storage';
import { mapBPs, mapRole, mapTab, mapUser, mapUsers } from './admin.dto';
import {
  CreateNewUserParams,
  DeleteUserParams,
  EditRoleParam,
  GetAdminBPsResponse,
  GetAdminTabsResponse,
  GetAdminUsersResponse,
  GetBPsParams,
  GetResetPasswordResponse,
  GetRolesResponse,
  GetUserResponse,
  GetUsersParams,
  UpdateUserParams,
} from './admin.params.dto';

const endpoint = {
  ADMIN: '/admin/',
  ADMIN_GROUP: '/group',
  ADMIN_DETAIL: '/details',
  ADMIN_LIST: '/list',
  ADMIN_USERS: '/users',
  ADMIN_LAST_LOGIN: '/lastlogin',
  ADMIN_USER: '/admin/users',
  ADMIN_RESET_PASSWORD: '/admin/users/reactive',
  ADMIN_BPS: '/admin/clients',
  ADMIN_ROLES: '/admin/roles',
  ADMIN_TABS: '/admin/tabs',
};
export interface AdminUserResponse {
  success: boolean;
  message: string;
  status?: number;
}

export const AdminDataSource = {
  getUser: (email: string): Promise<GetUserResponse> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.ADMIN_USER}/${email}`)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .interceptors({
        afterSuccess: async (res: GetUserResponse): Promise<IUser> => mapUser(res),
      })
      .execute();
  },

  getResetPassword: (oktaUid: string, token: string): Promise<GetResetPasswordResponse> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.ADMIN_RESET_PASSWORD}/${oktaUid}`)
      .headers({
        access_token: token,
        'Sso-Access': user.ssoAccess,
      })
      .execute();
  },

  getUsers: (params: GetUsersParams): Promise<GetAdminUsersResponse> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.ADMIN_USER}`)
      .headers({
        skip: params.skip,
        limit: params.limit,
        filterType: params.filterType,
        filterValue: params.filterValue,
        'Sso-Access': user.ssoAccess,
      })
      .interceptors({
        afterSuccess: async (res: GetAdminUsersResponse): Promise<IAdminUserList> => mapUsers(res),
      })
      .execute();
  },

  getBPs: (params: GetBPsParams): Promise<GetAdminBPsResponse> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.ADMIN_BPS}`)
      .headers({
        skip: params.skip,
        limit: params.limit,
        filterType: params.filterType,
        filterValue: params.filterValue,
        'Sso-Access': user.ssoAccess,
      })
      .interceptors({
        afterSuccess: async (res: GetAdminBPsResponse): Promise<IAdminBPList> => mapBPs(res),
      })
      .execute();
  },

  getRoles: (): Promise<GetRolesResponse> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.ADMIN_ROLES}`)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .interceptors({
        afterSuccess: async (res: GetRolesResponse): Promise<IRole[]> => mapRole(res),
      })
      .execute();
  },

  getTabs: (): Promise<any> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.ADMIN_TABS}`)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .interceptors({
        afterSuccess: async (res: GetAdminTabsResponse): Promise<IAdminTab[]> => mapTab(res),
      })
      .execute();
  },

  createUser: (params: CreateNewUserParams): Promise<void> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .post(`${endpoint.ADMIN_USER}`)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .data(params)
      .execute();
  },

  updateUser: (params: UpdateUserParams): Promise<void> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .put(`${endpoint.ADMIN_USER}`)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .data(params)
      .execute();
  },

  editRole: (params: EditRoleParam): Promise<void> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .put(`${endpoint.ADMIN_ROLES}/${params.uid}`)
      .headers({
        'Content-Type': 'application/json',
        'Sso-Access': user.ssoAccess,
      })
      .data({ profile: params.profile, tabs: params.tabs })
      .execute();
  },

  updateRole: (params: EditRoleParam): Promise<void> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .put(`${endpoint.ADMIN_ROLES}/${params.uid}`)
      .headers({
        'Content-Type': 'application/json',
        'Sso-Access': user.ssoAccess,
      })
      .data({ profile: params.profile, tabs: params.tabs })
      .execute();
  },

  deleteUser: (data: DeleteUserParams): Promise<void> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .delete(`${endpoint.ADMIN_USER}/${data.email}`)
      .headers({
        'Content-Type': 'application/json',
        'Sso-Access': user.ssoAccess,
      })
      .data(data)
      .execute();
  },
};
