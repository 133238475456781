import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { LicenseManager } from 'ag-grid-enterprise';
import { initializeApp, FirebaseOptions } from 'firebase/app';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { Container } from 'typedi';
import * as WebFont from 'webfontloader';
import { AxiosResponseInterceptor, UnwrapDataInterceptor } from '@app/core/data';
import { HttpClient, HttpRequestBuilder } from '@app/core/http';
import { HistoryToken } from '@app/core/route';
import { AzureTokenInterceptor } from './core/data/interceptor/azure-token.interceptor';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { BaggHttpRequestBuilder, IsaHttpRequestBuilder, BAGG_KEY_CLIENT_ID, BAGG_KEY_CLIENT_SECRET } from './data/http';
import 'firebase/analytics';
import { AuthHttpRequestBuilder } from './data/http/auth-http-request.builder';
import { AzureHttpRequestBuilder } from './data/http/azure-http-request.builder';
import { AuthStorageService } from './data/storage/auth.storage';

/**
 * App config
 *
 * Remember to update metadata in config/webpack/client to reflect
 * changes when running in webpack-dev-server mode
 */
export interface AppConfig {
  version: string;
  azureUrl: string;
  baseUrl: string;
  authUrl: string;
  isaMuleUrl: string;
  gabUrl: string;
  googleAnalyticsTrackingId: string;
  debug: boolean;
  dev: boolean;
  qa: boolean;
  prod: boolean;
  googleMapsStaticApiKey: string;
  agGridApiKey: string;
  firebaseBucketId: string;
  baggUrl: string;
  crmUrl: string;
  firebaseApiKey: string;
  firebaseAuthDomain: string;
  firebaseDatabaseURL: string;
  firebaseProjectId: string;
  firebaseStorageBucket: string;
  firebaseMessagingSenderId: string;
  firebaseAppId: string;
  firebaseMeasurementId: string;
  datadogApp: string;
  datadogClientToken: string;
  datadogSite: string;
  datadogService: string;
  hjid: string;
  urlGerdauMais: string;
  cxpClientId: string;
  cxpClientSecret: string;
  authClientId: string;
  authClientSecret: string;
  azureClientId: string;
  azureClientSectret: string;
  ssoAuthClientId: string;
  ssoAuthClientSecret: string;
  ssoTenantId: string;
  ssoLogoutUrl: string;
  ssoLogoutAuthPath: string;
  gsbAuthUrl: string;
}

export function configure(config: AppConfig, ssr = false) {
  configureVersion(config);
  configHttpBuilder(config);
  configureRoutesService(ssr);
  configureFontAwesome();
  configureGoogleFonts();
  configureFirebase(config);
  configureDocuments(config);
  configureGoogleMaps(config);
  configureAgGrid(config);
}

function configureVersion(env: AppConfig) {
  Container.set('Version', env.version);
}

function configureFirebase(env: AppConfig) {
  const firebaseConfig: FirebaseOptions = {
    apiKey: env.firebaseApiKey,
    authDomain: env.firebaseAuthDomain,
    databaseURL: env.firebaseDatabaseURL,
    projectId: env.firebaseProjectId,
    storageBucket: env.firebaseStorageBucket,
    messagingSenderId: env.firebaseMessagingSenderId,
    appId: env.firebaseAppId,
    measurementId: env.firebaseMeasurementId,
  };

  initializeApp(firebaseConfig);
}

function configHttpBuilder(env: AppConfig) {
  const client = new HttpClient();
  const user = Container.get(AuthStorageService).getUser();

  const interceptors = [new AxiosResponseInterceptor(), new UnwrapDataInterceptor()];

  const authorizedInterceptors = [
    new AxiosResponseInterceptor(),
    new UnwrapDataInterceptor(),
    new AzureTokenInterceptor(),
  ];

  const builder = new HttpRequestBuilder().configure({
    baseUrl: env.baseUrl,
    client,
    interceptors: authorizedInterceptors,
  });
  Container.set(HttpRequestBuilder, builder);

  const authBuilder = new AuthHttpRequestBuilder().configure({
    baseUrl: env.authUrl,
    client,
    interceptors: interceptors,
  });
  Container.set(AuthHttpRequestBuilder, authBuilder);

  const azureBuilder = new AzureHttpRequestBuilder().configure({
    baseUrl: env.azureUrl,
    client,
    headers: {
      client_id: env.azureClientId,
      client_secret: env.azureClientSectret,
    },
  });
  Container.set(AzureHttpRequestBuilder, azureBuilder);

  const baggBuilder = new BaggHttpRequestBuilder().configure({
    baseUrl: env.baggUrl,
    client,
    headers: {
      client_id: BAGG_KEY_CLIENT_ID,
      client_secret: BAGG_KEY_CLIENT_SECRET,
      'Access-Token': user?.accessToken,
      Authorization: 'Bearer ' + window.localStorage.getItem('azureToken'),
      'Content-Type': 'application/json',
      Accept: '*/*',
      'Sso-Access': `${user?.ssoAccess}`,
    },
  });
  Container.set(BaggHttpRequestBuilder, baggBuilder);

  const isaHeadersBuilder = new IsaHttpRequestBuilder().configure({
    baseUrl: env.baseUrl,
    headers: {
      'Access-Token': user?.accessToken,
      Authorization: 'Bearer ' + window.localStorage.getItem('azureToken'),
      'Content-Type': 'application/json',
      Accept: '*/*',
      'Sso-Access': `${user?.ssoAccess}`,
    },
  });
  Container.set(IsaHttpRequestBuilder, isaHeadersBuilder);

  Container.set('GabUrl', env.gabUrl);
  Container.set('CrmUrl', env.crmUrl);
}

function configureRoutesService(ssr: boolean) {
  const createHistory: any = ssr ? createMemoryHistory : createBrowserHistory;
  Container.set(HistoryToken, createHistory());
}

function configureFontAwesome() {
  library.add(fab, fas, far);
}

function configureGoogleFonts() {
  WebFont.load({
    google: {
      families: ['Open Sans:400,400i,600,700'],
    },
  });
}

function configureDocuments(env: AppConfig) {
  Container.set('DocumentsUrl', env.baseUrl);
}

function configureGoogleMaps(env: AppConfig) {
  Container.set('GoogleMapsStaticApiKey', env.googleMapsStaticApiKey);
}

function configureAgGrid(env: AppConfig) {
  LicenseManager.setLicenseKey(env.agGridApiKey);
}
