import * as React from 'react';
import { useSelector } from 'react-redux';
import { scroller } from 'react-scroll';
import { LogAnalytics } from '@app/core/analytics';
import { EnhancedError } from '@app/core/hook/request.model';
import { RootState } from '@app/core/redux/store';
import { UserInfo } from '@app/models/login.model';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName } from '@app/providers';
import { FlashMessageType } from '@atomic/mol.flash-message';
import { useGetClientsList } from '../hooks/useGetClientsList';
import { FormStrings } from './form.string';
import { useCallBackAttribute, useFormFlow, useFormFlowInitial } from './hooks/formFlow.datasource';
import { useChatCreate } from './hooks/useChatCreate';
import { useCreateAnalysis } from './hooks/useCreateAnalysis';
import { usePostFile } from './hooks/usePostFile';

interface FormContextState {
  formResult: any;
  enableClientSearch: boolean;
  showList: boolean;
  fieldValue: string;
  disableSubmit: boolean;
  conversationId: string;
  cfId: string;
  userInfo: UserInfo;
  strings: any;
  showModalConfirmation: boolean;
  chatCreateV2: boolean;
  showModalErroNlu: boolean;
  initialized: boolean;
  loadingCreateAnalysis: boolean;
  dataClientsList: ClientList[];
  loadingClients: boolean;
  loadingPostFile: boolean;
  dataFlowClient: any;
  dataFlowSteel: any;
  dataFlowProduct: any;
  dataFlowGoal: any;
  dataFlowAdditional: any;
  loadingFlowClient: boolean;
  loadingFlowSteel: boolean;
  loadingFlowProduct: boolean;
  loadingFlowGoal: boolean;
  loadingFormFlowAdditional: boolean;
  modalToOpen: any;
  responseCallback: any;
  loadingCallback: boolean;
  setDisableSubmit: (value: boolean) => void;
  setFormResult: (value: any) => void;
  handleValueAutocompleteChange: (value: string) => void;
  clearNluAttributes: () => void;
  setEnableClientSearch: (value: boolean) => void;
  setShowList: (value: boolean) => void;
  setFieldValue: (value: string) => void;
  setConversationId: (value: string) => void;
  setCfId: (value: string) => void;
  setShowModalConfirmation: (value: boolean) => void;
  setChatCreateV2: (value: boolean) => void;
  setShowModalErroNlu: (value: boolean) => void;
  setInitialized: (value: boolean) => void;
  show: (type: FlashMessageType, content: string, alignRight?: boolean, small?: boolean) => void;
  createAnalysis: () => void;
  postFile: (any) => void;
  filterClientList: (list: ClientList[]) => ClientList[];
  handleSelectClient: (value: ClientList) => void;
  handleFocusClient: (value: string) => void;
  deleteClient: () => void;
  scrollTo: (value?: string, session?: string, openClose?: boolean) => void;
  openModals: (key: string) => void;
  setModalToOpen: (value: string) => void;
  submitModals: (stepKey: string, key: string, values: any) => void;
  callBackAttribute: (any) => void;
  callFlows: (flow: string) => void;
}

export interface ClientList {
  bp: string;
  corporateGroup: string;
  formatList: string;
  name: string;
  type: string;
}

export const FormContext = React.createContext<FormContextState>(null);

export const FormProvider: React.FC = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [formResult, setFormResult] = React.useState(null);
  const [enableClientSearch, setEnableClientSearch] = React.useState<boolean>(false);
  const [showList, setShowList] = React.useState<boolean>(false);
  const [fieldValue, setFieldValue] = React.useState('');
  const [disableSubmit, setDisableSubmit] = React.useState<boolean>(false);
  const [conversationId, setConversationId] = React.useState(null);
  const [cfId, setCfId] = React.useState(null);
  const [showModalConfirmation, setShowModalConfirmation] = React.useState<boolean>(false);
  const [chatCreateV2, setChatCreateV2] = React.useState<boolean>(false);
  const [showModalErroNlu, setShowModalErroNlu] = React.useState<boolean>(false);
  const [initialized, setInitialized] = React.useState<boolean>(formResult !== null);
  const [responseCallback, setResponseCallback] = React.useState(null);
  const [show] = useFlashMessage(FlashMessageTargetName.APP);

  const { isFetching: loadingCreateAnalysis, refetch: createAnalysis } = useCreateAnalysis(
    { data: formResult, conversationId, chatCreateV2 },
    handleAnalysisCreationSuccess,
    handleAnalysisCreationFailure,
  );

  function handleAnalysisCreationSuccess(data) {
    LogAnalytics.submit({
      tipo: 'CriarCotacaoForm',
    });
    setCfId(data.data.cfId);
    setShowModalConfirmation(true);
    show('success', strings.newForm.messages.analysisCreatedSuccess);
  }

  function handleAnalysisCreationFailure(err: EnhancedError) {
    LogAnalytics.error({
      tipo: 'ErroCriarCotacaoForm',
      message: err.message,
    });
    if (conversationId) {
      setShowModalErroNlu(true);
    } else {
      show('alert', strings.alertTry);
    }

    setDisableSubmit(true);

    const conditionalField = formResult.flow[0].fields.find(field => field.key === 'STEEL_NAME');
    conditionalField.display = false;
    setFormResult({
      CLIENT: formResult.CLIENT,
      CLIENT_CODE: formResult.CLIENT_CODE,
      CORPORATE_CODE: formResult.CORPORATE_CODE,
      STANDARD: formResult.STANDARD,
      WHEN_CLIENT_DATA: formResult.WHEN_CLIENT_DATA,
    });
  }

  useChatCreate(handleChatCreatedSucces);

  function handleChatCreatedSucces(response) {
    setChatCreateV2(response.chatCreateV2);
  }

  const { data: dataFlowClient, isFetching: loadingFlowClient } = useFormFlowInitial(
    {
      flow: 'CLIENT',
      language: userInfo.language,
    },
    handleSuccessFlowInitial,
  );

  function handleSuccessFlowInitial(response) {
    console.log('responseInit', response);
    setFormResult(response);
  }

  const { mutate: callFormFlowSteel, data: dataFlowSteel, isLoading: loadingFlowSteel } = useFormFlow(
    handleSuccessFlowSteel,
  );

  const { mutate: callFormFlowProduct, data: dataFlowProduct, isLoading: loadingFlowProduct } = useFormFlow(
    handleSuccessFlowProduct,
  );

  const { mutate: callFormFlowGoal, data: dataFlowGoal, isLoading: loadingFlowGoal } = useFormFlow(
    handleSuccessFlowGoal,
  );

  const {
    mutate: callFormFlowAdditional,
    data: dataFlowAdditional,
    isLoading: loadingFormFlowAdditional,
  } = useFormFlow(handleSuccessFlowAdditional);

  function handleSuccessFlowSteel(response) {
    setFormResult(prevValue => ({
      ...prevValue,
      ...response,
    }));
    callFormFlowProduct({ flow: 'PRODUCT', language: userInfo.language });
  }
  function handleSuccessFlowProduct(response) {
    setFormResult(prevValue => ({
      ...prevValue,
      ...response,
    }));
    callFormFlowGoal({ flow: 'GOAL', language: userInfo.language });
  }
  function handleSuccessFlowGoal(response) {
    scrollTo('STEEL', null);
    setFormResult(prevValue => ({
      ...prevValue,
      ...response,
    }));
    callFormFlowAdditional({ flow: 'ADDITIONAL', language: userInfo.language });
  }
  function handleSuccessFlowAdditional(response) {
    setFormResult(prevValue => ({
      ...prevValue,
      ...response,
    }));
  }

  function callFlows(flow: string) {
    if (flow === 'NEW_PRODUCT') {
      callFormFlowSteel({ flow: 'STEEL', language: userInfo.language });
    }
  }

  console.log('formResult', formResult);

  const { data: dataClientsList, isFetching: loadingClients } = useGetClientsList(
    {
      enabled: enableClientSearch,
      client: fieldValue,
      language: userInfo.language,
    },
    handleSuccessClientList,
  );

  function handleSuccessClientList() {
    setShowList(true);
    setEnableClientSearch(false);
  }

  const handleValueAutocompleteChange = (value: string) => {
    setFieldValue(value);

    if (value?.length === 3) {
      setEnableClientSearch(true);
    } else if (value?.length < 3) {
      setShowList(false);
      setEnableClientSearch(false);
    }
  };

  const { mutate: postFile, isLoading: loadingPostFile } = usePostFile(handleSendFileSuccess, handleSendFileError);

  function handleSendFileSuccess(response) {
    LogAnalytics.submit({
      tipo: 'SucessoLeituraNormaForm',
    });
    setDisableSubmit(false);

    response.data.nlu.map(item => {
      if (item) {
        setFormResult(prevValue => ({
          ...prevValue,
          ...item,
        }));
      }
    });
    setConversationId(response.data.conversationId);
    show('success', strings.newForm.messages.readNormSuccess);
    // const conditionalFields = formResult.flow[0].fields.filter(field => field?.conditional?.key === 'CHECK_FILE');

    // conditionalFields.forEach(field => {
    //   field.display = true;
    // });
  }

  function handleSendFileError() {
    LogAnalytics.submit({
      tipo: 'ErroLeituraNormaForm',
    });
    show('warning', strings.newForm.message.readNormError);

    const conditionalFields = formResult.flow[0].fields.filter(field => field?.conditional?.key === 'CHECK_FILE');

    conditionalFields.forEach(field => {
      field.display = true;
    });
  }

  const { mutate: callBackAttribute, isLoading: loadingCallback } = useCallBackAttribute(
    handleSuccessCallBack,
    handleErrorCallback,
  );

  function handleSuccessCallBack(response) {
    setResponseCallback(response);
  }

  function handleErrorCallback() {
    show('warning', strings.newForm.message.readNormError);
  }

  const handleFocusClient = value => {
    const hasCode = value.split(' - ');
    if (typeof hasCode[0] !== 'number') {
      setFieldValue('');
    }
  };

  const deleteClient = () => {
    setFieldValue('');

    setFormResult(prevValue => ({
      ...prevValue,
      ['CLIENTS']: {
        ...prevValue.CLIENTS,
        fields: {
          CLIENT: { label: null, value: null, permissions: { visible: false } },
          CLIENT_CODE: { label: null, value: null, permissions: { visible: false } },
          CORPORATE_CODE: { label: null, value: null, permissions: { visible: false } },
        },
      },
    }));

    setShowList(false);
  };

  const clearNluAttributes = () => {
    setFormResult(prevValue => ({
      ...prevValue,
      ['CLIENTS']: {
        ...prevValue.CLIENTS,
        fields: {
          CLIENT: { ...prevValue.CLIENTS.fields.CLIENT },
          CLIENT_CODE: { ...prevValue.CLIENTS.fields.CLIENT_CODE },
          CORPORATE_CODE: { ...prevValue.CLIENTS.fields.CORPORATE_CODE },
          WHEN_CLIENT_DATA: { ...prevValue.CLIENTS.fields.WHEN_CLIENT_DATA },
        },
      },
    }));
  };

  const submitModals = (stepKey: string, key: string, values: any) => {
    if (formResult[stepKey]?.questions.fields[key]) {
      setFormResult(prevValue => ({
        ...prevValue,
        [stepKey]: {
          ...prevValue[stepKey],
          questions: {
            ...prevValue[stepKey].questions,
            fields: {
              ...prevValue[stepKey].questions.fields,
              [key]: {
                ...prevValue[stepKey].questions.fields[key],
                value: values.value,
              },
            },
          },
        },
      }));
    } else {
      setFormResult(prevValue => ({
        ...prevValue,
        [stepKey]: {
          ...prevValue[stepKey],
          questions: {
            ...prevValue[stepKey].questions,
            fields: {
              ...prevValue[stepKey].questions.fields,
              [key]: values,
            },
          },
        },
      }));
    }
  };

  const findClient = (value: ClientList) => {
    if (value) {
      if (value?.formatList) {
        return value.formatList.toLowerCase().includes(fieldValue.toLowerCase());
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const filterClientList = (list: ClientList[]) => {
    return list.filter(findClient);
  };

  const handleSelectClient = (value: ClientList) => {
    setFormResult(prevValue => ({
      ...prevValue,
      CLIENT: {
        ...prevValue.CLIENT,
        questions: {
          ...prevValue.CLIENT.questions,
          fields: {
            ...prevValue.CLIENT.questions.fields,
            CLIENT: {
              ...prevValue.CLIENT.questions.fields.CLIENT,
              value: value.name,
            },
            CLIENT_CODE: {
              ...prevValue.CLIENT.questions.fields.CLIENT_CODE,
              value: value.bp,
              label: strings.newForm.clientChatResult.clientCode,
              permissions: {
                visible: true,
                editable: false,
              },
              display: false,
              type: 'text',
            },
            CORPORATE_CODE: {
              ...prevValue.CLIENT.questions.fields.CORPORATE_CODE,
              value: value.corporateGroup,
              label: strings.newForm.clientChatResult.corporateCode,
              display: false,
              permissions: {
                visible: true,
                editable: false,
              },
              type: 'text',
            },
          },
        },
      },
    }));

    setEnableClientSearch(false);
    setFieldValue(value.formatList);
    setShowList(false);
    setInitialized(true);
  };

  const scrollTo = (name?: string, session?: string, openClose?: boolean) => {
    console.log(name, session, openClose);
    scroller.scrollTo(session || name, {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart',
      containerId: 'scrollContainer',
    });

    const section = document.getElementById(session || name);

    if (section.tagName.toLowerCase() === 'details') {
      if (section.hasAttribute('open') && !openClose && openClose === undefined) {
        if (!session) {
          section.removeAttribute('open');
        }
      } else {
        section.setAttribute('open', 'open');
      }
    }
  };

  const [modalToOpen, setModalToOpen] = React.useState<string>(null);
  const openModals = (item: any) => {
    console.log('item', item);
    switch (item) {
      case 'STRETCH':
      case 'AREA_SHRINK':
      case 'YIELD_LIMIT':
      case 'LOAD_LIMIT':
        setModalToOpen('MECHANICAL_PROPERTIES');
        break;
      default:
        setModalToOpen(item);
    }
  };

  const strings = FormStrings[userInfo.language];

  const value = {
    formResult,
    setFormResult,
    handleValueAutocompleteChange,
    clearNluAttributes,
    enableClientSearch,
    setEnableClientSearch,
    showList,
    setShowList,
    fieldValue,
    setFieldValue,
    disableSubmit,
    setDisableSubmit,
    conversationId,
    setConversationId,
    cfId,
    setCfId,
    userInfo,
    strings,
    showModalConfirmation,
    setShowModalConfirmation,
    chatCreateV2,
    setChatCreateV2,
    showModalErroNlu,
    setShowModalErroNlu,
    initialized,
    setInitialized,
    show,
    loadingCreateAnalysis,
    createAnalysis,
    dataClientsList,
    loadingClients,
    postFile,
    loadingPostFile,
    filterClientList,
    handleFocusClient,
    deleteClient,
    handleSelectClient,
    dataFlowClient,
    dataFlowSteel,
    dataFlowProduct,
    dataFlowGoal,
    dataFlowAdditional,
    loadingFlowClient,
    loadingFlowSteel,
    loadingFlowProduct,
    loadingFlowGoal,
    loadingFormFlowAdditional,
    callFlows,
    scrollTo,
    openModals,
    modalToOpen,
    setModalToOpen,
    submitModals,
    callBackAttribute,
    responseCallback,
    loadingCallback,
  };

  const memoizedValue = React.useMemo(() => value, [value]);

  return <FormContext.Provider value={memoizedValue}>{props.children}</FormContext.Provider>;
};
