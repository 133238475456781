import React from 'react';
import { selectedItemTableColumnsDefinitions } from '@app/modules/shipping-request/shipping-request.constants';
import {
  IShippingRequestColumns,
  IShippingRequestRow,
} from '@app/modules/shipping-request/shipping-request.interfaces';
import { TD, TR } from '@atomic/mol.table';
import { ShippingRequestTableBodyTD } from '../../../shipping-request-body/partials/shipping-request-table/partials/shipping-request-table-body/partials/shipping-request-table-body-td/shipping-request-table-body-td.component';

interface IShippingRequestSelectedItemTableBodyProps {
  selectedRows: IShippingRequestRow[];
}

export const ShippingRequestSelectedItemTableBody: React.FC<IShippingRequestSelectedItemTableBodyProps> = ({
  selectedRows,
}) => {
  return (
    <>
      {selectedRows.map(row => {
        return (
          <TR key={row.index} bordered>
            {selectedItemTableColumnsDefinitions.map((column: IShippingRequestColumns) => (
              <TD key={column.field}>
                <ShippingRequestTableBodyTD item={row} type={column.type} value={row[column.field]} />
              </TD>
            ))}
          </TR>
        );
      })}
    </>
  );
};
