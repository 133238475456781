/* eslint-disable complexity */

import React from 'react';
import { Dropzone } from '@atomic/atm.dropzone';
import { Body } from '@atomic/atm.typography';
import { Color } from '@atomic/obj.constants';
import { Form, Validators } from '@atomic/obj.form';
import { FormContext } from '../../form.context';
import { FormFlowProps } from '../form-flow-fields.container';
import { FormResultItems } from '../result/form-result.component';

export const FileField = (props: FormFlowProps) => {
  const { formResult, strings } = React.useContext(FormContext);
  const stringsNewForm = strings.newForm.messages;

  const checkValidator = (value: FormResultItems[], mandatory: boolean) => {
    if (mandatory) {
      if (value) {
        return [];
      }
      return [Validators.Required(stringsNewForm.mandatoryAlert)];
    } else {
      return [];
    }
  };

  return (
    <Form.Field name={props.field.key} validators={checkValidator(props.field?.value, props.field?.mandatory)}>
      <Dropzone
        limitAcceptance='.pdf'
        onDropAccepted={value => props.handleUpload(value, props.field.label, props.stepKey)}
      />
      {formResult && formResult[props.stepKey]?.[props.field?.key]?.value && (
        <Body bold={600} color={Color.Accessory}>{`${formResult[props.stepKey]?.[props.field.key]?.label}: ${
          formResult[props.stepKey]?.[props.field?.key]?.file[0].name
        }`}</Body>
      )}
    </Form.Field>
  );
};
