export enum SurrogateEvents {
  SubmitSurrogateClick = 'SubmitSurrogateClick',
  GetSurrogateError = 'GetSurrogateError',
  PostSurrogateSuccess = 'PostSurrogateSuccess',
  PostSurrogateError = 'PostSurrogateError',
  GetSurrogateEmailsError = 'GetSurrogateEmailsError',
  PutSurrogateSuccess = 'PutSurrogateSuccess',
  PutSurrogateError = 'PutSurrogateError',
  DeleteSurrogateClick = 'DeleteSurrogateClick',
  DeleteSurrogateSuccess = 'DeleteSurrogateSuccess',
  DeleteSurrogateError = 'DeleteSurrogateError',
  CheckSurrogateError = 'CheckSurrogateError',
}
