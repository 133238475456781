import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { ConfigurationsReponse } from '@app/data/http/quote-params.dto';

export async function getChatCreateV2(params): Promise<ConfigurationsReponse> {
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: params.language };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.CONFIGURATIONS}`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useChatCreate(params, onSuccess?, onError?) {
  return useQuery(['getChatCreateV2', params], () => getChatCreateV2(params), {
    onSuccess,
    onError,
    enabled: true,
    refetchOnWindowFocus: false,
  });
}
