/* eslint-disable prettier/prettier */
export const hasSomeNoConfirmedFiltersSelector = (state: any, tab: string) => {
  const hasSomeNoConfirmedFilters = !!Object.keys(state.advancedFilters[tab].filters).some(key => {
    const filter = state.advancedFilters[tab].filters[key];

    if (Array.isArray(filter)) {
      return filter?.some(value => !!value.trim().length);
    } else {
      return filter?.startDate !== null && filter?.endDate !== null;
    }
  });

  return hasSomeNoConfirmedFilters;
};

export const hasSomeConfirmedFiltersSelector = (state: any, tab: string) => {
  const hasSomeConfirmedFilters = !!Object.keys(state.advancedFilters[tab]?.confirmedFilters).some(key => {
    const filter = state.advancedFilters[tab]?.confirmedFilters[key];
    if (Array.isArray(filter)) {
      return filter.some(value => !!value.trim().length);
    } else {
      return (
        (!!filter.startDate && !!filter.endDate) || filter.minVal > filter.minLimit || filter.maxVal < filter.maxLimit
      );
    }
  });
  return hasSomeConfirmedFilters;
};

export const countFiltersSelector = (state: any, tab: string) => {
  const { confirmedFilters } = state.advancedFilters[tab];
  let count = 0;

  if (confirmedFilters) {
    count = Object.keys(confirmedFilters).reduce((accumalutor, key) => {
      if (Array.isArray(confirmedFilters[key])) {
        return accumalutor + (confirmedFilters[key].length ? 1 : 0);
      } else {
        return (
          accumalutor +
          (confirmedFilters[key]?.startDate ||
            confirmedFilters[key]?.minVal > confirmedFilters[key].minLimit ||
            confirmedFilters[key].maxVal < confirmedFilters[key].maxLimit
            ? 1
            : 0)
        );
      }
    }, count);
  }

  return count;
};


