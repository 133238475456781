import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { UserData } from '@app/providers';

export interface OrderUpdateDataSourceParams {
  data: (string | number | boolean | Date)[][];
  user: UserData;
  row: (string | number | boolean | Date)[][];
  comments: string;
  emissionCode: string;
  companyName: string;
  customerIds: string;
}

const endpoint = {
  ORDERUPDATE: '/email-wallet',
};

export const OrderUpdateDataSource = {
  putNewWallet: (params: OrderUpdateDataSourceParams): Promise<void> => {
    const { data, user, row, emissionCode, companyName, comments, customerIds } = params;
    const currentDate = new Date();
    const body = {
      clientEmail: user.email,
      clientName: user.userName,
      emissionCode: emissionCode,
      companyName: companyName,
      clientRequestDate: currentDate,
      generalMessage: comments,
      changeAmount: row.length,
      rows: data,
      rowsGAT: row,
    };

    return Container.get(HttpRequestBuilder)
      .put(endpoint.ORDERUPDATE)
      .params({ customerIds: customerIds })
      .headers({
        'Content-Type': 'application/json',
        'Sso-Access': user.ssoAccess,
      })
      .data(body)
      .execute();
  },
};
