import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { SurrogateDataSource } from '@app/data/http/surrogate.datasource';
import { IAuthState } from '@app/modules/auth/auth.interfaces';
import { SurrogateEvents } from '../../surrogate.analytics';
import { SLICE_NAME } from '../../surrogate.constants';
import { ISurrogateState } from '../../surrogate.interfaces';
import { ISurrogateStrings, Strings } from '../../surrogate.strings';

export const putSurrogate = createAsyncThunk(`${SLICE_NAME}/putSurrogate`, async (_, thunkAPI) => {
  const authState: any = thunkAPI.getState();
  const { userInfo }: IAuthState = authState.auth;
  const strings: ISurrogateStrings = Strings[userInfo.language];
  const surrogateState: any = thunkAPI.getState();
  const {
    _id,
    from,
    surrogatesTo,
    isIndeterminateTime,
    expiresFrom,
    expiresTo,
  }: ISurrogateState = surrogateState.surrogate;

  return await SurrogateDataSource.putSurrogate({
    _id,
    postSurrogateParams: { from, surrogatesTo: surrogatesTo.join(), isIndeterminateTime, expiresFrom, expiresTo },
  })
    .then(() => {
      LogAnalytics.click({ tipo: SurrogateEvents.PutSurrogateSuccess });
      return { message: strings.success };
    })
    .catch(err => {
      LogAnalytics.error({
        tipo: SurrogateEvents.PutSurrogateError,
        errorMessage: err.response.message,
        errorDetails: JSON.stringify(err.response.data),
      });
      return thunkAPI.rejectWithValue({ status: err.response.status, message: err.response.data?.errors[0]?.message });
    });
});

export const addPutSurrogateReducer = builder => {
  builder.addCase(putSurrogate.pending, state => {
    state.loading = true;
  });
  builder.addCase(putSurrogate.fulfilled, (state: ISurrogateState, action) => {
    state.loading = false;
    state.successMessage = action.payload.message;
    state.error = false;
    state.errorMessage = '';
  });
  builder.addCase(putSurrogate.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload.status;
    state.errorMessage = action.payload.message;
  });
};
