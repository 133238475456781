import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RENEW_TOKEN_INTERVAL_MILESECONDS } from '@app/constants';
import { LogAnalytics } from '@app/core/analytics';
import { AzureDataSource, IAzureToken } from '@app/data/http/azure.datasource';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { authErrorMessageMap } from '../../auth-error.mapper';
import { AuthEvents } from '../../auth.analytics';
import { SLICE_NAME } from '../../auth.constants';

export const getAzureTokenThunk = createAsyncThunk(`${SLICE_NAME}/getAzureToken`, async (_, thunkAPI) => {
  return AzureDataSource.getAzureToken()
    .then(data => {
      return data;
    })
    .catch(err => {
      LogAnalytics.error({ tipo: AuthEvents.AzureError, message: authErrorMessageMap[parseInt(err.response.status)] });
      thunkAPI.dispatch(
        setToast({
          show: true,
          text: authErrorMessageMap[parseInt(err.response.status)],
          variant: ToastVariant.DANGER,
        }),
      );
      return thunkAPI.rejectWithValue({ status: err.response.status });
    });
});

export const addGetTokenReducers = builder => {
  builder.addCase(getAzureTokenThunk.pending, state => {
    state.loading = true;
    state.loadingValidateToken = true;
  });
  builder.addCase(getAzureTokenThunk.fulfilled, (state, action: PayloadAction<IAzureToken>) => {
    const data: IAzureToken = action.payload;
    const azureDateTimeExpiration = new Date().getTime() + RENEW_TOKEN_INTERVAL_MILESECONDS;

    state.azureToken = data.access_token;
    state.azureExpireIn = data.expires_in;
    state.azureDateTimeExpiration = azureDateTimeExpiration;

    window.localStorage.setItem('azureToken', data.access_token);
    window.localStorage.setItem('azureExpireIn', data.expires_in.toString());
    window.localStorage.setItem('azureDateTimeExpiration', azureDateTimeExpiration.toString());
    state.loading = false;
    state.loadingValidateToken = false;
  });
  builder.addCase(getAzureTokenThunk.rejected, state => {
    state.loading = false;
    state.loadingValidateToken = false;
  });
};
