/* eslint-disable complexity */

import React from 'react';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { TextField } from '@atomic/atm.text-field';
import { Form, Validators } from '@atomic/obj.form';
import { LoadingState } from '@atomic/obj.loading-state';
import { FormContext } from '../../form.context';
import { FormFlowProps } from '../form-flow-fields.container';
import { AutoCompleteList } from './autoCompleteList';

export const AutoCompleteField = (props: FormFlowProps) => {
  const {
    loadingClients,
    fieldValue,
    strings,
    handleValueAutocompleteChange,
    handleSelectClient,
    handleFocusClient,
    deleteClient,
    showList,
    setShowList,
    dataClientsList,
    filterClientList,
  } = React.useContext(FormContext);

  //const stringsNewForm = strings.newForm.messages;

  return (
    <LoadingState loading={loadingClients}>
      <Form.Field
        name={props.field.key}
        initialValue={fieldValue}
        onValueChange={handleValueAutocompleteChange}
        validators={[Validators.Required(strings.newForm.clientField.alertValidator)]}
      >
        <TextField hasButton onFocus={e => handleFocusClient(e.target.value)}>
          <Button kind='link' onClick={() => deleteClient()}>
            <FaIcon.Close size='1x' />
          </Button>
        </TextField>
      </Form.Field>
      <AutoCompleteList
        show={showList}
        users={dataClientsList && filterClientList(dataClientsList)}
        onClose={() => setShowList(false)}
        onSelect={handleSelectClient}
        loading={loadingClients}
      />
    </LoadingState>
  );
};
