import styled from 'styled-components';
import { Body } from '@atomic/atm.typography';
import { Color } from '@atomic/obj.constants';

export const ButtonWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface SSOProps {
  textDecoration: string;
  disabled: boolean;
  loading: boolean;
}

export const SSOContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 49%;
`;

export const SSOButtonWrapperStyled = styled(Body)`
  color: ${Color.Primary};
  opacity: ${(props: SSOProps) => (props.disabled || props.loading ? '25%' : '100%')};
  text-decoration: ${(props: SSOProps) => (props.textDecoration ? props.textDecoration : 'auto')};
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: -95px;
  margin-top: -10px;
`;

interface SSOLinkContentProps {
  processing: boolean;
}

export const SSOLinkSpinnerStyled = styled.span`
  display: ${(props: SSOLinkContentProps) => (props.processing ? 'inline-block' : 'none')} !important;
  position: absolute;
  margin-top: -15px;
  margin-left: -15px;
`;
