import Container from 'typedi';
import Cookies from 'universal-cookie';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { IAuthState } from '../../auth.interfaces';
import { getAzureTokenThunk } from './get-azure-token.thunk';
import { buildUserData } from './initialize-and-get-profile.thunk';
import { refreshTokenRequest } from './refresh-token.thunk';

const authStorageService: AuthStorageService = Container.get(AuthStorageService);

export const refreshAndUpdateTokensThunk = (ssoAccess: boolean) => async (dispatch, getState) => {
  await dispatch(getAzureTokenThunk());
  const { azureToken, azureDateTimeExpiration }: IAuthState = getState().auth;
  const cookies = new Cookies();
  cookies.set('@authorization-token', azureToken, { domain: '.egerdau.com.br' });
  cookies.set('@authorization-token-expiration', azureDateTimeExpiration, { domain: '.egerdau.com.br' });

  if (ssoAccess) {
    await dispatch(refreshTokenRequest());
    const { token, userInfo }: IAuthState = getState().auth;
    cookies.set('@access_token', token, { domain: '.egerdau.com.br' });
    authStorageService.login(buildUserData(token, userInfo, ssoAccess));
  }

  return;
};
