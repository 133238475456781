import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import {
  SalesOrdersMaritimeHarborItem,
  SalesOrdersMaritimeOpenItem,
  SalesOrdersMaritimeShippedItem,
} from '@app/models/maritime-order-item.model';
import { AuthStorageService } from '../storage/auth.storage';
import {
  mapSalesOrdersMaritimeHarborItem,
  mapSalesOrdersMaritimeOpenItem,
  mapSalesOrdersMaritimeShippedItem,
  SalesOrdersMaritimeHarborDto,
  SalesOrdersMaritimeOpenDto,
  SalesOrdersMaritimeShippedDto,
} from './me-maritime-order.dto';
import { HarborMeOrderParams, OpenMeOrderParams, ShippedMeOrderParams } from './order-params.dto';

const endpoint = {
  EXTERNAL_MARKET: '/me',
  MARITIME: '/maritime',
  SALES_ORDER: '/sales-orders',
  SHIPPED: '/shipped',
  HARBOR: '/harbor',
};

export const MEMaritimeOrderDataSource = {
  getMeMaritimeOpen: (params: OpenMeOrderParams): Promise<SalesOrdersMaritimeOpenItem[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.EXTERNAL_MARKET + endpoint.MARITIME + endpoint.SALES_ORDER)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: SalesOrdersMaritimeOpenDto): Promise<SalesOrdersMaritimeOpenItem[]> => {
          return mapSalesOrdersMaritimeOpenItem(res.salesOrdersMaritime);
        },
      })
      .execute();
  },
  getMeMaritimeShipped: (params: ShippedMeOrderParams): Promise<SalesOrdersMaritimeShippedItem[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.EXTERNAL_MARKET + endpoint.MARITIME + endpoint.SHIPPED)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: SalesOrdersMaritimeShippedDto): Promise<SalesOrdersMaritimeShippedItem[]> => {
          return mapSalesOrdersMaritimeShippedItem(res.shippedMaritime);
        },
      })
      .execute();
  },
  getMeMaritimeHarbor: (params: HarborMeOrderParams): Promise<SalesOrdersMaritimeHarborItem[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.EXTERNAL_MARKET + endpoint.MARITIME + endpoint.HARBOR)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: SalesOrdersMaritimeHarborDto): Promise<SalesOrdersMaritimeHarborItem[]> => {
          return mapSalesOrdersMaritimeHarborItem(res.harborMaritime);
        },
      })
      .execute();
  },
};
