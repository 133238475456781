import styled from 'styled-components';
import { Spacing } from '@atomic/obj.constants';

export const ShippingRequestSuggestionTableWrapper = styled.div`
  min-width: ${props => (props.isGKN ? '1600px' : 'unset')};
  padding-right: ${props => (props.isGKN ? Spacing.Medium : 'unset')};
`;
export const ShippingRequestSuggestionTableScroll = styled.div`
  overflow-y: scroll;
  max-height: 165px;
`;

export const ShippingRequestSuggestionModalTotalFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: 'flex-start';
  align-content: flex-start;
  justify-content: 'flex-start';
  padding-left: ${Spacing.Large};
  width: 60%;
`;
