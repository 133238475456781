import * as React from 'react';
import { Validators } from '@atomic/obj.form';
import { FormContext } from '../form.context';
import { Field } from './form-flow-fields.container';
import { FormResultItems } from './result/form-result.component';

export interface FormFlowFieldsContextState {
  checkInitialValue: (stekey: string, field: Field, result: FormResultItems[]) => void;
  checkValidator: (result: FormResultItems[], stepKey: string, field: string, mandatory: boolean) => void;
}

export const FormFlowFieldsContext = React.createContext<FormFlowFieldsContextState>(null);

export const FormFlowFieldsProvider: React.FC = props => {
  const { strings } = React.useContext(FormContext);

  const stringsNewForm = strings.newForm.messages;

  function checkInitialValue(stepKey?: string, field?: Field, result?: FormResultItems[]) {
    if (result[stepKey]) {
      return !result[stepKey]?.questions?.fields[field.key]?.value
        ? []
        : result[stepKey]?.questions?.fields[field.key]?.value;
    } else {
      return [];
    }
  }

  const checkValidator = (result: FormResultItems[], stepKey: string, field: string, mandatory: boolean) => {
    if (mandatory) {
      if (result && result[stepKey]) {
        if (result[stepKey]?.questions?.fields[field]?.value) {
          return [];
        }
      }
      return [Validators.Required(stringsNewForm.mandatoryAlert)];
    } else {
      return [];
    }
  };

  const value = {
    checkInitialValue,
    checkValidator,
  };

  const memoizedValue = React.useMemo(() => value, [value]);

  return <FormFlowFieldsContext.Provider value={memoizedValue}>{props.children}</FormFlowFieldsContext.Provider>;
};
