import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { Material } from '@app/models';
import { AuthStorageService } from '../storage/auth.storage';
import { DocumentResponse, spreadsheetResponseToBlob } from './document.dto';
import {
  IMaterialsValidationResponse,
  IMultipleValidation,
  IMultipleValidationResponse,
  mapMaterialsValidationResponseToMaterials,
  mapMultipleValidation,
} from './order-input.dto';

export interface RowGate {
  docOrderType: string;
  orderOrganization: string;
  channelDistribution: string;
  orderNumber: string;
  orderItemRef: string;
  orderDate: string;
  issuer: string;
  receiver: string;
  payer: string;
  receiverInvoice: string;
  clientCG: string;
  center: string;
  material: string;
  materialClientNumber: string;
  weight: string;
  UM: string;
  shippingDate: string;
  orderInstuctions: string;
  CPgt: string;
  IncTm: string;
  IncoTm2: string;
  DtfixPrice: string;
  BlqR: string;
  GripoCondition: string;
  minimumAmount: string;
  multipleAmount: string;
  lowerLimite: string;
  higherLimite: string;
  message: string;
  materialDescription: string;
  flagged: string;
}

export interface OrderInplantationParams {
  emissionCode: string;
  emissionCompanyName: string;
  clientRequestDate: string;
  receiverCode: string;
  receiverCompanyName: string;
  clientImplantationQuantity: number;
  clientRequestNumber: string;
  issuer: string;
  receiver: string;
  materials: MaterialParams[];
  customerIds?: string;
  typeMaterialName: string;
  typeImplantation: string;
}

export interface MaterialParams {
  orderItemRef: string;
  material: string;
  materialDesctiption: string;
  materialClientNumber: string;
  weight: string;
  UM: string;
  shippingDate: string;
  orderNumber: string;
  isM2Date: boolean;
}

export interface OrderInplantationBody {
  clientEmail: string;
  clientName: string;
  clientRequestDate: string;
  emissionCode: string;
  emissionCompanyName: string;
  receiverCode: string;
  receiverCompanyName: string;
  generalMessage: string;
  clientImplantationQuantity: number;
  clientRequestNumber: string;
  typeMaterialName: string;
  typeImplantation: string;
  rowsGate: string[][];
}

export interface OrderInputResponse {
  message: string;
  status: string;
  pdfBase64: string;
}

export interface GetCatalogParams {
  customerIds: string;
}

export interface IValidateMaterialItemParams {
  sku: string;
  quantity: string;
  unitOfMeasure: string;
  datetCustomerRequired: string;
  orderItem: string;
}
export interface IValidateMaterialParams {
  emissionCode: string;
  clientImplantationQuantity: number;
  typeMaterialName: string;
  requests: IValidateMaterialItemParams[];
  customerIds: string;
}

export interface IMultipleMaterialParams {
  material: string;
  quantity: string;
  unitOfMeasure: string;
  plant: string;
}

export interface IValidateMultipleParams {
  emissionCode: string;
  multiple: IMultipleMaterialParams[];
}

const endpoint = {
  IMPLANTATION_REQUEST: '/mi/implantation-request',
  CATALOG: 'mi/implantation-request/catalogo-material',
  VALIDATE_MATERIAL: 'mi/implantation-request/upload/material-validation',
  VALIDATE_MULTIPLE: 'mi/implantation-request/multiple',
};

const materialsToRowsGate = (params: OrderInplantationParams) => {
  const rowsGateObjects: RowGate[] = params.materials.map(
    (material: MaterialParams): RowGate => ({
      docOrderType: 'TIPODOCVENDAS',
      orderOrganization: 'ORGVENDAS',
      channelDistribution: 'CANALDISTRIBUICAO',
      orderNumber: material.orderNumber,
      orderItemRef: material.orderItemRef,
      orderDate: params.clientRequestDate,
      issuer: params.issuer,
      receiver: params.receiver,
      payer: 'PAGADOR',
      receiverInvoice: 'RECEBEDORFATURA',
      clientCG: 'CLIENTEVIACG',
      center: 'CENTRO',
      material: material.material,
      materialClientNumber: material.materialClientNumber,
      weight: material.weight?.toString(),
      UM: material.UM,
      shippingDate: material.shippingDate,
      orderInstuctions: 'INSTRUVENDAS',
      CPgt: 'CPGT',
      IncTm: 'INCTM',
      IncoTm2: 'INC0TM2',
      DtfixPrice: 'DTFIXPRECO',
      BlqR: 'BLQR',
      GripoCondition: 'GRIPOCOND3',
      minimumAmount: 'QTDMIN',
      multipleAmount: 'QTDMULTI',
      lowerLimite: 'LIMITEINFERIOR',
      higherLimite: 'LIMITESUPERIOR',
      message: 'MENSAGEM',
      materialDescription: material.materialDesctiption,
      flagged: material.isM2Date ? 'flagged' : '',
    }),
  );

  const rowsGate: string[][] = rowsGateObjects.map(rowGateObject => Object.values(rowGateObject));

  return rowsGate;
};

export const OrderInputDatasource = {
  submitOrder: (params: OrderInplantationParams): Promise<OrderInputResponse> => {
    const user = Container.get(AuthStorageService).getUser();
    const body: OrderInplantationBody = {
      clientEmail: user.email,
      clientName: user.userName,
      clientRequestDate: params.clientRequestDate,
      emissionCode: params.emissionCode,
      emissionCompanyName: params.emissionCompanyName,
      receiverCode: params.receiverCode,
      receiverCompanyName: params.receiverCompanyName,
      generalMessage: 'Solicitação de pedido implantação',
      clientImplantationQuantity: params.clientImplantationQuantity,
      clientRequestNumber: params.clientRequestNumber,
      typeMaterialName: params.typeMaterialName,
      typeImplantation: params.typeImplantation,
      rowsGate: materialsToRowsGate(params),
    };

    return Container.get(HttpRequestBuilder)
      .put(endpoint.IMPLANTATION_REQUEST)
      .headers({
        'Content-Type': 'application/json',
        'Sso-Access': user.ssoAccess,
      })
      .data(body)
      .execute();
  },

  getCatalog: (params: GetCatalogParams) => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.CATALOG)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: DocumentResponse): Promise<string> => spreadsheetResponseToBlob(res),
      })
      .execute();
  },

  validateMaterial: (body: IValidateMaterialParams) => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .put(endpoint.VALIDATE_MATERIAL + '?customerIds=' + body.customerIds)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .data(body)
      .interceptors({
        afterSuccess: async (res: IMaterialsValidationResponse): Promise<Material[]> =>
          mapMaterialsValidationResponseToMaterials(res),
      })
      .execute();
  },
  validateMultiple: (body: IValidateMultipleParams) => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .put(endpoint.VALIDATE_MULTIPLE)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .data(body)
      .interceptors({
        afterSuccess: async (res: IMultipleValidationResponse): Promise<IMultipleValidation[]> =>
          mapMultipleValidation(res),
      })
      .execute();
  },
};
