import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { Body, H1 } from '@atomic/atm.typography';
import { Table } from '@atomic/mol.table';
import { Hbox, Separator } from '@atomic/obj.box';
import { Form, FormData } from '@atomic/obj.form';
import { Col, Grid, Row } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { FormContext } from '../form.context';
import { FormStrings } from '../form.string';
import { ModalGeneralAttributesFields } from './modal-general-attribute-fields.component';

interface ModalGeneralAttributesProps {
  data?: any;
  open: boolean;
  attribute: { key: string; label: string; question: string; type: string; unit?: string; callback?: string };
  submitModals: (stepKey: string, key: string, values: any) => void;
}

export const ModalGeneralAtributes: React.FC<ModalGeneralAttributesProps> = props => {
  const { setModalToOpen, callBackAttribute } = React.useContext(FormContext);
  const [opened, setOpened] = React.useState(props.open);

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = FormStrings[userInfo.language].modalMacroataque;

  const convertDataToNumber = (val: string | number) => {
    if (val) {
      return val.toString().replace(',', '.');
    }
    return val;
  };

  const handleSubmit = (data: FormData<any>) => {
    if (Object.keys(data.error).length > 0) {
      return;
    }

    let newValues;

    if (props.attribute.type === 'range') {
      newValues = `${convertDataToNumber(data.data.min)} - ${convertDataToNumber(data.data.max)}`;
    } else if (props.attribute.type === 'text') {
      newValues = `${data.data.value}`;
    }

    callBackAttribute({ url: props.attribute.callback });

    props.submitModals('ADITIONAL', props.attribute.key, {
      key: props.attribute.key,
      label: props.attribute.label,
      question: props.attribute.question,
      type: props.attribute.type,
      permissions: { visible: true, editable: true },
      display: true,
      value: newValues,
      unit: props?.attribute?.unit,
    });

    setModalToOpen(null);
  };

  const handleClose = () => {
    setOpened(false);
    setModalToOpen(null);
  };

  return (
    <Modal preventOverlayClick opened={opened} small onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Grid fluid>
          <Row mb>
            <Col xs={12}>
              <H1>{props?.attribute?.label}</H1>
            </Col>
          </Row>
          <Row mb>
            <Col xs={12}>
              <Body>
                {props?.attribute?.question} {props?.attribute?.unit && `${props.attribute.unit}`}
              </Body>
              <Separator />
              <Table>
                <ModalGeneralAttributesFields type={props?.attribute?.type} />
              </Table>
            </Col>
          </Row>
          <Row mb>
            <Col xs={8}></Col>
            <Col xs={4}>
              <Hbox.Item vAlign='end' noGrow>
                <Button kind='primary' type='submit'>
                  {strings.btnSave}
                </Button>
              </Hbox.Item>
            </Col>
          </Row>
        </Grid>
      </Form>
    </Modal>
  );
};
