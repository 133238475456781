import Container from 'typedi';
import { AzureHttpRequestBuilder } from './azure-http-request.builder';

const endpoint = {
  GET_TOKEN: '/token',
};

export interface IAzureToken {
  expires_in: number;
  access_token: string;
}

export const AzureDataSource = {
  getAzureToken: (): Promise<IAzureToken> => {
    return Container.get(AzureHttpRequestBuilder)
      .post(endpoint.GET_TOKEN)
      .headers({ Accept: '*/*', 'Access-Control-Allow-Origin': 'egerdau.com.br' })
      .interceptors({
        afterSuccess: async (res: { data: IAzureToken }): Promise<IAzureToken> => {
          const value: IAzureToken = { access_token: res.data.access_token, expires_in: res.data.expires_in };
          return value;
        },
      })
      .execute();
  },
};
