import { AppConfig } from '@app/configure-app';
import { LogAnalytics } from '@app/core/analytics';
import { persistor } from '@app/core/redux/store';
import { UserAnalyticsType } from '@app/models/analytics.model';
import { AppPath } from '@app/modules/app/route-constants';
import { AppWindow } from '@app/utils/interfaces';

export const handleLogUser = (user, applications) => {
  if (applications.indexOf('gsb') > -1) {
    LogAnalytics.setUserId(user.uid);
    LogAnalytics.setUserProperties({
      kind: user.isEmployee ? UserAnalyticsType.Employee : UserAnalyticsType.Client,
      userId: user.uid,
    });
    LogAnalytics.login({
      user: user.uid,
      kind: user.isEmployee ? UserAnalyticsType.Employee : UserAnalyticsType.Client,
      client: user.isEmployee ? '' : user.orderAccessList?.[0],
    });
  }
};

export const redirect = (url: string) => {
  if (window) {
    window.location.href = url;
  }
};

export const deleteCookie = (name: string, path: string, domain: string) => {
  if (getCookie(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
};

export const getCookie = (name: string) => {
  return document.cookie.split(';').some(c => {
    return c.trim().startsWith(name + '=');
  });
};

const config: AppConfig = ((window as unknown) as AppWindow).__APP_CONFIG__;

export const cleanCache = () => {
  const envVersion = config.version;
  const localStoreVersion = localStorage.getItem('version');

  if (envVersion !== localStoreVersion) {
    persistor.pause();

    window.localStorage.removeItem('azureDateTimeExpiration');
    window.localStorage.removeItem('azureExpireIn');
    window.localStorage.removeItem('azureToken');

    persistor.flush().then(() => {
      return persistor.purge();
    });

    setTimeout(() => {
      localStorage.setItem('version', envVersion);
      window.location.href = AppPath.AUTHENTICATION;
    }, 0);
  }
};

export const botUserList = [
  'rbtcxpepp@gerdau.com.br',
  'rbtcxpped@gerdau.com.br',
  'rbtcxprtc@gerdau.com.br',
  'rbtcxpqua@gerdau.com.br',
  'rbtcxpseller@gerdau.com.br',
  'rbtcxpgat@gerdau.com.br',
  'rbtcxpclient@gerdau.com.br',
  'rbtcxpgfmi@gerdau.com.br',
  'rbtcxpccmicart@gerdau.com.br',
  'rbtcxpccmifin@gerdau.com.br',
  'rbtcxpccmicot@gerdau.com.br',
  'rbtcxpccmi@gerdau.com.br',
  'rbtcxpsalesrep@gerdau.com.br',
  'rbtcxpclienteng@gerdau.com.br',
  'rbtcxpclientspa@gerdau.com.br',
  'rbtcxpccmecart@gerdau.com.br',
  'rbtcxpccmefin@gerdau.com.br',
  'rbtcxpccmecot@gerdau.com.br',
  'rbtcxpccme@gerdau.com.br',
  'cpg-rbtc@gerdau.com.br',
  'cpg-gssk@gerdau.com.br',
  'rbtgermczr8@gerdau.com.br',
  'rbtgermczr9@gerdau.com.br',
  'rbtgermczr2@gerdau.com.br',
  'rbtgermczr5@gerdau.com.br',
  'rbtgermczr16@gerdau.com.br',
  'rbtgermczr3@gerdau.com.br',
  'rbtgermczr6@gerdau.com.br',
  'rbtgermczr7@gerdau.com.br',
  'rbtgermczr10@gerdau.com.br',
  'rbtgermczr13@gerdau.com.br',
  'rbtgermczr15@gerdau.com.br',
  'rbtgermczr14@gerdau.com.br',
  'rbtgermczr11@gerdau.com.br',
  'rbtgermczr18@gerdau.com.br',
  'rbtgermczr19@gerdau.com.br',
  'rbtgermczr17@gerdau.com.br',
];

export const isTokenExpired = (azureDateTimeExpiration: number) => {
  let isExpired = false;

  if (!azureDateTimeExpiration || Date.now() >= azureDateTimeExpiration) {
    isExpired = true;
  }

  return isExpired;
};
