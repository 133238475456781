import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { LogAnalytics } from '@app/core/analytics';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { useFlashMessage } from '@app/modules/components/flash-message.hook';
import { FlashMessageTargetName } from '@app/providers';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Image } from '@atomic/atm.image';
import { TextField } from '@atomic/atm.text-field';
import { Body, Label } from '@atomic/atm.typography';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Color, FontSize, Spacing } from '@atomic/obj.constants';
import { VSeparator } from '@atomic/obj.grid';
import { DatePickerComponent } from './partials/date-picker/surrogate-date-picker.component';
import { RemoveSurrogateModal } from './partials/remove-surrogate-modal/remove-surrogate-modal.component';
import { SurrogateInfoModal } from './partials/surrogate-info-modal/surrogate-info-modal';
import {
  setEmail,
  removeSurrogate,
  setExpires,
  setExpiresFrom,
  setExpiresTo,
  setShowRemoveSurrogate,
  setSuccessMessage,
  setClearError,
  setShowSurrogateInfoModal,
} from './store/surrogate.store';
import { checkAndAddEmailThunk } from './store/thunks/check-and-add-email.thunk';
import { postSurrogate } from './store/thunks/post-surrogate.thunks';
import { putSurrogate } from './store/thunks/put-surrogate.thunks';
import { SurrogateEvents } from './surrogate.analytics';
import { INVALID_EMAIL } from './surrogate.constants';
import { ISurrogateStrings, Strings } from './surrogate.strings';
import {
  EmailLabel,
  FormFieldWrapper,
  HeightSeparator,
  SelectedEmail,
  SelectedEmailsWrapper,
  SurrogateBodyWrapper,
  TextFieldWrapper,
} from './surrogate.style';

export const Surrogate: React.FC = () => {
  const [show] = useFlashMessage(FlashMessageTargetName.APP);
  const [hasInvalidEmail, setHasInivalidEmail] = useState<boolean>(false);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const {
    _id,
    isIndeterminateTime,
    loading,
    email,
    surrogatesTo,
    error,
    errorMessage,
    successMessage,
    expiresFrom,
    expiresTo,
  } = useSelector((state: RootState) => state.surrogate);
  const strings: ISurrogateStrings = Strings[userInfo.language];
  const dispatch = useDispatch<AppThunkDispatch>();

  const handleEmailChange = (value: any) => {
    setHasInivalidEmail(false);
    dispatch(setClearError({}));
    dispatch(setEmail(value));
  };

  const handleAddEmail = () => {
    dispatch(checkAndAddEmailThunk(email));
  };

  const handleSetExpires = (checked: boolean) => {
    if (checked) {
      dispatch(setExpiresFrom(null));
      dispatch(setExpiresTo(null));
    }

    dispatch(setExpires(checked));
  };

  const handleSave = () => {
    if (!hasInvalidEmail) {
      LogAnalytics.click({ tipo: SurrogateEvents.SubmitSurrogateClick });
      if (_id) {
        dispatch(putSurrogate());
      } else {
        dispatch(postSurrogate());
      }

      dispatch(setShowSurrogateInfoModal(true));
    }
  };

  const handleIsDisabled = () => {
    let isDisabled = true;

    if (
      (surrogatesTo && surrogatesTo.length > 0 && expiresFrom && expiresTo) ||
      (surrogatesTo && surrogatesTo.length > 0 && isIndeterminateTime)
    ) {
      isDisabled = false;
    }

    return isDisabled;
  };

  useEffect(() => {
    if (_id) {
      dispatch(setShowSurrogateInfoModal(true));
    }
  }, [_id]);

  useEffect(() => {
    if (successMessage) {
      show('success', successMessage);
      dispatch(setSuccessMessage(''));
    }
  }, [successMessage]);

  useEffect(() => {
    if (error || (errorMessage && errorMessage.indexOf(INVALID_EMAIL) > -1)) {
      setHasInivalidEmail(true);
    }
  }, [error, errorMessage]);

  return (
    <FlexColumn vAlign='center' ml={Spacing.XLarge} mt={Spacing.XLarge}>
      <Body bold={700} size={FontSize.XLarge}>
        {strings.title}
      </Body>
      <VSeparator />
      <Body bold={700}>{strings.descriptionUp}</Body>
      <Body bold={700}>{strings.descriptionBottom}</Body>
      <VSeparator />
      <SurrogateBodyWrapper>
        <FormFieldWrapper>
          <FlexColumn fullWidth>
            <FlexRow vAlign='center' hAlign='space-between' pb={Spacing.XXSmall}>
              <FlexRow>
                <Body color={hasInvalidEmail ? Color.MediumAlert : Color.GrayDark} pr={Spacing.Small}>
                  {strings.input.label}
                </Body>
              </FlexRow>
            </FlexRow>
            <FlexRow>
              <TextFieldWrapper>
                <TextField onValueChange={value => handleEmailChange(value)} value={email} type='email' />
              </TextFieldWrapper>
              <FlexRow ml>
                <Button
                  testid={`${TEST_ID_CLOSE_MODAL}-add-surrogate-email`}
                  kind='primary'
                  onClick={() => handleAddEmail()}
                  loading={loading}
                  disabled={!email || surrogatesTo.includes(email)}
                >
                  {strings.addButton}
                </Button>
              </FlexRow>
            </FlexRow>
            {hasInvalidEmail && (
              <FlexRow>
                <Body color={Color.MediumAlert}>{strings.input.error}</Body>
                <FlexRow ml={Spacing.Small}>
                  <Image.InfoCircle />
                </FlexRow>
              </FlexRow>
            )}
          </FlexColumn>
          <Body>{strings.input.description}</Body>
          <VSeparator />
          <Body bold={700}>{strings.periodDescription}</Body>
          <DatePickerComponent />
          <FlexRow vAlign='center'>
            <CheckboxField
              id='surrogate-expires-time'
              checked={isIndeterminateTime}
              onValueChange={(_, checked) => handleSetExpires(checked)}
            />
            <Body>{strings.option}</Body>
          </FlexRow>
          <HeightSeparator />
          <FlexRow>
            <FlexRow mt>
              <Button
                testid={`${TEST_ID_CLOSE_MODAL}-save-surrogate`}
                kind='primary'
                onClick={() => handleSave()}
                loading={loading}
                disabled={handleIsDisabled()}
              >
                {strings.button}
              </Button>
            </FlexRow>
            <FlexRow mt ml>
              <Button
                testid={`${TEST_ID_CLOSE_MODAL}-open-remove-surrogate-modal`}
                kind='alertSecondary'
                onClick={() => {
                  LogAnalytics.click({ tipo: SurrogateEvents.DeleteSurrogateClick });
                  dispatch(setShowRemoveSurrogate(true));
                }}
                loading={loading}
                disabled={!_id}
              >
                {strings.deleteButton}
              </Button>
            </FlexRow>
          </FlexRow>
        </FormFieldWrapper>
        <FlexColumn>
          <Label pb={Spacing.Small}>{strings.selectedEmailsLabel}</Label>
          <SelectedEmailsWrapper>
            {surrogatesTo?.map((surrogateEmail, index) => (
              <SelectedEmail key={index} hAlign='space-between' pt={Spacing.Small} pb={Spacing.Small}>
                <EmailLabel>{surrogateEmail}</EmailLabel>
                <Button onClick={() => dispatch(removeSurrogate(surrogateEmail))} kind='link'>
                  <FaIcon.Close />
                </Button>
              </SelectedEmail>
            ))}
          </SelectedEmailsWrapper>
        </FlexColumn>
      </SurrogateBodyWrapper>
      <RemoveSurrogateModal setAction={setHasInivalidEmail} />
      <SurrogateInfoModal />
    </FlexColumn>
  );
};
