import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Container from 'typedi';
import { RENEW_TOKEN_INTERVAL_MILESECONDS, VERIFY_LOGOFF_MILESECONDS } from '@app/constants';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { initializeAndGetProfileThunk } from '@app/modules/auth/store/thunk/initialize-and-get-profile.thunk';
import { refreshAndUpdateTokensThunk } from '@app/modules/auth/store/thunk/refresh-and-update-azure-and-ssotoken.thunk';
import { isTokenExpired, redirect } from '@app/modules/auth/utils/utils';

interface IAuthorizationContainerProps {
  hasUserLoggedIn: boolean;
}
const authStorageService: AuthStorageService = Container.get(AuthStorageService);

export const AuthorizationContainer: React.FC<IAuthorizationContainerProps> = props => {
  const { token, userInfo, ssoAccess, azureToken, azureDateTimeExpiration } = useSelector(
    (state: RootState) => state.auth,
  );
  const ssoLogout = window.location.search.indexOf('ssoLogout=true') > -1;
  const dispatch = useDispatch<AppThunkDispatch>();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const codeParam = searchParams.get('code');
  const redirectUrl = searchParams.get('redirect');

  if (redirectUrl && !!token?.length) {
    redirect(redirectUrl);
  }

  useEffect(() => {
    const ssoLogoutLocalStorage = window.localStorage.getItem('ssoLogout');

    if (ssoLogout) {
      document.cookie = '@access_token=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/; Domain=.egerdau.com.br;';
      document.cookie = '@sso-access=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/; Domain=.egerdau.com.br;';
      authStorageService.ssoLogout();
    } else if (!redirectUrl && token && !userInfo?.oktaUid && !ssoLogout && ssoLogoutLocalStorage === 'false') {
      dispatch(initializeAndGetProfileThunk(token));
    }
  }, [userInfo]);

  useEffect(() => {
    if ((isTokenExpired(azureDateTimeExpiration) && !codeParam) || (!azureToken && !codeParam)) {
      dispatch(refreshAndUpdateTokensThunk(ssoAccess));
    }

    const interval = setInterval(() => {
      dispatch(refreshAndUpdateTokensThunk(ssoAccess));
    }, RENEW_TOKEN_INTERVAL_MILESECONDS);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const user = authStorageService.getUser();
      const isLoginPage = window.location.pathname.indexOf('/auth') === 0;
      const isForgotPasswordPage = window.location.pathname.indexOf('/forgot-password') === 0;

      if (!user && !isLoginPage && !isForgotPasswordPage) {
        authStorageService.logout();
      }
    }, VERIFY_LOGOFF_MILESECONDS);
    return () => clearInterval(interval);
  }, []);

  return <>{props.children}</>;
};
