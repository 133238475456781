import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';

export async function getUsersByProfileAllData(params): Promise<any> {
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: params.language };

  const path = params.profile === 'CLIENT' ? `bp=${params.bp}` : `profile=${params.profile}`;

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.USERS}?${path}`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    return { data, profile: params.profile };
  } else {
    return null;
  }
}

export const useUsersByProfilesAllData = (onSuccess?, onError?) => {
  return useMutation(getUsersByProfileAllData, {
    onError,
    onSuccess,
    retry: false,
  });
};
