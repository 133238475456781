import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import {
  DeliveryForecastItem,
  InStockOrderItem,
  SalesOrderItem,
  SalesOrderSummaryItem,
  StockRealDateItem,
} from '@app/models';
import { AuthStorageService } from '../storage/auth.storage';
import {
  BilledSalesOrderDto,
  DeliveryForecastDto,
  DeliveryForecastItemDto,
  InStockOrderDto,
  mapBilledOrder,
  mapDeliveryForecastItem,
  mapInStockOrder,
  mapOpenOrderSummary,
  mapStockRealDate,
  OpenedSalesOrderSummaryDto,
  StockRealDateDto,
} from './mi-order.dto';
import {
  BilledMiOrderParams,
  DeliveryForecastParams,
  OpenMiOrderParams,
  StockOrderParams,
  StockRealDateParams,
} from './order-params.dto';

const endpoint = {
  SALES_ORDER: '/sales-orders',
  SALES_ORDER_SUMMARY: '/sales-orders-online-sumary',
  ONLINE_BILLING: '/online-billing',
  INTERNAL_MARKET: '/mi',
  EXTERNAL_MARKET: '/me',
  OPENED: '/open',
  BILLED: '/billed',
  SHIPPED: '/shipped',
  FRONTIER: '/bordering',
  STOCK: '/stock/advanced',
  GKN: '/gkn',
  STOCK_REAL_DATE: '/stock/real-date',
  DELIVERY_FORECAST: '/delivery-forecast',
};

export const OrderDataSource = {
  getBilledOrder: (params: BilledMiOrderParams): Promise<SalesOrderItem[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.SALES_ORDER + endpoint.INTERNAL_MARKET + endpoint.BILLED)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: BilledSalesOrderDto): Promise<SalesOrderItem[]> => mapBilledOrder(res),
      })
      .execute();
  },
  getOpenOrderSummary: (params: OpenMiOrderParams): Promise<SalesOrderSummaryItem[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.INTERNAL_MARKET + endpoint.SALES_ORDER_SUMMARY)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: OpenedSalesOrderSummaryDto): Promise<SalesOrderSummaryItem[]> =>
          mapOpenOrderSummary(res),
      })
      .execute();
  },
  getStockOrder: (params: StockOrderParams, isGKN: boolean): Promise<InStockOrderItem[]> => {
    const user = Container.get(AuthStorageService).getUser();
    const complement = isGKN ? endpoint.GKN : '';

    return Container.get(HttpRequestBuilder)
      .get(endpoint.STOCK + complement)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: InStockOrderDto): Promise<InStockOrderItem[]> => mapInStockOrder(res),
      })
      .execute();
  },
  getStockRealDate: (params: StockRealDateParams): Promise<StockRealDateItem[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.STOCK_REAL_DATE)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: StockRealDateDto): Promise<StockRealDateItem[]> => mapStockRealDate(res),
      })
      .execute();
  },
  getDeliveryForecast: (params: DeliveryForecastParams): Promise<DeliveryForecastItem[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.INTERNAL_MARKET + endpoint.SALES_ORDER_SUMMARY + endpoint.DELIVERY_FORECAST)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: DeliveryForecastDto): Promise<DeliveryForecastItemDto[]> =>
          mapDeliveryForecastItem(res),
      })
      .execute();
  },
};
