export const GKN_BPS = ['0100244048', '0100243679'];

export const THYSSENKRUPP_BPS = ['0100014854', '0100242516', '0101057709'];

export const REDUX_DATE_ERROR_MESSAGE =
  'Redux does not save objects, just primitive values, you must save date as number using date timestamp';

export const RENEW_TOKEN_INTERVAL_MILESECONDS = 1800000;
export const VERIFY_LOGOFF_MILESECONDS = 2000;

export const TEST_ID_CLOSE_MODAL = 'close-modal-button';
