import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { TrackingInformations } from '@app/models/truck-tracking.model';
import { AuthStorageService } from '../storage/auth.storage';
import { GetTrackingInformationResponse, mapTrackingInformation } from './truck-tracking.dto';

export interface TruckTrackingDataSourceParams {
  clientRequest: string;
  plant: string;
  concatenatedBillingDocument: string;
  customerIds: string;
}

export interface TruckTrackingMapParams {
  destLat: string;
  destLong: string;
  truckLat: string;
  truckLong: string;
  originLat: string;
  originLong: string;
}

const endpoint = {
  TRACKING: '/truck-tracking',
  TRUCK_TRACKING_MAP: '/trucktrackmaps',
};

export const TruckTrackingDataSource = {
  getTrackingInformation: (params: TruckTrackingDataSourceParams): Promise<TrackingInformations> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.TRACKING}`)
      .params({
        customerIds: params.customerIds,
        language: 'P',
        concatenatedBillingDocument: params.concatenatedBillingDocument,
        plant: params.plant,
        customerOrder: params.clientRequest,
      })
      .headers({
        Accept: '*/*',
        'Sso-Access': user.ssoAccess,
      })
      .interceptors({
        afterSuccess: async (res: GetTrackingInformationResponse): Promise<TrackingInformations> =>
          mapTrackingInformation(res),
      })
      .execute();
  },
  getTruckTrackingMap: (params: TruckTrackingMapParams): Promise<File> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(
        `${endpoint.TRUCK_TRACKING_MAP}/${params.destLat}/${params.destLong}/${params.truckLat}/${params.truckLong}/${params.originLat}/${params.originLong}`,
      )
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .execute();
  },
};
