import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LogAnalytics } from '@app/core/analytics';
import { RootState } from '@app/core/redux/store';
import { isGKNSelector } from '../components/customer-search/store/customer-search.selectors';
import { ShippingRequestEvents } from '../order/order.analytics';
import { AlertButtonModal } from './partials/alert-button-modal/alert-button-modal.component';
import { ShippingRequestBody } from './partials/shipping-request-body/shipping-request-body.component';
import { ShippingRequestFooter } from './partials/shipping-request-footer/shipping-request-footer.component';
import { ShippingRequestHeader } from './partials/shipping-request-header/shipping-request-header.component';
import { ShippingRequestReceiverModal } from './partials/shipping-request-receiver-modal/shipping-request-receiver-modal.component';
import { ShippingRequestSuggestionModal } from './partials/shipping-request-suggestion-modal/shipping-request-suggestion-modal.component';
import { ShippingRequestWrapper } from './shipping-request.style';
import { setTableRows } from './store/shipping-request.store';

export const ShippingRequestPage: React.FC = () => {
  const { selectedRows } = useSelector((state: RootState) => state.orderTabStock);
  const { openReceiverModalIndex, openShippingRequestSuggestionModalMaterial } = useSelector(
    (state: RootState) => state.shippingRequest,
  );
  const isGKN = useSelector(isGKNSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    LogAnalytics.pageView(ShippingRequestEvents.ShippingRequestView);
  }, []);

  useEffect(() => {
    if (selectedRows.length) {
      dispatch(setTableRows({ selectedRows, isGKN }));
    }
  }, [selectedRows]);

  return (
    <ShippingRequestWrapper>
      <ShippingRequestHeader />
      <ShippingRequestBody />
      <ShippingRequestFooter />
      {openReceiverModalIndex !== null && <ShippingRequestReceiverModal />}
      {openShippingRequestSuggestionModalMaterial !== null && <ShippingRequestSuggestionModal />}
      <AlertButtonModal />
    </ShippingRequestWrapper>
  );
};
