import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';

export async function getGetDocumentList(params) {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: user.language };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${
    endpoint.DOCUMENTS
  }?sort=asc&order=title&take=100&skip=0&documentType=${params.documentType}`;

  const { data, status } = await axios.get(url, { headers: defaultHeaders });

  if (status === 200) {
    return data;
  } else {
    return [];
  }
}

export function useGetDocumentList(params?, onSuccess?, onError?) {
  return useQuery(['getDocumentList', params], () => getGetDocumentList(params), {
    onSuccess,
    onError,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
