import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { ConfigColumnsModel } from '@app/models';
import { AuthStorageService } from '../storage/auth.storage';
import { ConfigColumnsDTO, mapConfigColumnsDTOToModel, mapConfigColumnsModelToDTO } from './config-columns.dto';

const endpoint = {
  ORDERINPUT: 'mi/implantation-request',
  LAYOUT: '/layout',
};

export const ConfigColumnsDatasource = {
  getConfigColumns: (customerIds: string) => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.ORDERINPUT + endpoint.LAYOUT)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params({ identifier: user.uid, customerIds: customerIds })
      .interceptors({
        afterSuccess: async (res: ConfigColumnsDTO): Promise<ConfigColumnsModel> => mapConfigColumnsDTOToModel(res),
      })
      .execute();
  },
  postConfigColumns: (params: ConfigColumnsModel): Promise<any> => {
    const user = Container.get(AuthStorageService).getUser();
    const columnsDTO = mapConfigColumnsModelToDTO(params);

    return Container.get(HttpRequestBuilder)
      .post(endpoint.ORDERINPUT + endpoint.LAYOUT + '?customerIds=' + params.customerIds)
      .headers({
        'Sso-Access': user.ssoAccess,
        Accept: '*/*',
      })
      .data(columnsDTO)
      .execute();
  },
};
