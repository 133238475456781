import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { DeleteAssetsParams } from '@app/data/http/quote-assets-params.dto';

export const deleteAssets = async (params?: DeleteAssetsParams) => {
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: params.language };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_ASSETS
  }/${params.assetKey}`;

  await axios.delete(url, { headers: defaultHeaders });
};

export const useDeleteAssets = onSuccess => {
  return useMutation(deleteAssets, {
    onSuccess,
  });
};
