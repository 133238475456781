import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { PatchAssetsParams } from '@app/data/http/quote-assets-params.dto';

export const editAssets = async (params?: PatchAssetsParams) => {
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: params.language };

  const data = { client_visible: params.isClientVisible };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}/${params.analysisId}${
    endpoint.ANALYSIS_ASSETS
  }/${params.assetKey}`;

  await axios.patch(url, data, { headers: defaultHeaders });
};

export const useEditAssets = onSuccess => {
  return useMutation(editAssets, {
    onSuccess,
  });
};
