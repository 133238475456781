import React from 'react';
import { useSelector } from 'react-redux';
import * as Scroll from 'react-scroll';
import { RootState } from '@app/core/redux/store';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { Body, ButtonText, H3 } from '@atomic/atm.typography';
import { DefaultListCell } from '@atomic/mol.default-list-cell';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { FormContext } from '../../form.context';
import { FormStrings } from '../../form.string';
import { Field, Step } from '../form-flow-fields.container';
import { Attribute } from './form-result-attribute.component';
import { CompositionTable } from './form-result-composition.component';
import { FileNorm } from './form-result-file.component';
import { JominyTable } from './form-result-jominy.component';
import { MacroataqueTable } from './form-result-macroataque.component';
import { MechanicalTable } from './form-result-mechanical.component';
import { MicroinclusaoTable } from './form-result-microinclusao.component';
import { FormDrawerStyled, FormResultLabelStyled, FormResultWrapperStyled } from './form-result.component.style';

export interface FormResultItems {
  key: string;
  label: string;
  value: any;
  creation?: { origin: boolean };
  visible?: boolean;
  type?: string;
  file?: File[];
}
interface FormResultProps {
  initialized?: boolean;
  resetChat: () => void;
}

export const FormResult: React.FC<FormResultProps> = props => {
  const { formResult } = React.useContext(FormContext);

  const mapChatResult = chatR => {
    const result = [];

    const steps = Object.entries(chatR);

    steps.forEach((item: [string, { key: string; questions: Step }]) => {
      result.push({
        key: item[1].key,
        fields: item[1]?.questions?.fields ? Object?.entries(item[1]?.questions.fields) : null,
      });
    });
    return result;
  };

  const result = formResult && mapChatResult(formResult);

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = FormStrings[userInfo.language].resumeAnalysis;

  const handleAnalysisReset = () => {
    props.resetChat();
  };

  return (
    <FormResultWrapperStyled initialized={props.initialized}>
      <DefaultListCell>
        <Hbox>
          <Hbox.Item>
            <H3>{strings.resume}</H3>
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item vAlign='center'>
            <ButtonText onClick={handleAnalysisReset}>{strings.reset}</ButtonText>
          </Hbox.Item>
        </Hbox>
      </DefaultListCell>
      <FormResultLabelStyled>
        <DefaultListCell>
          <Hbox noGrow>
            <Hbox.Item>
              <Body>{QuoteStrings[userInfo.language].defaultMessageGerdau.chatResult}</Body>
            </Hbox.Item>
          </Hbox>
        </DefaultListCell>
      </FormResultLabelStyled>
      <FormDrawerStyled id='result-analysis'>
        <Scroll.Element>
          {result
            ? result.map(step =>
                step?.fields
                  ? step.fields.map((item: [string, Field]) => callAttributeComponents(item[0], item[1], step.key))
                  : null,
              )
            : null}
        </Scroll.Element>
        <VSeparator />
      </FormDrawerStyled>
    </FormResultWrapperStyled>
  );
};

function callAttributeComponents(key: string, attribute: any, stepKey: string) {
  if (key === 'SPECIFIC_SPECIFICATION_NAME') {
    return (
      <Hbox.Item key={key}>
        <FileNorm stepKey={stepKey} attrKey={key} attribute={attribute} />
      </Hbox.Item>
    );
  } else if (key === 'CHEMICAL_COMPOSITION') {
    return (
      attribute?.value?.length > 0 && (
        <Hbox.Item key={key}>
          <CompositionTable stepKey={stepKey} attrKey={key} attribute={attribute} />
        </Hbox.Item>
      )
    );
  } else if (key === 'MICRO_INCLUSION') {
    return (
      attribute?.value?.length > 0 && (
        <Hbox.Item key={key}>
          <MicroinclusaoTable stepKey={stepKey} attrKey={key} attribute={attribute} />
        </Hbox.Item>
      )
    );
  } else if (key === 'HARDENABILITY') {
    return (
      attribute?.value?.length > 0 && (
        <Hbox.Item key={key}>
          <JominyTable stepKey={stepKey} attrKey={key} attribute={attribute} />
        </Hbox.Item>
      )
    );
  } else if (key === 'MACRO_ETCH') {
    return (
      attribute?.value?.length > 0 && (
        <Hbox.Item key={key}>
          <MacroataqueTable stepKey={stepKey} attrKey={key} attribute={attribute} />
        </Hbox.Item>
      )
    );
  } else if (key === 'STRETCH' || key === 'AREA_SHRINK' || key === 'YIELD_LIMIT' || key === 'LOAD_LIMIT') {
    return (
      attribute?.value && (
        <Hbox.Item key={key}>
          <MechanicalTable stepKey={stepKey} attrKey={key} attribute={attribute} />
        </Hbox.Item>
      )
    );
  } else {
    return (
      (attribute?.value?.length > 0 || attribute?.value?.value) && (
        <Hbox.Item key={key}>
          <Attribute stepKey={stepKey} attrKey={key} attribute={attribute} />
        </Hbox.Item>
      )
    );
  }
}
