import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { SurrogateDataSource } from '@app/data/http/surrogate.datasource';
import { IAuthState } from '@app/modules/auth/auth.interfaces';
import { SurrogateEvents } from '../../surrogate.analytics';
import { SLICE_NAME } from '../../surrogate.constants';
import { ISurrogateState } from '../../surrogate.interfaces';
import { ISurrogateStrings, Strings } from '../../surrogate.strings';

export const postSurrogate = createAsyncThunk(`${SLICE_NAME}/postSurrogate`, async (_, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const { userInfo }: IAuthState = state.auth;
  const strings: ISurrogateStrings = Strings[userInfo.language];
  const surrogateState: any = thunkAPI.getState();
  const { surrogatesTo, isIndeterminateTime, expiresFrom, expiresTo }: ISurrogateState = surrogateState.surrogate;

  return await SurrogateDataSource.postSurrogate({
    from: userInfo.email,
    surrogatesTo: surrogatesTo.join(),
    isIndeterminateTime,
    expiresFrom,
    expiresTo,
  })
    .then(resp => {
      LogAnalytics.click({ tipo: SurrogateEvents.PostSurrogateSuccess });
      return { data: resp.data, message: strings.success };
    })
    .catch(err => {
      LogAnalytics.error({
        tipo: SurrogateEvents.PostSurrogateError,
        errorMessage: err.response.message,
        errorDetails: JSON.stringify(err.response.data),
      });
      return thunkAPI.rejectWithValue({ status: err.response.status, message: err.response.data?.errors[0]?.message });
    });
});

export const addPostSurrogateReducer = builder => {
  builder.addCase(postSurrogate.pending, state => {
    state.loading = true;
  });
  builder.addCase(postSurrogate.fulfilled, (state: ISurrogateState, action) => {
    state._id = action.payload.data._id;
    state.from = action.payload.data.from;
    state.loading = false;
    state.successMessage = action.payload.message;
    state.error = false;
    state.errorMessage = '';
  });
  builder.addCase(postSurrogate.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload.status;
    state.errorMessage = action.payload.message;
  });
};
