import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { EnhancedError } from '@app/core/hook/request.model';
import { HttpRequestBuilder } from '@app/core/http';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { mapFilter, mapFilterParams } from '@app/data/http/quote-filter.dto';
import { mapAnalysis } from '@app/data/http/quote.dto';
import { AnalysisParams } from '@app/models/analysis.model';
import { handleUnauthorized } from '@app/utils/http-utils';

export interface LazyRequestHook<Params, Response> {
  loading: boolean;
  error: EnhancedError;
  data: Response;
  performRequest: (params?: Params) => void;
  isLoading?: boolean;
  isFetching?: boolean;
}

export async function getAnalysis(params?: AnalysisParams) {
  const allParams = `${mapFilterParams(params.filtersParams)}&count=true`;

  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: params.language };

  const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}${
    endpoint.ANALYSIS_LIST
  }?${allParams}`;

  if (params.filters === null) {
    try {
      const { data, status, headers } = await axios.get(url, { headers: defaultHeaders });
      if (status === 200) {
        return mapAnalysis(data, headers.paginationcount, params.language);
      } else {
        return [];
      }
    } catch (error) {
      handleUnauthorized(error);
      return [];
    }
  } else {
    try {
      const payload = {
        userType: params.quoteIsInternalUser ? 'GERDAU' : 'CLIENT',
        filter: mapFilter(params.filters),
      };

      const { data, status, headers } = await axios.post(url, payload, { headers: defaultHeaders });
      if (status === 200) {
        return mapAnalysis(data, headers.paginationcount, params.language);
      } else {
        return [];
      }
    } catch (error) {
      handleUnauthorized(error);
      return [];
    }
  }
}

export function useAnalysis(params?: any, onError?) {
  return useQuery(['getAnalysis', params], () => getAnalysis(params), {
    onError,
    enabled: params.enabled,
    refetchOnWindowFocus: false,
  });
}
