import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { QuoteStrings } from '@app/modules/quote/quote.string';
import { H4, InputLabel } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { VSeparator } from '@atomic/obj.grid';
import { rangeLimits } from '../../form.contants';
import { FormResultEditOptions } from './form-result-edit-options';
import { FormResultAttributeWrapper, FormResultOptionsWrapper } from './form-result.component.style';

interface JominyItems {
  hardenabilityUnit: number;
  points: string;
  unit: string;
  value: {
    min: any;
    max: any;
  };
}
interface JominyTableProps {
  attrKey: string;
  stepKey: string;
  attribute: {
    display: boolean;
    key: string;
    label?: string;
    mandatory?: boolean;
    permissions?: {
      visible: boolean;
      editable: boolean;
    };
    type: string;
    creation: string;
    value: JominyItems[];
  };
}

export const JominyTable: React.FC<JominyTableProps> = props => {
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const formatStringValue = (val: string | number, range: string) => {
    if (val) {
      if (range === 'max') {
        const newMask = val.toString().replace(rangeLimits.maxString, rangeLimits.dash);
        return newMask;
      } else {
        if (val === rangeLimits.min) {
          const newMask = val.toString().replace(rangeLimits.minString, rangeLimits.dash);
          return newMask;
        }
        return val;
      }
    } else {
      return rangeLimits.dash;
    }
  };

  const orderData = (data: JominyItems[]) => {
    return data.sort((a: JominyItems, b: JominyItems) => (+a.points < +b.points ? -1 : +a.points > +b.points ? 1 : 0));
  };

  return props.attribute?.permissions.visible ? (
    <FormResultAttributeWrapper
      data-testid={`analysisResume${props.attrKey}`}
      modified={props.attribute?.creation === 'NLU'}
    >
      <Hbox.Item>
        <FormResultOptionsWrapper>
          <InputLabel>
            {props.attribute?.label}{' '}
            {props.attribute?.creation === 'NLU' && (
              <span>{` ${QuoteStrings[userInfo.language].defaultMessageGerdau.chatResultNormAdvice}`}</span>
            )}
          </InputLabel>
          <FormResultEditOptions
            stepKey={props.stepKey}
            editable={props.attribute.permissions.editable}
            attrKey={props.attrKey}
            type={props.attribute.type}
          />
        </FormResultOptionsWrapper>
      </Hbox.Item>
      <Table>
        <TR>
          <TD>
            <H4>{props.attribute.value[0].unit}</H4>
          </TD>
          <TD>
            <H4>Min</H4>
          </TD>
          <TD>
            <H4>Max</H4>
          </TD>
          <TD></TD>
        </TR>
        {orderData(props.attribute.value).map(item => (
          <TR key={item.points}>
            <TD>
              <H4>{item.points}</H4>
            </TD>
            <TD>{formatStringValue(item.value.min, 'min')}</TD>
            <TD>{formatStringValue(item.value.max, 'max')}</TD>
            <TD>{item.hardenabilityUnit}</TD>
          </TR>
        ))}
      </Table>
      <VSeparator />
    </FormResultAttributeWrapper>
  ) : null;
};
