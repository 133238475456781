import React from 'react';
import { Badge } from '@atomic/atm.badge';
import { FaIcon } from '@atomic/atm.fa-icon';
import { H2, H3 } from '@atomic/atm.typography';
import { Hbox } from '@atomic/obj.box';
import { Color } from '@atomic/obj.constants';
import { FormContext } from '../form.context';
import { Field } from './form-flow-fields.container';
import { IconWrapper, SummaryWrapper } from './result/form-result.component.style';

export interface FormSummaryProps {
  stepIndex: number;
  stepKey: string;
  stepLabel: string;
  stepQuestion: string;
  steps?: Field[];
}

export function calcMandatory(steps) {
  let totalMandatory = 0;
  let totalMandatoryLeft = 0;
  steps.forEach((field: Field) => {
    if (field.mandatory && field.display && !field.value) {
      totalMandatoryLeft++;
    }
    if (field.mandatory && field.display) {
      totalMandatory++;
    }
  });
  const prevValue = (100 - (totalMandatoryLeft * 100) / totalMandatory).toFixed(0);

  if (isNaN(+prevValue)) {
    return 0;
  } else {
    return +prevValue;
  }
}

export const FormSummary = (props: FormSummaryProps) => {
  const { scrollTo } = React.useContext(FormContext);

  return props?.steps ? (
    <SummaryWrapper bg={true} onClick={() => scrollTo(props.stepKey, null)}>
      <H3>Etapa {props.stepKey === 'CLIENTS' ? 1 : props.stepIndex + 1}</H3>
      <Hbox hAlign='space-between' vAlign='center'>
        <Hbox>
          <H2 bold={700}>
            {props.stepLabel}:&nbsp;<span>{props.stepQuestion}</span>
          </H2>
          {props.stepKey !== 'ADITIONAL' && (
            <Badge color={calcMandatory(props.steps) === 100 ? 'success' : 'orange'}>{`${
              calcMandatory(props.steps) === 100
                ? 'Preenchido ' + calcMandatory(props.steps)
                : 'Em edição ' + calcMandatory(props.steps)
            }%`}</Badge>
          )}
        </Hbox>

        <IconWrapper rotate={0} onClick={() => scrollTo(props.stepKey, null)}>
          <FaIcon.ChevronDown color={Color.Accessory} />
        </IconWrapper>
      </Hbox>
    </SummaryWrapper>
  ) : null;
};
