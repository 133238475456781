import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { LoginDataSource } from '@app/data/http/login.datasource';
import { ICustomerSearch } from '@app/models';
import { Language, UserInfo } from '@app/models/login.model';
import { setBpOptions } from '@app/modules/admin/store/admin.store';
import { setCurrentMarket } from '@app/modules/components/customer-search/store/customer-persist.store';
import { setClientBpSearch } from '@app/modules/components/customer-search/store/customer-search.store';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { MarketType } from '@app/providers';
import { handleUnauthorized } from '@app/utils/http-utils';
import { authErrorMessageMap } from '../../auth-error.mapper';
import { AuthEvents } from '../../auth.analytics';
import { SLICE_NAME } from '../../auth.constants';
import { IAuthState } from '../../auth.interfaces';

interface GetProfileParams {
  token: string;
  ssoAccess: boolean;
}

export const getProfile = createAsyncThunk(`${SLICE_NAME}/getProfile`, async (params: GetProfileParams, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const { isActive }: IAuthState = state.auth;

  if (!isActive) {
    return thunkAPI.rejectWithValue({});
  }

  return await LoginDataSource.profile(params.ssoAccess)
    .then(resp => {
      const bpsOptions = [];
      const bpsSearch: ICustomerSearch[] = [];

      resp.data.bps.forEach(bp => {
        bpsOptions.push({ code: bp.customerId, name: bp.customerName, id: bp._id, selected: false });
        bpsSearch.push({
          _id: bp._id,
          customerId: bp.customerId,
          name: bp.customerName,
          corpGroup: bp.corporateGroup,
          corpGroupName: bp.corporateGroupName,
          cnpj: bp.cnpj.toString(),
          address: `${bp.streetName}, ${bp.houseNumber}`,
          district: bp.district,
          city: bp.city,
          state: bp.state,
          country: bp.country,
          bpType: bp.bptype,
          market: bp.market === 'MI' ? 'internal' : 'external',
          salesOrg: bp.salesOrganization,
          salesGroup: bp.salesGroup,
          numberGroupRentability: bp.corporateGroup,
          nameGroupRentability: bp.corporateGroupName,
        });
      });

      thunkAPI.dispatch(setBpOptions(bpsOptions));
      thunkAPI.dispatch(setClientBpSearch(bpsSearch));

      if (resp?.data?.bps[0]?.market === MarketType.External) {
        thunkAPI.dispatch(setCurrentMarket(MarketType.External));
      } else {
        thunkAPI.dispatch(setCurrentMarket(MarketType.Internal));
      }

      return { ...resp.data, bps: resp.data.bps, bpList: bpsSearch };
    })
    .catch(err => {
      LogAnalytics.error({
        tipo: AuthEvents.ProfileError,
        message: authErrorMessageMap[parseInt(err.response.status)],
        errorMessage: err.response.message,
        errorDetails: JSON.stringify(err.response.data),
      });
      handleUnauthorized(err);
      thunkAPI.dispatch(
        setToast({
          show: true,
          text: authErrorMessageMap[parseInt(err.response.status)],
          variant: ToastVariant.DANGER,
        }),
      );
      return thunkAPI.rejectWithValue({ status: err.response.status });
    });
});

export const addGetProfileReducers = builder => {
  builder.addCase(getProfile.pending, state => {
    state.loadingProfile = true;
  });
  builder.addCase(getProfile.fulfilled, (state, action) => {
    const payload: UserInfo = action.payload as UserInfo;
    payload.language = Language[payload.language];
    state.userInfo = payload;
    state.loadingProfile = false;
  });
  builder.addCase(getProfile.rejected, state => {
    state.loading = false;
    state.loadingValidateToken = false;
    state.loadingProfile = false;
  });
};
