import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { format } from 'date-fns';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { NewChatCreateAnalysisParams } from '@app/data/http/quote-params.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';

interface Params {
  client: {
    name: string;
    code: string;
    corporateCode: string;
    dateQuote: string;
  };
  standard: {
    type: string;
    name: string;
    date: string;
    revision: string;
  };
  product: {
    type: string[];
  };
  conversationId?: string;
}

export async function postCreateAnalysis(params?: NewChatCreateAnalysisParams): Promise<Params> {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: user.language };

  let url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.STANDARD_REVIEW}`;
  let dataPost;

  if (params.conversationId) {
    url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.STANDARD_REVIEW}${
      endpoint.STANDARD_REVIEW_NLU_CREATE
    }`;
    dataPost = {
      client: {
        name: params.data.CLIENT.value,
        code: params.data.CLIENT_CODE.value,
        corporateCode: params.data.CORPORATE_CODE.value,
        dateQuote: params.data.WHEN_CLIENT_DATA.value,
      },
      standard: {
        type: params.data.STANDARD.value.key,
        name: params.data.SPECIFIC_SPECIFICATION.value,
        date: format(new Date(params.data.SPECIFICATION_DATE.value), 'yyyy-MM-dd'),
        revision: params.data.SPECIFICATION_REVISION.value,
      },
      product: {
        type: params.data.PRODUCT.value,
      },
      conversationId: params.conversationId,
    };
    if (params?.data?.STEEL_NAME) {
      dataPost.product.steelName = params.data.STEEL_NAME.value;
    }
  } else {
    dataPost = {
      client: {
        name: params.data.CLIENT.value,
        code: params.data.CLIENT_CODE.value,
        corporateCode: params.data.CORPORATE_CODE.value,
        dateQuote: params.data.WHEN_CLIENT_DATA.value,
      },
      standard: {
        type: params.data.STANDARD.value.key,
        name: params.data.SPECIFIC_SPECIFICATION.value,
        date: format(new Date(params.data.SPECIFICATION_DATE.value), 'yyyy-MM-dd'),
        revision: params.data.SPECIFICATION_REVISION.value,
      },
      product: {
        type: params.data.PRODUCT.value,
      },
    };
  }

  const { data } = await axios.post(url, dataPost, { headers: defaultHeaders });

  if (data.success) {
    return data;
  } else {
    return null;
  }
}

export function useCreateAnalysis(params: NewChatCreateAnalysisParams, onSuccess?, onError?) {
  return useQuery(['postCreateAnalysis', params], () => postCreateAnalysis(params), {
    onSuccess,
    onError,
    enabled: false,
    refetchOnWindowFocus: false,
    retry: false,
  });
}
