import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { LoginDataSource } from '@app/data/http/login.datasource';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { authErrorMessageMap } from '../../auth-error.mapper';
import { AuthEvents } from '../../auth.analytics';
import { SLICE_NAME } from '../../auth.constants';

export const refreshTokenRequest = createAsyncThunk(`${SLICE_NAME}/refreshToken`, async (_, thunkAPI) => {
  const state: any = thunkAPI.getState();
  const refreshToken = state.auth.refreshToken;
  const authorizationToken = state.auth.azureToken;
  return LoginDataSource.refreshToken({ token: authorizationToken, refreshToken: refreshToken })
    .then(resp => {
      return resp;
    })
    .catch(err => {
      LogAnalytics.error({
        tipo: AuthEvents.RefreshTokenError,
        message: authErrorMessageMap[parseInt(err.response.status)],
        errorMessage: err.response.message,
        errorDetails: JSON.stringify(err.response.data),
      });
      thunkAPI.dispatch(
        setToast({
          show: true,
          text: authErrorMessageMap[parseInt(err.response.status)],
          variant: ToastVariant.DANGER,
        }),
      );
      return thunkAPI.rejectWithValue({ status: err.response.status });
    });
});

export const addRefreshTokenReducers = builder => {
  builder.addCase(refreshTokenRequest.pending, state => {
    state.loading = true;
  });
  builder.addCase(refreshTokenRequest.fulfilled, (state, action) => {
    state.token = action.payload.access_token;
    state.tokenType = action.payload.token_type;
    state.tokenExpiresIn = action.payload.expires_in;
    state.refreshToken = action.payload.refresh_token;
    state.ssoAccess = true;
    state.loading = false;
    state.isActive = true;
  });
  builder.addCase(refreshTokenRequest.rejected, state => {
    state.loading = false;
  });
};
