import React from 'react';
import { Button } from '@atomic/atm.button';
import { FaIcon } from '@atomic/atm.fa-icon';
import { Hbox } from '@atomic/obj.box';
import { FormContext } from '../../form.context';

interface ChatOptionsProps {
  attrKey: string;
  stepKey: string;
  editable: boolean;
  type: string;
}

export const FormResultEditOptions = (props: ChatOptionsProps) => {
  const { formResult, setFieldValue, setFormResult, scrollTo, openModals } = React.useContext(FormContext);

  const handleEditAttribute = () => {
    switch (props.attrKey) {
      case 'CHEMICAL_COMPOSITION':
      case 'MICRO_INCLUSION':
      case 'HARDENABILITY':
      case 'MACRO_ETCH':
        openModals(props.attrKey);
        break;
      case 'STRETCH':
      case 'AREA_SHRINK':
      case 'YIELD_LIMIT':
      case 'LOAD_LIMIT':
        openModals('MECHANICAL_PROPERTIES');
        break;
    }

    scrollTo(props.attrKey, props.stepKey);
  };

  const handleDeleteAttribute = () => {
    if (props.attrKey === 'CLIENT') {
      delete formResult[props.stepKey].questions.fields.CLIENT.value;
      delete formResult[props.stepKey].questions.fields.CLIENT_CODE.value;
      delete formResult[props.stepKey].questions.fields.CORPORATE_CODE.value;

      setFieldValue('');

      setFormResult(prevValue => ({
        ...prevValue,
        [props.stepKey]: {
          ...prevValue[props.stepKey],
          questions: {
            ...prevValue[props.stepKey].questions,
            fields: {
              ...prevValue[props.stepKey].questions.fields,
              CLIENT: {
                ...prevValue[props.stepKey].questions.fields.CLIENT,
              },
              CLIENT_CODE: {
                ...prevValue[props.stepKey].questions.fields.CLIENT_CODE,
              },
              CORPORATE_CODE: {
                ...prevValue[props.stepKey].questions.fields.CORPORATE_CODE,
              },
            },
          },
        },
      }));
    } else {
      if (props.type === 'table') {
        //delete formResult[props.stepKey].questions.fields[props.attrKey];
        setFormResult(prevValue => ({
          ...prevValue,
          [props.stepKey]: {
            ...prevValue[props.stepKey],
            questions: {
              ...prevValue[props.stepKey].questions,
              fields: {
                ...prevValue[props.stepKey].questions.fields,
                [props.attrKey]: {
                  ...prevValue[props.stepKey].questions.fields[props.attrKey],
                  value: null,
                },
              },
            },
          },
        }));
      } else {
        //delete formResult[props.stepKey].questions.fields[props.attrKey].value;
        setFormResult(prevValue => ({
          ...prevValue,
          [props.stepKey]: {
            ...prevValue[props.stepKey],
            questions: {
              ...prevValue[props.stepKey].questions,
              fields: {
                ...prevValue[props.stepKey].questions.fields,
                [props.attrKey]: {
                  ...prevValue[props.stepKey].questions.fields[props.attrKey],
                  value: null,
                  display:
                    props.stepKey === 'ADDITIONAL'
                      ? false
                      : prevValue[props.stepKey].questions.fields[props.attrKey].display,
                },
              },
            },
          },
        }));
      }
    }

    scrollTo(props.attrKey, props.stepKey);
  };

  return (
    <Hbox>
      {props.editable ? (
        <>
          <Button kind='link' onClick={handleEditAttribute}>
            <FaIcon.Pen />
          </Button>
          <Button kind='link' onClick={handleDeleteAttribute}>
            <FaIcon.Trash />
          </Button>
        </>
      ) : (
        <Hbox></Hbox>
      )}
    </Hbox>
  );
};
