import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { AuthStorageService } from '../storage/auth.storage';
import { DeleteCriticalItemsParams, GetCriticalItemRulesParams, PostCriticalItemsParams } from './order-params.dto';
import {
  CriticalItemDto,
  CriticalItemsResponse,
  mapCriticalItems,
  mapOrderPriorizationRule,
  OrderPriorizationRuleItem,
  OrderPriorizationRuleItemDto,
} from './order-priorization.dto';

const endpoint = {
  INTERNAL_MARKET: '/mi',
  SALES_ORDER_SUMMARY: '/sales-orders-online-sumary',
  CRITICAL_ITEMS: '/critical-items',
  RULES: '/rules',
};

export const OrderPriorizationDataSource = {
  postCriticalItems: (params: PostCriticalItemsParams): Promise<void> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .post(
        endpoint.INTERNAL_MARKET +
          endpoint.SALES_ORDER_SUMMARY +
          endpoint.CRITICAL_ITEMS +
          '?customerIds=' +
          params.emissionCode,
      )
      .headers({
        'Content-Type': 'application/json',
        'Sso-Access': user.ssoAccess,
      })
      .data(params)
      .execute();
  },

  getCriticalItemRules: (params: GetCriticalItemRulesParams): Promise<OrderPriorizationRuleItemDto> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.INTERNAL_MARKET + endpoint.SALES_ORDER_SUMMARY + endpoint.CRITICAL_ITEMS + endpoint.RULES)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: OrderPriorizationRuleItem): Promise<OrderPriorizationRuleItemDto> =>
          mapOrderPriorizationRule(res),
      })
      .execute();
  },

  getCriticalItems: (params: GetCriticalItemRulesParams): Promise<OrderPriorizationRuleItemDto> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.INTERNAL_MARKET + endpoint.SALES_ORDER_SUMMARY + endpoint.CRITICAL_ITEMS)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: CriticalItemsResponse): Promise<CriticalItemDto[]> => mapCriticalItems(res),
      })
      .execute();
  },

  deleteCriticalItems: (params: DeleteCriticalItemsParams): Promise<void> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .delete(
        endpoint.INTERNAL_MARKET +
          endpoint.SALES_ORDER_SUMMARY +
          endpoint.CRITICAL_ITEMS +
          '?customerIds=' +
          params.emissionCode,
      )
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .data(params)
      .execute();
  },
};
