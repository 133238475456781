import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { AuthStorageService } from '@app/data/storage/auth.storage';

interface ParamsDelete {
  id: string;
}

export const deleteDocument = async (params?: ParamsDelete) => {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: user.language };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.DOCUMENTS}/${params.id}`;

  const { data: dataResponse } = await axios.delete(url, { headers: defaultHeaders });

  return dataResponse;
};

export const useDeleteDocument = (onSuccess?, onError?) => {
  const queryClient = useQueryClient();
  return useMutation(deleteDocument, {
    onSuccess,
    onError,
    onSettled: () => {
      queryClient.invalidateQueries(['getDocumentList']);
    },
  });
};
