import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Container from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { CreateAnalysisParams } from '@app/data/http/quote-params.dto';

export async function postCreateAnalysis(params?: CreateAnalysisParams): Promise<any> {
  const defaultHeaders = { ...Container.get(IsaHttpRequestBuilder).config.headers, language: params.language };

  const url = !params.chatCreateV2
    ? `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}`
    : `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.ANALYSIS}-v2`;

  const dataPost = {
    conversation_id: params.conversationId,
  };

  const { data, status } = await axios.post(url, dataPost, { headers: defaultHeaders });

  if (status === 200) {
    return data;
  } else {
    return null;
  }
}

export function useCreateAnalysis(params: CreateAnalysisParams, onSuccess?, onError?) {
  return useQuery(['postCreateAnalysis', params], () => postCreateAnalysis(params), {
    onSuccess,
    onError,
    enabled: false,
    refetchOnWindowFocus: false,
  });
}
