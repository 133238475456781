import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { GetSurrogateParams, PostSurrogateParams, PutSurrogateParams, Surrogate } from '@app/models/surrogate.model';
import { AuthStorageService } from '../storage/auth.storage';
import { mapSurrogate, SurrogateDto } from './surrogate.dto';

const endpoint = {
  SURROGATE: '/surrogate',
  UPDATE_SURROGATE: '/surrogate/actions',
  IS_ABLE: '/surrogate/is-able',
};

export const SurrogateDataSource = {
  getSurrogate: (params: GetSurrogateParams): Promise<Surrogate> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.SURROGATE}/${params.email}`)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .interceptors({
        afterSuccess: async (res: SurrogateDto): Promise<Surrogate> => mapSurrogate(res),
      })
      .execute();
  },

  postSurrogate: (params: PostSurrogateParams): Promise<any> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .post(endpoint.SURROGATE)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .data(params)
      .execute();
  },

  putSurrogate: (params: PutSurrogateParams): Promise<any> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .put(`${endpoint.UPDATE_SURROGATE}/${params._id}`)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .data(params.postSurrogateParams)
      .execute();
  },
  deleteSurrogate: (_id: string): Promise<any> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .delete(`${endpoint.UPDATE_SURROGATE}/${_id}`)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .execute();
  },
  checkSurrogate: (email: string): Promise<Surrogate> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.IS_ABLE}/${email}`)
      .headers({
        Authorization: 'Bearer ' + user.accessToken,
        'Sso-Access': user.ssoAccess,
      })
      .execute();
  },
};
