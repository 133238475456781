import { createAsyncThunk } from '@reduxjs/toolkit';
import { LogAnalytics } from '@app/core/analytics';
import { AnnouncementModalDatasource } from '@app/data/http/announcement-modal-datasource';
import { IPutAnnouncementParams } from '@app/data/http/announcement.dto';
import { AnnouncementModalEvents } from '../announcement-regular-modal.analytics';
import { SLICE_NAME } from '../announcement-regular-modal.constants';

export const putRegularAnnouncement = createAsyncThunk(
  `${SLICE_NAME}/putRegularAnnouncement`,
  async (announcementParams: IPutAnnouncementParams, thunkAPI) => {
    return AnnouncementModalDatasource.putAnnouncement(announcementParams)
      .then(resp => resp)
      .catch(err => {
        LogAnalytics.error({
          tipo: AnnouncementModalEvents.PutAnnouncementError,
          message: err.response.status,
        });
        return thunkAPI.rejectWithValue({ status: err.response.status });
      });
  },
);

export const addPutRegularAnnouncementReducers = builder => {
  builder.addCase(putRegularAnnouncement.pending, state => {
    state.loading = true;
  });
  builder.addCase(putRegularAnnouncement.fulfilled, (state, _) => {
    state.announcements = [];
    state.opened = false;
    state.loading = false;
  });
  builder.addCase(putRegularAnnouncement.rejected, state => {
    state.loading = false;
  });
};
