import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { InvoiceList, InvoiceMEList } from '@app/models/invoice-list.model';
import { AuthStorageService } from '../storage/auth.storage';
import { InvoiceListDto, InvoiceMEListDto, mapInvoiceList, mapInvoiceMEList } from './invoice.dto';

const endpoint = {
  SALES_ORDER_MI: '/mi/financials/invoice',
  SALES_ORDER_ME: '/me/financials/invoice',
};

export interface GetInvoiceParams {
  customerIds: string;
  statusCompensation: string;
}

export const InvoiceDataSource = {
  getInvoiceMI: (params: GetInvoiceParams): Promise<InvoiceList> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.SALES_ORDER_MI)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: InvoiceListDto): Promise<InvoiceList> => mapInvoiceList(res),
      })
      .execute();
  },
  getInvoiceME: (params: GetInvoiceParams): Promise<InvoiceMEList> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.SALES_ORDER_ME)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: InvoiceMEListDto): Promise<InvoiceMEList> => mapInvoiceMEList(res),
      })
      .execute();
  },
};
