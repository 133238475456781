import { useMutation } from '@tanstack/react-query';
// import axios from 'axios';
// import { Container } from 'typedi';
// import { HttpRequestBuilder } from '@app/core/http';
// import { CXP_KEY_CLIENT_ID, CXP_KEY_CLIENT_SECRET, endpoint } from '@app/data/http';
// import { mapNluForm } from '@app/data/http/chat.dto';
import { PostFileParams } from '@app/data/http/quote-params.dto';
//import { AuthStorageService } from '@app/data/storage/auth.storage';
//import { ChatFormResponse } from '@app/models';

export async function postFile(params?: PostFileParams) {
  console.log(params);
  // const user = Container.get(AuthStorageService).getUser();
  // const defaultHeaders = {
  //   client_id: CXP_KEY_CLIENT_ID,
  //   client_secret: CXP_KEY_CLIENT_SECRET,
  //   Authorization: 'Bearer ' + user.accessToken,
  //   access_token: user.accessToken,
  //   'Content-Type': 'application/json',
  //   Accept: '*/*',
  //   language: params.language,
  //   'Allow-GPT': false,
  // };

  // const url = `${Container.get(HttpRequestBuilder).config.baseUrl}${endpoint.STANDARD_REVIEW}${
  //   endpoint.STANDARD_REVIEW_NLU
  // }`;

  // const form = new FormData();

  // form.append('client', JSON.stringify(params.client));
  // form.append('standard', JSON.stringify(params.standard));
  // form.append('file', params.file);

  // const { data } = await axios.post(url, form, { headers: defaultHeaders });

  // return {
  // data: {
  //   conversationId: '3132131312',
  //   nlu: [
  //     {
  //       STEEL: {
  //         key: 'STEEL',
  //         label: 'Aço',
  //         question: 'Qual é o aço que você deseja?',
  //         fields: {
  //           CHEMICAL_COMPOSITION: {
  //             label: 'Composição química',
  //             type: 'table',
  //             question: 'Composição química',
  //             permissions: { visible: true, editable: true },
  //             key: 'CHEMICAL_COMPOSITION',
  //             display: true,
  //             value: [
  //               {
  //                 element: 'C',
  //                 value: { min: 0.14, max: 0.19 },
  //                 unit: '%',
  //               },
  //               {
  //                 element: 'Mn',
  //                 value: { min: 1.2, max: 1.5 },
  //                 unit: '%',
  //               },
  //               {
  //                 element: 'Si',
  //                 value: { min: 0, max: 0.3 },
  //                 unit: '%',
  //               },
  //               {
  //                 element: 'P',
  //                 value: { min: 0, max: 0.025 },
  //                 unit: '%',
  //               },
  //             ],
  //             mandatory: true,
  //           },
  //         },
  //       },
  //       ADITIONAL: {
  //         key: 'ADITIONAL',
  //         label: 'Características adicionais',
  //         question: 'Deseja adicionar mais características?',
  //         fields: {
  //           HARDENABILITY: {
  //             key: 'HARDENABILITY',
  //             label: 'Jominy',
  //             question: 'Jominy',
  //             type: 'table', //text, textArray, range
  //             readBy: 'nlu', //nlu, user
  //             permissions: { visible: true, editable: true },
  //             display: true,
  //             value: [
  //               {
  //                 points: 1.5,
  //                 unit: 'mm',
  //                 value: { min: 1, max: 2 },
  //                 hardenabilityUnit: 'HRC',
  //               },
  //               {
  //                 points: 3,
  //                 unit: 'mm',
  //                 value: { min: 2, max: 2 },
  //                 hardenabilityUnit: 'HRC',
  //               },
  //               {
  //                 points: 5,
  //                 unit: 'mm',
  //                 value: { min: 2, max: 3 },
  //                 hardenabilityUnit: 'HRC',
  //               },
  //               {
  //                 points: 20,
  //                 unit: 'mm',
  //                 value: { min: 1, max: 4 },
  //                 hardenabilityUnit: 'HRC',
  //               },
  //             ],
  //           },
  //           MACRO_ETCH: {
  //             key: 'MACRO_ETCH',
  //             label: 'Macroataque',
  //             question: 'Macroataque',
  //             type: 'table',
  //             readBy: 'nlu',
  //             permissions: { visible: true, editable: true },
  //             display: true,
  //             value: [
  //               {
  //                 element: 'S',
  //                 value: 2,
  //               },
  //               {
  //                 element: 'R',
  //                 value: 2,
  //               },
  //               {
  //                 element: 'C',
  //                 value: 2,
  //               },
  //             ],
  //           },
  //           MICRO_INCLUSION: {
  //             label: 'Micro inclusão',
  //             type: 'table',
  //             question: 'Micro inclusão',
  //             permissions: { visible: true, editable: true },
  //             key: 'MICRO_INCLUSION',
  //             display: true,
  //             value: [
  //               {
  //                 element: 'A',
  //                 value: { FINO: 2.5, GROSSO: 2 },
  //               },
  //               {
  //                 element: 'B',
  //                 value: { FINO: 2, GROSSO: 1 },
  //               },
  //               {
  //                 element: 'C',
  //                 value: { FINO: 0.5, GROSSO: 0.5 },
  //               },
  //               {
  //                 element: 'D',
  //                 value: { FINO: 1, GROSSO: 1 },
  //               },
  //             ],
  //             mandatory: true,
  //           },
  //         },
  //       },
  //     },
  //   ],
  // },
  // };

  // if (data.success) {
  //   return { data: { conversationId: data.data.conversationId, nlu: mapNluForm(data.data.nlu) } };
  // } else {
  //   return null;
  // }
}

export function usePostFile(onSuccess?, onError?) {
  return useMutation(postFile, {
    onSuccess,
    onError,
    retry: false,
  });
}
