import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Container } from 'typedi';
import { endpoint, IsaHttpRequestBuilder } from '@app/data/http';
import { mapNluForm } from '@app/data/http/chat.dto';
import { PostFileParams } from '@app/data/http/quote-params.dto';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { ChatFormResponse } from '@app/models';

export async function postFile(params?: PostFileParams): Promise<ChatFormResponse> {
  const user = Container.get(AuthStorageService).getUser();
  const defaultHeaders = {
    ...Container.get(IsaHttpRequestBuilder).config.headers,
    'Allow-GPT': false,
    language: user.language,
  };

  const url = `${Container.get(IsaHttpRequestBuilder).config.baseUrl}${endpoint.STANDARD_REVIEW}${
    endpoint.STANDARD_REVIEW_NLU
  }`;

  const form = new FormData();

  form.append('client', JSON.stringify(params.client));
  form.append('standard', JSON.stringify(params.standard));
  form.append('file', params.file);

  const { data } = await axios.post(url, form, { headers: defaultHeaders });

  if (data.success) {
    return { data: { conversationId: data.data.conversationId, nlu: mapNluForm(data.data.nlu) } };
  } else {
    return null;
  }
}

export function usePostFile(onSuccess?, onError?) {
  return useMutation(postFile, {
    onSuccess,
    onError,
    retry: false,
  });
}
