import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Route } from '@app/core/route';
import { ProfileTypesId } from '@app/models/admin.model';
import { AppPath } from '../../app/route-constants';
import { useQuoteGuard } from '../../quote/quote.guard';
import { FormPage } from './form.page';

export const FormRouter: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const quoteAuthorized = useQuoteGuard();

  return (
    <Route
      exact
      path={AppPath.QUOTE.FORM.DETAIL}
      component={FormPage}
      authorized={quoteAuthorized && userInfo?.profile?.profile === ProfileTypesId.Rtc}
      redirectPath={AppPath.QUOTE.BASE}
    />
  );
};
