import { createAsyncThunk } from '@reduxjs/toolkit';
import { ChangePasswordParameters, LoginDataSource } from '@app/data/http/login.datasource';
import { setToast } from '@app/modules/components/toast/store/toast.store';
import { ToastVariant } from '@app/modules/components/toast/toast.constants';
import { authErrorMessageMap } from '../../auth-error.mapper';
import { SLICE_NAME } from '../../auth.constants';

export const changePassword = createAsyncThunk(
  `${SLICE_NAME}/changePassword`,
  async (params: ChangePasswordParameters, thunkAPI) => {
    return await LoginDataSource.changePassword(params)
      .then(data => data)
      .catch(err => {
        thunkAPI.dispatch(
          setToast({
            show: true,
            text: authErrorMessageMap[parseInt(err.response.status)],
            variant: ToastVariant.DANGER,
          }),
        );
        return thunkAPI.rejectWithValue({ status: err.response.status });
      });
  },
);

export const addChangePasswordReducers = builder => {
  builder.addCase(changePassword.pending, state => {
    state.changePasswordLoading = true;
  });
  builder.addCase(changePassword.fulfilled, (state, action) => {
    state.changePasswordData = action.payload;
    state.changePasswordLoading = false;
  });
  builder.addCase(changePassword.rejected, state => {
    state.changePasswordLoading = false;
    state.error = false;
  });
};
