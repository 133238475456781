import React from 'react';
import { FormContext } from '../form.context';
import { ModalAditionalAttributes } from './modal-aditional-atributtes.component';
import { ModalAttributeProvider } from './modal-attribute.context';
import { ModalComposition } from './modal-composition.component';
import { ModalDiameter } from './modal-diameter.component';
import { ModalGeneralAtributes } from './modal-general-attribute.component';
import { ModalJominy } from './modal-jominy.component';
import { ModalMacroataque } from './modal-macroataque.component';
import { ModalMechanicalProperties } from './modal-mechanical.component';
import { ModalMicroinclusao } from './modal-microinclusao.component';

export const ModalAttributeContainer = () => {
  const { formResult, modalToOpen, submitModals } = React.useContext(FormContext);

  const keyAttribute = typeof modalToOpen === 'string' ? modalToOpen : modalToOpen?.key;

  switch (keyAttribute) {
    case 'CHEMICAL_COMPOSITION':
      return (
        <ModalComposition
          open={keyAttribute === 'CHEMICAL_COMPOSITION' || false}
          data={formResult?.STEEL?.questions?.fields?.CHEMICAL_COMPOSITION?.value}
          submitModals={submitModals}
        />
      );
    case 'ADDITIONAL_DIAMETER':
      return (
        <ModalDiameter
          open={keyAttribute === 'ADDITIONAL_DIAMETER' || false}
          data={formResult?.STEEL?.questions?.fields?.ADDITIONAL_DIAMETER?.value}
          submitModals={submitModals}
        />
      );
    case 'MICRO_INCLUSION':
      return (
        <ModalMicroinclusao
          open={keyAttribute === 'MICRO_INCLUSION' || false}
          data={formResult?.ADDITIONAL?.questions?.fields?.MICRO_INCLUSION?.value}
          submitModals={submitModals}
        />
      );
    case 'MACRO_ETCH':
      return (
        <ModalMacroataque
          open={keyAttribute === 'MACRO_ETCH' || false}
          data={formResult?.ADDITIONAL?.questions?.fields?.MACRO_ETCH?.value}
          submitModals={submitModals}
        />
      );
    case 'HARDENABILITY':
      return (
        <ModalJominy
          open={keyAttribute === 'HARDENABILITY' || false}
          data={formResult?.ADDITIONAL?.questions?.fields?.HARDENABILITY?.value}
          submitModals={submitModals}
        />
      );
    case 'MECHANICAL_PROPERTIES':
      return (
        <ModalMechanicalProperties
          open={keyAttribute === 'MECHANICAL_PROPERTIES' || false}
          data={
            formResult?.ADDITIONAL?.questions?.fields?.LOAD_LIMIT
              ? [
                  formResult?.ADDITIONAL?.questions?.fields?.LOAD_LIMIT,
                  formResult?.ADDITIONAL?.questions?.fields?.YIELD_LIMIT,
                  formResult?.ADDITIONAL?.questions?.fields?.AREA_SHRINK,
                  formResult?.ADDITIONAL?.questions?.fields?.STRETCH,
                  formResult?.ADDITIONAL?.questions?.fields?.YIELD_LIMIT?.unit,
                ]
              : null
          }
          submitModals={submitModals}
        />
      );
    case 'ADDITIONAL':
      return <ModalAditionalAttributes open={keyAttribute === 'ADDITIONAL' || false} submitModals={submitModals} />;
    default:
      return modalToOpen ? (
        <ModalGeneralAtributes
          attribute={modalToOpen}
          open={modalToOpen && modalToOpen !== null}
          data={formResult?.ADITIONAL?.questions?.fields[keyAttribute]?.value}
          submitModals={submitModals}
        />
      ) : null;
  }
};

export const ModalPage = () => {
  return (
    <ModalAttributeProvider>
      <ModalAttributeContainer />
    </ModalAttributeProvider>
  );
};
