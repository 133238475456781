import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TEST_ID_CLOSE_MODAL } from '@app/constants';
import { AppThunkDispatch, RootState } from '@app/core/redux/store';
import { MarketType } from '@app/providers';
import { announcementOpenedSelector } from '@app/providers/navigation/navigation.selectors';
import { Button } from '@atomic/atm.button';
import { Image } from '@atomic/atm.image';
import { FlexColumn, FlexRow } from '@atomic/obj.box';
import { Modal } from '@atomic/obj.modal';
import { HAPPY_CUSTOMER_DAY, IMG_RELATIVE_PATH } from './announcement-regular-modal.constants';
import { Strings, IAnnouncementModalStrings } from './announcement-regular-modal.string';
import {
  AnnouncementModalBody,
  AnnouncementModalCloseWrapper,
  AnnouncementModalFooter,
  AnnouncementModalHeader,
  AnnouncementModalImage,
  AnnouncementModalTitleWrapper,
  AnnouncementModalWrapper,
} from './announcement-regular-modal.style';
import { setRegularOpened } from './store/announcement-regular-modal.store';
import { getRegularAnnouncement } from './store/get-regular-announcement.thunk';
import { putRegularAnnouncement } from './store/put-regular-announcement.thunk';

export const AnnouncementRegularModal: React.FC = () => {
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const { announcements, opened } = useSelector((state: RootState) => state.regularAnnouncement);
  const { currentMarket } = useSelector((state: RootState) => state.customerPersist);
  const { openBPsModal } = useSelector((state: RootState) => state.navigation);
  const dispatch = useDispatch<AppThunkDispatch>();
  const announcementOpened = useSelector(announcementOpenedSelector);
  const strings: IAnnouncementModalStrings = Strings[userInfo?.language];

  const handleClose = () => {
    dispatch(putRegularAnnouncement({ key: HAPPY_CUSTOMER_DAY, email: userInfo?.email }));
  };

  useEffect(() => {
    dispatch(getRegularAnnouncement(HAPPY_CUSTOMER_DAY));
  }, []);

  useEffect(() => {
    if (announcements && announcements.length && currentMarket === MarketType.Internal) {
      dispatch(setRegularOpened(true));
    } else {
      dispatch(setRegularOpened(false));
    }
  }, [announcements?.length, openBPsModal]);

  return (
    <Modal small opened={opened && announcementOpened}>
      <AnnouncementModalHeader>
        <AnnouncementModalTitleWrapper>{strings?.announcement.title}</AnnouncementModalTitleWrapper>
        <AnnouncementModalCloseWrapper>
          <FlexColumn onClick={() => handleClose()} style={{ cursor: 'pointer' }}>
            <Image.NewsModalHeaderCloseButton />
          </FlexColumn>
        </AnnouncementModalCloseWrapper>
      </AnnouncementModalHeader>
      <AnnouncementModalWrapper>
        <AnnouncementModalBody>
          <AnnouncementModalImage>
            <img width='600px' src={IMG_RELATIVE_PATH + announcements[0]?.url[0]} />
          </AnnouncementModalImage>
          <AnnouncementModalFooter>
            <FlexRow hAlign='center'>
              <Button testid={`${TEST_ID_CLOSE_MODAL}-annoucement`} onClick={() => handleClose()}>
                {strings?.announcement.confirmButton}
              </Button>
            </FlexRow>
          </AnnouncementModalFooter>
        </AnnouncementModalBody>
      </AnnouncementModalWrapper>
    </Modal>
  );
};
