import styled from 'styled-components';
import { Spacing } from '@atomic/obj.constants';

export const ShippingRequestSelectedItemTableWrapper = styled.div`
  min-width: ${props => (props.isGKN ? '1600px' : 'unset')};
  padding-right: ${props => (props.isGKN ? Spacing.Medium : 'unset')};
`;

export const ShippingRequestSelectedItemTableScroll = styled.div`
  overflow-x: scroll;
  max-width: 100%;
`;

export const ShippingRequestSelectedItemTableOptionsScroll = styled.div`
  overflow-x: scroll;
  max-width: 92%;
  max-height: 230px;
`;
