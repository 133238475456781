import React from 'react';
import { TextField } from '@atomic/atm.text-field';
import { TD, TR } from '@atomic/mol.table';
import { Form } from '@atomic/obj.form';

interface FieldsRowProps {
  type?: string;
  unit?: string;
}

export const ModalGeneralAttributesFields: React.FC<FieldsRowProps> = props => {
  switch (props.type) {
    case 'range':
      return (
        <TR>
          <TD>
            <Form.Field name='min' hideErrorCaption key='min'>
              <TextField placeholder='Min.' type='text' />
            </Form.Field>
          </TD>
          <TD> - </TD>
          <TD>
            <Form.Field name='max' key='max' hideErrorCaption>
              <TextField placeholder='Máx.' type='text' />
            </Form.Field>
          </TD>
        </TR>
      );
    case 'text':
      return (
        <TR>
          <TD>
            <Form.Field name='value' hideErrorCaption>
              <TextField type='text' />
            </Form.Field>
          </TD>
        </TR>
      );
    default:
      return null;
  }
};
