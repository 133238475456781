import { Service } from 'typedi';
import { LocalStorage } from '@app/core/storage';
import { AppPath } from '@app/modules/app/route-constants';
import { UserData } from '@app/providers';

export interface AuthStorageObserver {
  hasLoggedIn?: (user: UserData) => void;
  hasLoggedOut?: () => void;
}

const USER_KEY = 'user';

@Service()
export class AuthStorageService {
  private observers: Set<AuthStorageObserver> = new Set();

  @LocalStorage(USER_KEY)
  private user: UserData;

  getUser = () => {
    return this.user;
  };

  login = (user: UserData) => {
    this.user = user;
    this.observers.forEach(observer => observer.hasLoggedIn(user));
  };
  setAzureToken = (token: string) => {
    this.user = { ...this.user, azureToken: token };
  };
  logout = () => {
    window.localStorage.removeItem('persist:auth-persist-reducer');
    window.localStorage.removeItem('persist:admin-persist-reducer');
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('azureDateTimeExpiration');
    window.localStorage.removeItem('azureExpireIn');
    window.localStorage.removeItem('azureToken');

    this.user = null;
    window.location.href = AppPath.AUTHENTICATION;
  };

  ssoLogout = () => {
    document.cookie = '@access_token=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/; Domain=.egerdau.com.br;';
    document.cookie = '@sso-access=; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/; Domain=.egerdau.com.br;';
    window.localStorage.removeItem('persist:auth-persist-reducer');
    window.localStorage.removeItem('persist:admin-persist-reducer');
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('azureDateTimeExpiration');
    window.localStorage.removeItem('azureExpireIn');
    window.localStorage.removeItem('azureToken');
    window.localStorage.setItem('ssoLogout', 'true');
    window.localStorage.setItem('path', '/auth');

    this.user = null;
  };

  addObserver(observer: AuthStorageObserver) {
    this.observers.add(observer);
  }

  removeObserver(observer: AuthStorageObserver) {
    this.observers.delete(observer);
  }
}
