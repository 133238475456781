import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { ISchedule, IScheduleLayout } from '@app/models/schedule.model';
import { AuthStorageService } from '../storage/auth.storage';
import { mapDefaultLayout, ScheduleDTO, ScheduleLayoutDTO } from './schedule.dto';

interface ScheduleClientsParams {
  name: string;
  code: string;
}

interface ScheduleLayoutParams {
  uid: string;
  belongs: string;
  label: string;
  value: string;
  active: boolean;
  index: number;
}

export interface ScheduleParams {
  name: string;
  mail: string;
  user_type: number;
  occurrence_type: number;
  send_day: string;
  hour_to_send: number;
  active: boolean;
  clients: ScheduleClientsParams[];
  layout: ScheduleLayoutParams[];
}

export interface UpdateScheduleParams extends ScheduleParams {
  id: number;
}

const endpoint = {
  VALIDATE: 'sch/validate/email',
  SCHEDULE: 'sch/schedule',
  SCHEDULERS: 'sch/schedulers',
  LAYOUT_DEFAULT_CART: 'sch/layout/default/cart',
  LAYOUT_DEFAULT_STOCK: 'sch/layout/default/stock',
};

export const ScheduleDataSource = {
  validateEmail: (email: string): Promise<string> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.VALIDATE}?email=${email}`)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .execute();
  },
  getSchedules: (customerIds: string): Promise<ScheduleDTO[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.SCHEDULE}?uid=${user.uid}`)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params({ customerIds })
      .interceptors({
        afterSuccess: async (res: ScheduleDTO[]): Promise<ISchedule[]> => [...res],
      })
      .execute();
  },
  submitSchedule: (params: ScheduleParams, customerIds: string): Promise<string> => {
    const user = Container.get(AuthStorageService).getUser();
    const body = { uid: user?.uid, ...params };

    return Container.get(HttpRequestBuilder)
      .post(endpoint.SCHEDULE)
      .headers({
        'Sso-Access': user.ssoAccess,
        'Content-Type': 'application/json',
      })
      .params({ customerIds })
      .data(body)
      .execute();
  },
  updateSchedule: (params: UpdateScheduleParams, customerIds: string): Promise<string> => {
    const user = Container.get(AuthStorageService).getUser();
    const body = { uid: user.uid, ...params };

    return Container.get(HttpRequestBuilder)
      .put(endpoint.SCHEDULE)
      .headers({
        'Sso-Access': user.ssoAccess,
        'Content-Type': 'application/json',
      })
      .params({ customerIds })
      .data(body)
      .execute();
  },
  deleteSchedule: (id: number, customerIds: string): Promise<string> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .delete(`${endpoint.SCHEDULE}?id=${id}`)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params({ customerIds })
      .execute();
  },
  getLayoutCart: (customerIds: string): Promise<ScheduleLayoutDTO[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.LAYOUT_DEFAULT_CART}`)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params({ customerIds })
      .interceptors({
        afterSuccess: async (res: ScheduleLayoutDTO[]): Promise<IScheduleLayout[]> => mapDefaultLayout(res),
      })
      .execute();
  },
  getLayoutStock: (customerIds: string): Promise<ScheduleLayoutDTO[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(`${endpoint.LAYOUT_DEFAULT_STOCK}`)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params({ customerIds })
      .interceptors({
        afterSuccess: async (res: ScheduleLayoutDTO[]): Promise<IScheduleLayout[]> => mapDefaultLayout(res),
      })
      .execute();
  },
};
