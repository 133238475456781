import Container from 'typedi';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { ComplainClientsData } from '@app/models/complain.model';
import { BaggHttpRequestBuilder } from './bagg-http-request.builder';
import { ComplainClientResponse, mapComplainClients } from './complain.dto';

const endpoint = {
  SALESFORCE_ACCOUNTS: '/salesforce-accounts',
  FORM_COMPLAIN: '/form-complaints',
  FORM_COMPLAIN_FILES: '/form-attachment',
};

const user = Container.get(AuthStorageService).getUser();

export const ComplainDataSource = {
  getSalesForceAccounts: (): Promise<ComplainClientsData[]> => {
    return Container.get(BaggHttpRequestBuilder)
      .get(`${endpoint.SALESFORCE_ACCOUNTS}`)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .interceptors({
        afterSuccess: async (res: ComplainClientResponse): Promise<ComplainClientsData[]> => mapComplainClients(res),
      })
      .execute();
  },

  postComplain: (params): Promise<void> => {
    return Container.get(BaggHttpRequestBuilder)
      .post(`${endpoint.FORM_COMPLAIN}`)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .data(params)
      .interceptors({
        afterError: async (res: any): Promise<void> => res,
      })
      .execute();
  },

  postFilesComplain: (params): Promise<void> => {
    return Container.get(BaggHttpRequestBuilder)
      .post(`${endpoint.FORM_COMPLAIN_FILES}`)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .data(params)
      .interceptors({
        afterError: async (res: any): Promise<void> => res,
      })
      .execute();
  },
};
