import Container from 'typedi';
import { HttpRequestBuilder } from '@app/core/http';
import { Receiver } from '@app/models';
import { AuthStorageService } from '../storage/auth.storage';
import { mapReceivers, ReceiversDTO } from './receiver.dto';

export interface IGetReceiverParams {
  customerIds: string;
}

const endpoint = {
  ORDERINPUT: '/mi/implantation-request',
  RECEIVER: '/recebedor',
};

export const ReceiverDataSource = {
  getReceivers: (params: IGetReceiverParams): Promise<Receiver[]> => {
    const user = Container.get(AuthStorageService).getUser();

    return Container.get(HttpRequestBuilder)
      .get(endpoint.ORDERINPUT + endpoint.RECEIVER)
      .headers({
        'Sso-Access': user.ssoAccess,
      })
      .params(params)
      .interceptors({
        afterSuccess: async (res: ReceiversDTO): Promise<Receiver[]> => mapReceivers(res),
      })
      .execute();
  },
};
